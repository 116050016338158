.title_navbar {
  background-color: white;
  padding: 20px;
}
.planning_text {
  font-size: 20px;
  margin-left: 10px;
  font-weight: 600;
  cursor: pointer;
}
.Team_close_icon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  top: 25px;
}
/* popup */
.our_mission_text {
  font-size: 16px;
  color: #778190;
}
.our_mission_text_box {
  height: 230px;
  padding: 10px;

  border-radius: 9px;
  background-color: #f1f3f6;
}
.team_members_name{
   
    margin-left: 10px;
}
.drag_section{
    background-color: aqua;
}
.team_wolf_title{
    font-size: 32px;
    font-weight: 600;
}
.title_text_icon{
  color:gray !important;
}

.addNewGroupContainer{
  margin: 0px;
}

.addNewGroup{
  cursor: pointer;
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background: white;
    min-width: 300px;
    border-radius: 8px;
    padding: 13px;
    margin-right: 20px;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%);
}

.addNewGroupPlus{
  background: #efe5e5;
    padding: 0px 8px 2px 8px;
    border-radius: 8px;
    border: 1px solid #bfacac;
}

.e-manage-groups-container{
  width: 100%;
  overflow: auto;
}

.e-add-group-confirm-icons{
  float: right;
}

.e-manage-group-edit-group-name{
  cursor: pointer;
  margin-left: 5px;
}