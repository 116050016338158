body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.common-search-box {
  height: 40px;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 4px;
}

@font-face {
  font-family: Pompiere-Regular;
  src: url(./assets/fonts/Pompiere/Pompiere-Regular.ttf);
}

@font-face {
  font-family: Khand SemiBold;
  src: url(./assets//fonts/khand/Khand-SemiBold.woff);
}

@font-face {
  font-family: Khand Regular;
  src: url(./assets//fonts/khand/Khand-Medium.woff);
}

@font-face {
  font-family: Poppins Regular;
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins Bold;
  src: url(./assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins SemiBold;
  src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins Mediam;
  src: url(./assets/fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Manrope;
  src: url(./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf);
}

@font-face {
  font-family: Inter Tight Medium;
  src: url(./assets/fonts/Inter_Tight/static/InterTight-Medium.ttf);
}

@font-face {
  font-family: Inter Tight ExtraBold;
  src: url(./assets/fonts/Inter_Tight/static/InterTight-ExtraBold.ttf);
}

@font-face {
  font-family: Inter Tight Bold;
  src: url(./assets/fonts/Inter_Tight/static/InterTight-Bold.ttf);
}

.swal2-container {
  z-index: 9999999 !important;
}

.host-course-list {
  height: 100vh;
  overflow: auto;
  position: relative;
  top: 220px;
}

.nav-img-v4 {
  position: absolute;
  bottom: 55px;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
  position: fixed !important;
  width: 100%;
}