/** #region RESPONSIVE SCREENS */

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    /** COMMON */
    .spacing{
        padding-left: 25px;
        padding-right: 25px;
    }

    .white-desc{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #FDFDFD;
        text-align: center;
    }

    /**SECTION 1*/
    .desktop-section-1-container{
        position: absolute;
        top: 30%;
        width: 450px;
        left: 75px;
        height: 163px;
    }
    
    .desktop-section-1-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 103%;
        color: #FDFDFD;
    }

    
.mobile-section-1-title{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 103%;
    color: #FDFDFD;
}


.mobile-text-section-1-container{
    position: absolute;
    top: 13%;
    height: 163px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.mobile-section-1-container{
    position: relative;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
}

    /** SECTION - 2 */
    .section-2-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-left: 10px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
        padding-right: 10px;
    }

    /** SECTION - 4 */
    .section-4-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 121%;
        color: #FFFFFF;
        text-align: center;
    }

    /** FOOTER */

    .footer-subscribe-section{
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .subscribe-btn{
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: none;
    }

    .footer-value-text{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #2861C4;
    }

    .f-label{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #2861C4;
    }
    
    .f-value{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen{
        display: block;
    }
    .large-screen{
        display: none;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    /** COMMON */
    .spacing{
        padding-left: 25px;
        padding-right: 25px;
    }

    .white-desc{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #FDFDFD;
        text-align: center;
    }

    /**SECTION 1*/
    .desktop-section-1-container{
        position: absolute;
        top: 30%;
        width: 450px;
        left: 75px;
        height: 163px;
    }
    
    .desktop-section-1-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 103%;
        color: #FDFDFD;
    }


.mobile-section-1-title{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 103%;
    color: #FDFDFD;
}


.mobile-text-section-1-container{
    position: absolute;
    top: 23%;
    height: 163px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.mobile-section-1-container{
    position: relative;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
}
    /** SECTION - 2 */
    .section-2-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-left: 10px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
        padding-right: 10px;
    }

    /** SECTION - 4 */
    .section-4-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 121%;
        color: #FFFFFF;
        text-align: center;
    }

    /** FOOTER */

    .footer-subscribe-section{
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .subscribe-btn{
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: none;
    }

    .footer-value-text{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #2861C4;
    }

    .f-label{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #2861C4;
    }
    
    .f-value{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen{
        display: block;
            }
            .large-screen{
        display: none;
            }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    /** COMMON */
    .spacing{
        padding-left: 25px;
        padding-right: 25px;
    }

    .white-desc{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #FDFDFD;
        text-align: center;
    }
    /**SECTION 1*/
    .desktop-section-1-container{
        position: absolute;
        top: 30%;
        width: 450px;
        left: 75px;
        height: 163px;
    }
    
    .desktop-section-1-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 103%;
        color: #FDFDFD;
    }


.mobile-section-1-title{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 103%;
    color: #FDFDFD;
}


.mobile-text-section-1-container{
    position: absolute;
    top: 13%;
    height: 163px;
    text-align: center;
    padding-left: 98px;
    padding-right: 98px;
}

.mobile-section-1-container{
    position: relative;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
}

    /** SECTION - 2 */
    .section-2-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-left: 10px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
        padding-right: 10px;
    }

    /** SECTION - 4 */
    .section-4-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 121%;
        color: #FFFFFF;
        text-align: center;
    }

    /** FOOTER */

    .footer-subscribe-section{
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .subscribe-btn{
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: none;
    }

    .footer-value-text{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #2861C4;
    }

    .f-label{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #2861C4;
    }
    
    .f-value{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen{
        display: block;
            }
            .large-screen{
        display: none;
            }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    /** COMMON */
    .spacing{
        padding-left: 75px;
        padding-right: 75px;
    }

    .white-desc{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 30px;
        color: #FDFDFD;
        text-align: center;
    }

    /**SECTION 1*/
    .desktop-section-1-container{
        position: absolute;
        top: 30%;
        width: 450px;
        left: 75px;
        height: 163px;
    }
    
    .desktop-section-1-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 103%;
        color: #FDFDFD;
    }


.mobile-section-1-title{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 110%;
    color: #FDFDFD;
}


.mobile-text-section-1-container{
    position: absolute;
    top: 22%;
    height: 163px;
    text-align: center;
    padding-left: 98px;
    padding-right: 98px;
}

.mobile-section-1-container{
    position: relative;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
}


    /** SECTION - 2 */
    .section-2-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 33px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
    }

    /** SECTION - 4 */
    .section-4-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 121%;
        color: #FFFFFF;
        text-align: center;
    }

    /** FOOTER */

    .footer-subscribe-section{
        width: 100%;
    }

    .subscribe-btn{
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: center;
    }

    .footer-value-text{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #2861C4;
        text-align: left;
    }

    .f-label{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #2861C4;
    }
    
    .f-value{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen{
        display: block;
            }
            .large-screen{
        display: none;
            }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    /** COMMON */
    .spacing{
        padding-left: 75px;
        padding-right: 75px;
    }

    .white-desc{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #FDFDFD;
        text-align: left;
    }

    /**SECTION 1*/
    .desktop-section-1-container{
        position: absolute;
        top: 30%;
        width: 450px;
        left: 75px;
        height: 163px;
    }

    .desktop-section-1-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 103%;
        color: #FDFDFD;
    }

    .white-desc{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #FDFDFD;
    }

    /** SECTION - 2 */
    .section-2-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 121%;
        color: #2861C4;
        margin-left: 75px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
    }

    /** SECTION - 4 */
    .section-4-title{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 121%;
        color: #FFFFFF;
        text-align: left;
    }

    /** FOOTER */

    .footer-subscribe-section{
        width: 70%;
    }

    .subscribe-btn{
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: left;
    }

    .footer-value-text{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #2861C4;
        text-align: left;
    }

    .f-label{
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #2861C4;
    }
    
    .f-value{
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen{
        display: none;
            }
            .large-screen{
        display: block;
            }
}
/** #endregion */

/** COMMON CSS*/

.spacer{
    padding-top: 36px;
    padding-bottom: 36px;
}

.mobile-hz-spacer{
    padding-left: 98px;
    padding-right: 98px;
}

/* .common-bg{
    background: linear-gradient(180deg, #FFFFFF 1.54%, #FFFFFF 21.47%, #3435E5 51.01%, #E4E4FC 90.23%, #FFFFFF 98.86%, #FFFFFF 101.54%);
} */

.common-bg{
    background: linear-gradient(180deg,#fff 1.54%,#fff 22.54%,#3435e5 69.01%,#e4e4fc 97.23%,#fff 98.86%,#fff 101.54%);
}

.dark-get-started-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 75px;
    width: 221px;
    height: 69px;
    left: 120px;
    top: 1701px;
    background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
}

.dark-get-started-btn span{
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 103%;
    text-align: center;
    color: #FEFEFE;
}

.mobile-dark-get-started-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* margin-left: 75px; */
    width: 154px;
    height: 39px;
    left: 120px;
    top: 1701px;
    background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 44px;
    padding-left: 10px;
    padding-right: 10px;
}

.mobile-dark-get-started-btn span{
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 103%;
    text-align: center;
    color: #FEFEFE;
}

.mobile-btn-container{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.light-get-started-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 221px;
    height: 69px;
    left: 120px;
    top: 1701px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
}

.light-get-started-btn span{
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 103%;
    text-align: center;
    color: #3C3DE6;
}

.mobile-light-get-started-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 154px;
    height: 39px;
    left: 120px;
    top: 1701px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 44px;
}

.mobile-light-get-started-btn span{
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 103%;
    text-align: center;
    color: #3C3DE6;
}

.transparent-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    border-radius: 44px;
    width: 221px;
    height: 69px;
    left: 120px;
    top: 1701px;
    margin-left: 20px;
}

.transparent-btn span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 103%;
    text-align: center;
    color: #FFFFFF;
}

.transparent-rouund-play-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    border: 1px solid #FFFFFF;
    border-radius: 44px;
}

.mobile-transparent-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    border-radius: 44px;
    width: 154px;
    height: 39px;
    left: 120px;
    top: 1701px;
    margin-left: 20px;
}

.mobile-transparent-btn span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 98%;
    text-align: center;
    color: #FFFFFF;
}

.mobile-transparent-rouund-play-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border: 1px solid #FFFFFF;
    border-radius: 44px;
}

/** SECTION 1 */
.main-container{
    height: 100%;
    width: 100%;
}

.section-1-container{
    position: relative;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
}

/** SECTION 2 */
.section-2-container{
    /* background-image: url(../../assets/img/home/section_2_bg.png); */
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 0px;
    margin-right: 0px;
}

/**SECTION 3 */
.section-3-container{
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.section-3-container.spacing:before {
    background-color: #31B6FD;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    width: 600px;
    height: 600px;
    filter: blur(177px);
    top: 430px;
    right: 64%;
}

.section-3-container-z-index{
    z-index: 1;
}
/* .section-3-container{
    background-image: linear-gradient(to right, #384ce9 , #26bbfd);
    background-size: cover;
    background-repeat: no-repeat;
} */


/* SECTION 3 MOBILE ACCORDION DESIGN */
.accordion {
    border-radius: 15px;
    margin-bottom: 10px;
}

.accordion:first-of-type {
    border-radius: 15px;
    margin-bottom: 10px;
}

.accordion:last-of-type{
    border-radius: 15px;
    margin-bottom: 10px;
}
.s-3-tab-text-mobile{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 121%;
    color: #1C4697;
}

.s-3-tab-1-active-mobile{
    background:#FFE394!important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.s-3-tab-2-active-mobile{
    background:#BCEBDF!important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.s-3-tab-3-active-mobile{
    background:#B4CBF9!important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.section-3-title{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 121%;
    text-align: center;
    color: #2861C4;
}

.section-3-card{
    background: #E7E7E7;
    border-radius: 20px;
    display: flex;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 15px 15px;
    border-radius: 30px;
}

.s-3-tab{
    background: #FFFFFF;
    height: calc(94.99%/2.9);
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.s-3-tab-1-active{
    background:#FFE394!important;
}


.s-3-tab-2-active{
    background:#BCEBDF!important;
}

.s-3-tab-3-active{
    background:#B4CBF9!important;
}

.s-3-tab-1-sizing{
    border-top-left-radius: 20px;
    margin-bottom: 5px;
}

.s-3-tab-2-sizing{
    margin-bottom: 5px;
}

.s-3-tab-3-sizing{
    border-bottom-left-radius: 20px;
}

.s-3-tab-text{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 121%;
    text-align: center;
    color: #1C4697;
}

.s-3-tab-content{
    background-color: #FFFFFF!important;
    display: flex;
    width: 100%;
    margin-left: 5px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: center;
    align-items: center;
    padding-left: 101px;
    padding-top: 90px;
    padding-right: 96px;
    padding-bottom: 90px;
}

.s-3-tab-content img{
    width: 100%;
}

/** SECTION - 4*/
.section-4-container{
    /* background-image: linear-gradient(to right, #384ce9 , #26bbfd); */
    display: flex;
    align-items: center;
    position: relative;
}

.section-4-container-img{
    z-index: 1;
}

/* .section-4-container{
   background-image: url(../../assets/img/home/section_4_bg.png);
   background-repeat: no-repeat;
   background-size: cover;
    display: flex;
    align-items: center;
    height: 100vh;
} */

.dotted-bg{
    background-image: url(../../assets/img/home/dotted_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 0px;
    margin-right: 0px;
    z-index: 1;
}

/** SECTION 5*/
.section-5-container{
    padding-bottom: 35px;
    display: flex;
    align-items: center;
    position: relative;
}

.section-5-container:before {
    background-color: #31B6FD;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    width: 600px;
    height: 600px;
    filter: blur(177px);
    top: -23px;
    right: 64%;
}

/* .section-5-container{
    background-image: url(../../assets/img/home/section_5_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
     display: flex;
     align-items: center;
 } */

/** JOIN MEEKU */

.section_6_container{
    /* background-image: linear-gradient(to right, #384ce9 , #26bbfd); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktop_join_meeku_container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop_join_meeku_bg_container{
    position: relative;
    box-shadow: 10px 10px;
    border-radius: 30px;
}

.desktop_join_meeku_inner_bg{
    position: absolute;
    top: -50px;
    padding-left: 25px;
    padding-right: 25px;
}

.desktop_join_meeku_inner_text_container{
    position: absolute;
    text-align: center;
    width: 70%;
}

.desktop_join_meeku_inner_title{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    margin-top: 12px;
    line-height: 121%;
    color: #FFFFFF;
    text-align: center;
}
/* FOOTER */

.footer-container{
    background-color: #FFFFFF;
    padding-top: 35px;
    padding-bottom: 35px;
}

.inputEmail{
    height: 40px!important;
    border-radius: 20px;
    border: none;
    width: 350px;
    padding-left: 20px;
    color: #2861C4;
    font-size: 14px;
}

.inputEmail:focus-visible {
    outline: none;
}

.inputEmailMobile{
    height: 40px!important;
    border-radius: 20px;
    border: none;
    width: 100%;
    padding-left: 20px;
    color: #2861C4;
    font-size: 14px;
}

.inputEmailMobile:focus-visible {
    outline: none;
}

.submit-btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 221px;
    height: 40px;
    background: #f1577d!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
}

.submit-btn span{
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 103%;
    text-align: center;
    color: #FFFFFF;
}

/** POPUP */

/* POPUP */
.play-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .close-btn {
    cursor: pointer;
    color: #466582;
    position: absolute;
    right: 10px;
    top: 6px;
  }
  
  .btn:active{
    color: white;
  }
  
  .btn:disabled{
    color: white;
  }
  
  .btn-submit {
    background-color: #F1577D !important;
    color: white;
    font-family: 'Khand SemiBold';
    font-weight: 600;
    width: 75%;
  }
  
  .btn-submit:hover{
    color: white;
  }
  
  .form-container {
    padding: 20px;
  }
  
  .free-live-text {
    font-family: 'Khand SemiBold';
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }
  
  .PhoneInputInput {
    border-width: 0px!important;
    padding: 0px!important;
    font-size: 0.875rem!important;
    outline: none;
  }
  
  [type='tel']:focus{
    box-shadow: none!important;
  }

  
.success_c {
    display: flex;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  
.success_img_c {
    justify-content: center;
    font-family: 'Khand SemiBold';
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  
  .success_img_div {
    display: flex;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  
  .success_icon {
    font-family: 'Khand SemiBold';
    padding-bottom: 8px;
    width: 25%;
  }
  
  .section-4-container.spacing:before {
    background-color: #31B6FD;
    background-repeat: no-repeat;
    content: "";
    right: 44px;
    position: absolute;
    width: 500px;
    height: 500px;
    filter: blur(177px);
    top: -42px;
}

