.delete_icon {
  position: absolute;
  right: 0px;
  font-size: 12px;
  bottom: 120px;
  cursor: pointer;
  margin-left: 20px;
}

.send_invite_btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 7px;
  width: 120px;

  text-align: left;
}

.btn-text-left {
  text-align: left;
  margin-left: 20px;
}

.link_text {
  color: #7b7be0;
  cursor: pointer;
}

.link_text:hover {
  padding-bottom: 5px;
  transition-duration: 0.2s;
}

.tab_btn_exp {
  border: 1px solid #ccc;
  border-radius: 7px;
  font-weight: bold !important;
}