.enroll-now-btn-ph {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 44px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 25px;
    padding-left: 25px;
    border: 2px solid #dfe0e1;
}

.enroll-now-btn-ph span {
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #f07820;
}

.parent-course-title-text-ph {
    text-transform: capitalize;
}

.parent-card-text-ph {
    padding: 18px;
}

.parent-course-price-text-ph {
    font-size: 12px;
    color: #7a8492;
}

.parent-course-price-ph {
    font-size: 15px;
    color: #3b4357;
    font-weight: bolder;
}

.courses_title_text_p_home {
  font-family: 'Inter Tight Medium';
  font-size: 24px;
  color: #3B4357;
  font-weight: 700;
}