.accordion_sess_title {
  font-size: 17px;
  font-weight: bold;
}

.session_list_ul {
  list-style: none;
}

.session_list_ul li:hover {
  background-color: #f1f3f6;
  cursor: pointer;
  font-weight: bold;
}

.session_list_li_text {
  font-size: 16px;
}

.session_ilst_item {
  padding: 5px;
  display: flex;
  color: #6c757d;
}

.selected_course_title {
  background: #fff;
  padding: 10px;
  font-weight: 500;
  margin-top: 3px;

}

.content-section-iframe {
  width: 100%;
  height: 100vh;
}

.active-course {
  border-left: 3px solid #2165d1;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.e-curriculum-main {
  /* margin-top: -20px; */
  overscroll-behavior: contain;
  height: 100%;
  background: #fff;
  position: relative;
}

.e-curriculum-breadscrum-container {
  width: 100%;
  border-bottom: 1px solid #6c757d;
  color: #6c757d;
  position: relative;
  z-index: 1;
  background: #fff;
}

.e-curriculum-bs-text {
  font-size: 14px !important;
}

/* .e-curriculum-container {
  height: calc(100% - 0%);
  overflow: auto;
  display: flex;
  width: 100%;
  overscroll-behavior: contain;
} */

/* .e-curriculum-left-menu {
  width: calc(100% - 80%);
  height: calc(100% - 22%);
  overflow: auto;
  overscroll-behavior: contain;
  position: fixed;
  border-right: 1px dashed rgba(145, 158, 171, 0.24);
}

.e-curriculum-right-content {
  width: calc(100% - 25%);
  padding: 15px;
  height: calc(100% - 0%);
  overflow: auto;
  overscroll-behavior: contain;
  position: absolute;
  right: 0;
} */

.e-curriculum-icon {
  width: 20px;
  border-radius: 7px;
}

.e-curriculum-games-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: green;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.e-curriculum-quiz-question {
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
}

.e-curriculum-quiz-answer {
  font-size: 14px;
  font-weight: 400;
}

.e-curriculum-quiz-answer-active {
  color: green;
}

/* MobileDrawer component styles */
.mobile-drawer {
  /* position: fixed; */
  position: absolute;
  top: 63;
  left: -100%;
  /* Initially hide the drawer off-screen */
  width: 250px;
  /* Adjust the width as per your design */
  height: 100%;
  background-color: #fff;
  transition: left 0.3s ease;
  z-index: 1000;
  padding: 10px;
}

.mobile-drawer.open {
  left: 0;
  /* Slide in from the left when open */
}

.drawer-content {
  height: 100%;
  overflow: auto;
}

.mobile-toggle-section-btn {
  color: white !important;
  background-color: #2065d1 !important;
  border-radius: 7px;
  padding: 3px;
  font-weight: bold;
  border: none;
  display: flex;
  align-items: center;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

  .e-curriculum-container {
    height: calc(100% - 0%);
    overflow: auto;
    display: unset;
    width: 100%;
    overscroll-behavior: contain;
    background: #fff;
  }

  .mobile-toggle-section-div {
    display: block;
  }

  .e-curriculum-left-menu-mobile {
    display: block;
  }

  .e-curriculum-left-menu {
    display: none;
    width: calc(100% - 75%);
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
    position: sticky;
  }

  .e-curriculum-right-content {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    height: calc(100% - 0%);
    overflow: auto;
    overscroll-behavior: contain;
    position: absolute;
    right: 0;
  }

  .mobile-screen {
    display: block;
  }

  .large-screen {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

  .e-curriculum-container {
    height: calc(100% - 0%);
    overflow: auto;
    display: unset;
    width: 100%;
    overscroll-behavior: contain;
    background: #fff;
  }

  .mobile-toggle-section-div {
    display: block;
  }

  .e-curriculum-left-menu-mobile {
    display: block;
  }

  .e-curriculum-left-menu {
    display: none;
    width: calc(100% - 75%);
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    position: sticky;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
  }

  .e-curriculum-right-content {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    height: calc(100% - 0%);
    overflow: auto;
    overscroll-behavior: contain;
    position: absolute;
    right: 0;
  }


  .mobile-screen {
    display: block;
  }

  .large-screen {
    display: none;
  }


}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {



  .e-curriculum-container {
    height: calc(100% - 0%);
    overflow: auto;
    display: unset;
    width: 100%;
    overscroll-behavior: contain;
    background: #fff;
  }

  .mobile-toggle-section-div {
    display: block;
  }

  .e-curriculum-left-menu-mobile {
    display: block;
  }

  .e-curriculum-left-menu {
    display: none;
    width: calc(100% - 75%);
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    position: sticky;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
  }

  .e-curriculum-right-content {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    height: calc(100% - 0%);
    overflow: auto;
    overscroll-behavior: contain;
    position: absolute;
    right: 0;
  }

  .mobile-screen {
    display: block;
  }

  .large-screen {
    display: none;
  }




}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

  .e-curriculum-container {
    height: calc(100% - 0%);
    overflow: auto;
    display: unset;
    width: 100%;
    overscroll-behavior: contain;
    background: #fff;
  }

  .mobile-toggle-section-div {
    display: none;
  }

  .e-curriculum-left-menu-mobile {
    display: none;
  }

  .e-curriculum-left-menu {
    display: block;
    width: calc(100% - 75%);
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    position: sticky;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
  }

  .e-curriculum-right-content {
    width: calc(100% - 25%);
    padding: 15px;
    background-color: #fff;
    height: calc(100% - 0%);
    overflow: auto;
    overscroll-behavior: contain;
    position: absolute;
    right: 0;
  }


  .mobile-screen {
    display: none;
  }

  .large-screen {
    display: block;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

  .e-curriculum-container {
    height: calc(100% - 0%);
    overflow: auto;
    display: flex;
    width: 100%;
    overscroll-behavior: contain;
    background: #fff;
  }

  .e-curriculum-left-menu-mobile {
    display: none;
  }

  .mobile-toggle-section-div {
    display: none;
  }

  .e-curriculum-left-menu {
    display: block;
    width: calc(100% - 75%);
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    position: sticky;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
  }

  .e-curriculum-right-content {
    width: calc(100% - 25%);
    padding: 15px;
    background-color: #fff;
    height: calc(100% - 0%);
    overflow: auto;
    overscroll-behavior: contain;
    position: absolute;
    right: 0;
  }


  .mobile-screen {
    display: none;
  }

  .large-screen {
    display: block;
  }
}