.testimonial-container {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #39B8FF;
    width: 100%;
    /* height: 100vh; */
}

.testimonial-section {
    padding-top: 35px;
}

.Parents-sec-text-title {
    color: #591FFF;
    font-size: 22px !important;
    font-weight: bold;
    font-family: 'Inter Tight Bold';
}

.Parents-sec-text {
    font-size: 18px !important;
    font-family: 'Inter Tight Medium';
}

.Parents-name-text {
    font-size: 22px !important;
    font-weight: bold;
    font-family: 'Inter Tight Bold';
}

.Parents-name-c-text {
    font-size: 18px !important;
    font-family: 'Inter Tight Medium';
}

.carousel-indicators-Parents-sec {
    position: absolute;
    right: 0;
    bottom: -3px !important;
    left: 0;
    z-index: 2;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

.testimonial-section-title-com-white-color {
    color: #FFFFFF !important;
    font-size: 40px !important;
    text-align: center;
    font-family: 'Inter Tight Medium';

    .strong {
        font-family: 'Inter Tight Medium';
    }
}



/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
.carousel-indicators-Parents-sec{
    display: flex;
}
    .testimonial_star_img {
        position: absolute;
        width: 98px;
        left: -17px;
        top: 140px;
    }

    .mobile-screen-testimonial {
        display: block;
    }

    .large-screen-testimonial {
        display: none;
    }

    .Parents-card-sec-7 {
        height: unset;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 18px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    .multi_union_w_icon {
        position: absolute;
        bottom: 28px;
        left: 16px;
    }

    .m_end_icon {
        position: absolute;
        bottom: 37px;
        width: 190px;
        right: 0px
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .carousel-indicators-Parents-sec{
        display: flex;
    }
    .testimonial_star_img {
        position: absolute;
        width: 152px;
        left: 32px;
        top: 83px;
    }

    .mobile-screen-testimonial {
        display: block;
    }

    .large-screen-testimonial {
        display: none;
    }

    .Parents-card-sec-7 {
        height: unset;
        padding: 60px;
        background-color: #FFFFFF;
        border-radius: 18px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    .multi_union_w_icon {
        position: absolute;
        bottom: 28px;
        left: 16px;
    }

    .m_end_icon {
        position: absolute;
        bottom: 37px;
        width: 213px;
        right: 0px
    }

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .carousel-indicators-Parents-sec{
        display: none;
    }
    .testimonial_star_img {
        position: absolute;
        width: 171px;
        left: 47px;
        top: 48px;
    }

    .mobile-screen-testimonial {
        display: none;
    }

    .large-screen-testimonial {
        display: block;
    }

    .Parents-card-sec-7 {
        height: 525px;
        padding: 60px;
        background-color: #FFFFFF;
        border-radius: 18px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    .multi_union_w_icon {
        position: absolute;
        bottom: 8px;
        left: 62px
    }

    .m_end_icon {
        position: absolute;
        bottom: 37px;
        width: 213px;
        right: 50px
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .carousel-indicators-Parents-sec{
        display: none;
    }
    .testimonial_star_img {
        position: absolute;
        width: 171px;
        left: 47px;
        top: 48px;
    }

    .mobile-screen-testimonial {
        display: none;
    }

    .large-screen-testimonial {
        display: block;
    }

    .Parents-card-sec-7 {
        height: 550px;
        padding: 60px;
        background-color: #FFFFFF;
        border-radius: 18px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    .multi_union_w_icon {
        position: absolute;
        bottom: 8px;
        left: 62px
    }

    .m_end_icon {
        position: absolute;
        bottom: 37px;
        width: 213px;
        right: 50px
    }

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .carousel-indicators-Parents-sec{
        display: none;
    }
    .testimonial_star_img {
        position: absolute;
        width: 171px;
        left: 47px;
        top: 48px;
    }

    .mobile-screen-testimonial {
        display: none;
    }

    .large-screen-testimonial {
        display: block;
    }

    .Parents-card-sec-7 {
        height: 490px;
        padding: 60px;
        background-color: #FFFFFF;
        border-radius: 18px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    .multi_union_w_icon {
        position: absolute;
        bottom: 8px;
        left: 62px
    }

    .m_end_icon {
        position: absolute;
        bottom: 37px;
        width: 213px;
        right: 50px
    }
}