/* section-3-container */

.section-3-container {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #FFEFC6;
    width: 100%;
    /* height: 100vh; */
}

.section-3 {
    padding-top: 35px;
}



.sections-title-com {
    color: #591FFF !important;
    font-size: 40px !important;
    text-align: center;
    font-family: 'Inter Tight Medium';

    .strong {
        font-family: 'Inter Tight Medium';
    }
}

.meeku-world-card {
    padding: 30px 25px;
    background-color: #FFFFFF;
    border-radius: 18px;
    display: flex;
    align-items: center;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    z-index: 1;
}

.meeku-world-card-title {
    font-family: 'Inter Tight Medium';
    font-weight: bold;
    font-size: 22px;
}

.meeku-world-card-text {
    font-family: 'Inter Tight Medium';
    font-size: 18px;
}


.outer-circle {
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.section {
    width: 100%;
    height: 100%;
    position: absolute;
    clip-path: polygon(50% 50%, 0 0, 100% 0);
}

.FocussedSessions {
    background-color: #FC7234;
    transform: rotate(45deg);
}

.ProblemSolving {
    background-color: #00c9fe;
    transform: rotate(135deg);
}

.TeamLearning {
    background-color: #29007f;
    transform: rotate(-45deg);

}

.ExperiencedTutors {
    background-color: #6800D3;
    transform: rotate(-135deg);
}


.circleactive {
    font-weight: bold;
}

.section-text {
    position: absolute;
    top: 11%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    font-weight: bold;
}


.inner-circle {
    width: 160px;
    height: 160px;
    background-color: #ffefc6;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-content {
    display: flex;
    justify-content: center;
}

.image-container-circle {
    position: relative;
}

.focussed-section-text-img {
    position: absolute;
    top: 20px;
    transform: rotate(40deg);
}

.path-text-tr {
    fill: transparent;
}

.section-text-1 {
    position: absolute;
    top: -32px;
    left: 46px;
    transform: rotate(39deg);
}

.section-text-2 {
    position: absolute;
    top: -32px;
    left: 56px;
    transform: rotate(39deg);
}

.section-text-3 {
    position: absolute;
    top: -32px;
    left: 58px;
    transform: rotate(39deg);
}

.section-text-4 {
    position: absolute;
    top: -32px;
    left: 50px;
    transform: rotate(39deg);
}

.section-text-all {
    fill: white !important;
    font-size: 13px;
}



/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {


    .section_star_img {
        display: none;
    }

    .container-img-section {
        padding-left: 20px;
        padding-right: 20px;
    }

    .yellowLine-img-2 {
        margin-left: 10px;
        width: 26%;
    }

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

    .section_star_img {
        display: none;
    }

    .container-img-section {
        padding-left: 60px;
        padding-right: 60px;
    }

    .yellowLine-img-2 {
        margin-left: 10px;
        width: 20%;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    .section_star_img {
        display: none;
    }

    .container-img-section {
        padding-left: 12px;
        padding-right: 12px;
    }

    .yellowLine-img-2 {
        margin-left: 10px;
        width: 17%;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

    .section_star_img {
        display: block;
    }

    .container-img-section {
        padding-left: 120px;
        padding-right: 120px;
    }

    .yellowLine-img-2 {
        margin-left: 10px;
        width: 11%;
    }
}

/** #endregion */

.section_star_img {
    position: absolute;
    top: 15%;
    width: 13%;
    right: 99px;
}
