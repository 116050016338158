.cou_nav_title{
    font-size: 40px !important;
    color: white !important;
    text-decoration: none;
    margin-left: 20px;
}
.nav__header_session {
  background-color: #5121b9e6 !important;
  border-bottom: 1px solid gray !important;
}
.introduction_text {
    color: blueviolet;
    font-size: 25px;
    font-weight: bold;
  }
  .price_session_text {
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.546);
  }
  .price_session_number {
    font-size: 22px;
    color: black;
  }
  .instructors_text {
    font-size: 18px;
  }
  .intro__section{
    background-color: white;
    padding: 18px;
    border-radius: 10px;
  }
  .enrool__cour_btn {
    background-color: black !important;
    color: white;
    border-radius: 0px !important;
    margin-top: 7px;
    margin-bottom: 7px;
    width: 100%;
    font-size: 16px;
  }
.card_courses__title{
    font-size: 23px;
    font-weight: bold;
}  

.card_courses__session{
    font-size: 20px;
}
.card__free_session{
    border-radius: 0px;
    margin-bottom: 30px;
    height: 300px;
    border: 1px solid gray;
}