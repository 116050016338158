
.course-view-container-v2{
    height: 100%;
}

.height-100{
    height: 100%;
}

.border-radius-20{
    border-radius: 20px;
}

video#course-view-video {
    background: #41464b;
    width: 200px;
    position: absolute;
    margin-top: 8px;
    margin-left: 8px;
    visibility: hidden;
}
/* @media (min-width: 760px) and (max-width: 1460px) {
    video#course-view-video {
        width: 74vw;
        height: 60vh;
    }
}
@media (min-width: 1460px) and (max-width: 1600px) {
    video#course-view-video {
        width: 74vw;
        height: 70vh;
    }
}
@media (min-width: 1600px) and (max-width: 2000px) {
    video#course-view-video {
        width: 74vw;
        height: 80vh;
    }
} */

.whiteboard-locked-morph-div-v2{
    width: calc(100% - 593px);
    height: calc(100% - 148px);
    position: absolute;
  }

  .content-section-iframe-v2{
    width: 100%;
    border-radius: 24px;
  }

  .webviewer {
    flex: 1;
    margin: 8px;
    -webkit-box-shadow: 1px 1px 10px #999;
            box-shadow: 1px 1px 10px #999;
  }

 .embed-code-style-v2{
     height: 100%;
 }

 .embed-code-style-v2 iframe{
    border-radius: 24px;
 }

 .punch-viewer-navbar-logo {
    display: none!important;
}
