.close-icon {
  font-size: 25px;
  cursor: pointer;
}

.e-apps-list {
  padding: 2px 8px 2px 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
}

.e-apps-list-in-active {
  background: #e8e0ff;
  color: #591fff;
}

.e-apps-list-active {
  background: #591fff;
  color: #fff;
}

.e-host-course-list-v2 {
  border-radius: 15px;
  background-color: white;
  /* height: 100%; */
  overflow: auto;
  position: absolute;
  top: 247px;
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 250px);
}

.e-role-label {
  background: #3b4357;
  width: 100%;
  color: white;
  padding: 8px;
  font-weight: bold;
  font-size: 20px;
  position: fixed;
  width: 280px;
  z-index: 1;
}
.host-course-item {
  padding: 5px;
  display: flex;
  color: #6c757d;
}

.e-courses-list-main-v2 {
  overflow: auto;
  /* height: calc(100% - 320px); */
  position: absolute;
  width: 100%;
  /* top: 40px; */
}

.course_list_ul {
  list-style: none;
}
.course_list_ul li:hover {
  background-color: #f1f3f6;
  cursor: pointer;
}
.course_list_li_text {
  font-size: 16px;
}

.dropdown-check-list {
  display: inline-block;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list.visible .items {
  display: block;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.play-btn-span :hover {
  cursor: pointer;
  border: 3px solid #522bc1;
  border-radius: 50px;
}

.play-btn-span-disabled :hover {
  cursor: pointer;
  border: 3px solid rgb(152, 136, 136);
  border-radius: 50px;
}

.active-session {
  border-left: 5px solid #522bc1;
  background-color: #f1f3f6;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
