.user-name-container-det {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.user-name-text-div {
  cursor: pointer;
}

.user-name-icon-det {
  width: 30px;
  height: 30px;
  background-color: #8eb3df;
  border-radius: 36px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #1e0e0e;
  cursor: pointer;
  overflow: hidden;
}

.user-name-text {
  color: black;
}

.submit-opt-btn-v4 {
  color: white !important;
  border-radius: 44px;
  background: #4011C6;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  border: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.active-your-account-v4 {
  color: red;
  font-size: 12px;
  padding-top: 10px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .user-name-text {
    display: none;
  }

  .mobile-screen-user-name-details {
    display: block;
  }

  .large-screen-user-name-details {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .user-name-text {
    display: none;
  }

  .mobile-screen-user-name-details {
    display: block;
  }

  .large-screen-user-name-details {
    display: none;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .user-name-text {
    display: block;
  }

  .mobile-screen-user-name-details {
    display: block;
  }

  .large-screen-user-name-details {
    display: none;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .user-name-text {
    display: block;
  }

  .mobile-screen-user-name-details {
    display: none;
  }

  .large-screen-user-name-details {
    display: block;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .user-name-text {
    display: block;
  }

  .mobile-screen-user-name-details {
    display: none;
  }

  .large-screen-user-name-details {
    display: block;
  }
}