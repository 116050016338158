/* .white-boards-tab {
    padding: 20px;
  
    margin-top: 20px;
    overflow-y: scroll;
    max-height: 88vh;
} */

.breakout-progress-navbar {
    background: rgb(250, 236, 34);
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border: 5px;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: #522bc1;
    height: 25px;
}