/* nav.meeku-header.navbar.navbar-expand-md.navbar-dark {
    background: #522bc1;
} */

.cursor-pointer {
  cursor: pointer;
}
.meeku-breakout-room-header-v2 {
  background: #ffee6c !important;
  height: 40px;
}

.meeku-header {
  background: #522bc1 !important;
  padding: 15px;
}

.meeku-header-icon {
  font-size: 30px;
  color: white;
}

/* RAISE HAND */
.raise-hand-left-initial-v2 {
  background: #9898ae;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  padding: 7px 5px 6px 5px;
  font-size: 14px;
  font-weight: 500;
}

.raise-hand-name-v2 {
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 30px;
}

.lower-hand-container-v2 {
  cursor: pointer;
  height: 20px;
  background: #e3cdcd;
  color: black;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.raised-hand-notification-dot-e-v2 {
  position: absolute;
  top: -5px;
  right: -7px;
  width: 15px;
  height: 15px;
  background: yellow;
  border-radius: 50%;
}
.clicked-raised-hand-ex-v2 {
  background: #213d5e !important;
}

.hand-icon-container {
  position: relative;
  cursor: pointer;
}

.breakout-session-header-expert-main-v2 {
  color: #5901e6;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.end-breakout-session-by-expert-btn-v2 {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-left: 15px !important;
  padding: 2px 10px 2px 10px;
  border-radius: 30px;
  background-color: red;
  color: #fff;
}

.header-e-opened_group_floating_lable_v2 {
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #23237c;
  padding: 6px 10px 6px 10px;
  border-radius: 10px;
}

.leave-group-text-btn-v2 {
  background: red;
  margin-left: 8px;
  padding: 2px 6px 2px 6px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.text-color-white {
  color: #ffffff;
}

/* new */

.controls-container-ex-v2 {
  display: flex;
  justify-content: center;
}

.e-class-control-box-v2 {
  color: #fff;
  position: relative;
  background-color: #2b5d9a;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-right: 16px;
}

.control-red-bg {
  background-color: #f34444 !important;
}

.control-red-bg-in-active {
  background-color: gray;
  cursor: default;
}

.e-control-icons-v2 {
  color: #ffffff;
  font-size: 24px;
}

.loading-dot-e-v2 {
  font-size: 24px;
  font-weight: 900;
}

/* RAISE HAND */
.raise-hand-left-initial {
  background: #9898ae;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  padding: 7px 5px 6px 5px;
  font-size: 14px;
  font-weight: 500;
}

.raise-hand-name {
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 30px;
}

.expert-class-room-header-padding-v2 {
  padding: 10px 0px 10px 0px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .mobile-screen {
    display: block;
  }
  .mobile-screen-portrait {
    display: block;
  }
  .mobile-screen-landscape {
    display: none;
  }
  .large-screen {
    display: none;
  }

  .expert-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 7px 0px 7px;
    width: 100%;
  }

  /* HEADER */
  .expert-class-room-header-height-v2 {
    align-items: center;
    height: 30px;
  }

  .expert-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .expert-class-room-short-logo-v2 {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 17px;
    height: 17px;
  }

  .expert-class-room-bread-crumbs-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .learner-class-room-session-timing-v2 {
    width: 49.87px;
    height: 20.29px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 115px);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .mobile-screen {
    display: block;
  }
  .mobile-screen-portrait {
    display: none;
  }
  .mobile-screen-landscape {
    display: block;
  }
  .large-screen {
    display: none;
  }

  .expert-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 7px 0px 7px;
    width: 100%;
  }

  /* HEADER */
  .expert-class-room-header-height-v2 {
    align-items: center;
    height: 30px;
  }

  .expert-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .expert-class-room-short-logo-v2 {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 17px;
    height: 17px;
  }

  .expert-class-room-bread-crumbs-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .learner-class-room-session-timing-v2 {
    width: 49.87px;
    height: 20.29px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 115px);
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .mobile-screen {
    display: block;
  }
  .mobile-screen-portrait {
    display: none;
  }
  .mobile-screen-landscape {
    display: block;
  }
  .large-screen {
    display: none;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .mobile-screen {
    display: none;
  }
  .large-screen {
    display: block;
  }

  .expert-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 20px 0px 20px;
    width: 100%;
  }

  /* HEADER */
  .expert-class-room-header-height-v2 {
    align-items: center;
    height: 60px;
  }

  .expert-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .expert-class-room-short-logo-v2 {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 32px;
    height: 32px;
  }

  .expert-class-room-bread-crumbs-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
  }

  .learner-class-room-session-timing-v2 {
    width: 75.67px;
    height: 32px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 70px);
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .mobile-screen {
    display: none;
  }
  .large-screen {
    display: block;
  }

  .expert-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 20px 0px 20px;
    width: 100%;
  }

  /* HEADER */
  .expert-class-room-header-height-v2 {
    align-items: center;
    height: 60px;
  }

  .expert-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .expert-class-room-short-logo-v2 {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 32px;
    height: 32px;
  }

  .expert-class-room-bread-crumbs-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
  }

  .learner-class-room-session-timing-v2 {
    width: 75.67px;
    height: 32px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 70px);
  }
}
