.left-column-sp {
    background-color: #591FFF;
    /* height: 100vh; */
  }
  
  .right-column-pr-sp {
    background-color: #EAE5F5;
    /* height: 100vh; */
  }
  
  
  .meeku-icon-sp-div {
    width: 85px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 19px;
    /* left: 120px;
    top: 1701px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
  }
  
  .Coding-With-Title-sp {
    font-family: 'Inter Tight Medium';
    font-size: 32px;
    font-weight: 700;
    color: #FFFFFF;
  }
  
  .Coding-With-card-sp {
    background: #591FD6;
    border-radius: 9px;
    padding: 10px;
  
  }
  
  .Coding-With-card-le-text-sp {
    font-family: 'Inter Tight Medium';
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
  }
  
  .Coding-With-card-ri-text-sp {
    font-family: 'Inter Tight Medium';
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
  }
  
  .m-icon-sp {
    position: absolute;
    top: 222px;
    width: 70px;
  }
  
  .yellow_star_img_sp {
    width: 150px;
  }
  
  .userIcon-prv2 {
    width: 62px;
  }
  
  .edit-course-in-registration{
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 0px;
  }
  
  
  .parent-reg-card-sp {
    background: #FFFFFF;
    border-radius: 9px;
    padding: 15px 28px;
    z-index: 1;
  }
  
  
  
  .section3_star_img_sp {
    position: absolute;
    top: 220px;
    width: 100px;
  }
  
  .interactive_section_mm_img_sp {
    width: 50%;
  }
  
  .registration-complete-text-sp {
    font-family: 'Inter Tight Medium';
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #591FFF;
  }
  
  .Submit-btn-sp {
    font-family: 'Inter Tight Medium';
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background: #F9BA1A;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
    border: none;
  }
  
  .Submit-btn-sp span {
    color: #000000 !important;
    font-weight: 700;
  }
  
  .complete-qu-text-sp {
    font-family: 'Inter Tight Medium';
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  
  .complete-an-text-sp {
    font-family: 'Inter Tight Medium';
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #3B4357;
  }
  
  /* Custom, iPhone Retina */
  @media only screen and (min-width : 320px) {
    
    .left-column-sp-section {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 50px;
      padding-bottom: 20px
    }
    .right-column-pr-sp-section {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 25px;
      padding-bottom: 20px
    }
    .m-icon-sp{
      display: none;
    }
    
  }
  
  /* Extra Small Devices, Phones */
  @media only screen and (min-width : 480px) {
    .left-column-sp-section {
      padding-left: 70px;
      padding-right: 70px;
      padding-top: 70px;
      padding-bottom: 20px
    }
    .right-column-pr-sp-section {
      padding-left: 70px;
      padding-right: 70px;
      padding-top: 25px;
      padding-bottom: 20px
    }
    .m-icon-sp{
      display: block;
    }
  }
  
  /* Small Devices, Tablets */
  @media only screen and (min-width : 768px) {
  
    .left-column-sp-section {
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 70px;
      padding-bottom: 20px
    }
    .right-column-pr-sp-section {
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 25px;
      padding-bottom: 20px
    }
    .m-icon-sp{
      display: block;
    }
  }
  
  /* Medium Devices, Desktops */
  @media only screen and (min-width : 992px) {
  
    .left-column-sp-section {
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 70px;
      padding-bottom: 20px
    }
    .right-column-pr-sp-section {
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 25px;
      padding-bottom: 20px
    }
    .m-icon-sp{
      display: block;
    }
  }
  
  /* Large Devices, Wide Screens */
  @media only screen and (min-width : 1200px) {
    .left-column-sp-section {
      padding-left: 125px;
      padding-right: 125px;
      padding-top: 70px;
      padding-bottom: 20px
    }
    .right-column-pr-sp-section {
      padding-left: 125px;
      padding-right: 125px;
      padding-top: 25px;
      padding-bottom: 20px
    }
    .m-icon-sp{
      display: block;
    }
  }
  
  .parent-reg-mobile-number{
    border-radius: 8px;
  }
  
  .requiredField{
    color: #FF4842;
  }