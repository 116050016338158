.introduction_text{
    color: blueviolet;
    font-size: 25px;
    font-weight: bold;
}
.price_session_text{
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.546);
}
.price_session_number{
    font-size: 22px;
    color:black;
}
.instructors_text{
    font-size: 18px;
}
.Patricia_text{
    color: blue;
    font-size: 15px;
}
.Design_Expert_text{
    font-size: 15px ;
    color: rgba(0, 0, 0, 0.546);
}
.about_course_text{
    font-size: 23px;
    font-weight: 600;
}
.about_course_discription_text{
    font-size: 17px;
}
.course_glance_text{
    font-weight: bold;
    font-size: 20px;
}
.Duration_text{
    color: rgba(0, 0, 0, 0.637);
}
.between_text{
    color: rgba(0, 0, 0, 0.82);
}
.course_glance_card{
    border: 2px solid blueviolet;
}
.learners_card{
    border: 2px solid blueviolet;
}
.required_text{
    color: rgba(0, 0, 0, 0.637); 
}
.Categories_btn{
   
    border-radius: 20px; 
   color: black;
    font-size: 13px;
    margin-right: 5px;
}
.Session_title_text{
    font-weight: bold;
    font-size: 18px;
}
.instructors_text_2{
    font-weight: bold;
    font-size: 23px;
}