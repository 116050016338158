.pre_assessment_list_card{
    border: 2px solid;
    border-color: lightgray;;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,0.2);
    border-top-width: 10px;
    border-top-color: orange;
}
.add_curriculum_card{
    background-color: hsl(0, 0%, 83%);
   
    
}
.add_option_text{
    font-size: 12px;
}
.correct_ans_dis_text{
   padding-left: 4%;
   padding-right: 4%;
   
}
.correct_ans_text{
    font-size: 13px;
   
}
.correct_answer_text{
    margin-right: 80px;
    color: green;
}