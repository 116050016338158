.free-trial-label{
  font-size: 12px;
  background: orange;
  padding: 2px 5px 5px 2px;
  border-radius: 5px;
  font-weight: 600;
}

.self-learning-main-container{
  height: 100%;
}

.solo-player-text{
  font-size: 10px;
  position: absolute;
  background: #d1d157;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.dim-opacity{
  opacity: 0.5;
  cursor:not-allowed !important;
}

.locked-icon-div{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.share-code-btn{
  float: right;
  padding: 5px 5px 5px 5px;
  background: #aeaef2;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.share-code-btn:hover{
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.share-code-text {
  padding: 5px 5px 5px 5px;
  background: #aeaef2;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  cursor: text;
  margin-bottom: 10px;
  text-align: center;
}

.share-code-text-in-detail{
  padding: 5px 5px 5px 5px;
  background: #aeaef2;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  cursor: text;
  text-align: center;
}

.self-learning-w-close{
  position: absolute;
    right: 10px;
    font-size: 12px;
    cursor: pointer;
}

.self-learning-whiteboard-iframe-gsp {
  width: 100%;
  height: 87%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid black;
}

.self-learning-empty-board{
  text-align: center;
    height: 87%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border: 1px solid black;
}

.share-code-start-game-btn{
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  margin-right: 10px;
}

.join-with-code-btn{
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 6px 8px 6px 8px;
  margin-right: 10px;
  float:right;
}

.players-status-dot{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: green;
}

.chat-floating-icon{
  position: absolute;
  bottom: 25px;
  right: 25px;
  color: #f6f603;
}

.my-friends-list-main-div{
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 2;
  width: 100%;
    /* background: black; */
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
    /* padding: 10px 10px 10px 10px; */
  overflow: hidden;
  border-top: 1px solid #D4D9DF;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
}

.my-friends-list-inner-div{
  height: 100%;
  width: 30%;
  background: #fff;
  position: fixed;
  right: 0;
  padding: 10px 10px 10px 10px;
}

.my-friends-title{
  font-size: 16px;
  font-weight: 600;
}

.my-friends-list-ul{
  padding: 10px 0px 10px 0px;
  cursor: pointer;
}

.my-friends-list-ul:hover{
  background-color: #f1ecec;
  border-radius: 8px;
}

.my-friends-name{
  font-size: 14px;
}

.my-friends-close-btn{
  position: absolute;
  right: 0;
}

.my-friends-chat-text-box-main-div{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 10px 10px 10px 10px;
}
.individual-chats-main-div{
  height: calc(100% - 92px);
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  flex-direction: column-reverse;
  display: flex;
  background: #e6f5e6;
}

.my-name-container-ind-chat{
  text-align: right;
}

.my-name-ind-chat{
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.chat-my-msg-container-ind-chat{
  width: 80%;
  float: right;
  background-color: #13b8ff;
  padding: 8px 10px 8px 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rvr-msg-container-ind-chat{
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #fff;
  word-wrap: anywhere;
}

.rvr-name-ind-chat {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.rvr-container-ind-chat {
  display: flex;
  align-items: center;
  position: relative;
}

.my-friend-avatar-ind-chat {
  background: #084159 !important;
  width: 40px;
  height: 40px;
  margin-right: 6px;
  margin-left: 6px;
  border-radius: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.rvr-avatar-container-ind-chat {
  background: #084159 !important;
  width: 40px;
  height: 40px;
  margin-right: 6px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.rvr-msg-container-div-ind-chat {
  margin-left: 46px;
}

.chat-rvr-name-ind-chat {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.chat-rvr-msg-container-ind-chat {
  float: left;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
  padding: 10px 10px 10px 10px;
  width: 80%;
}

.rvr-msg-container-ind-chat {
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #fff;
  word-wrap: anywhere;
}

.chat-rvr-msg-ind-chat {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  word-wrap: anywhere;
}

#ind-chat-input-id{
  padding: 10px 10px 10px 10px;
}

.ind-chat-input{
  width: 85%;
  height: 45px;
  border-radius: 30px;
  border: 1px solid #D4D9DF;
}

.ind-chat-send-btn{
width: 15%;
height: 45px;
}

.border-bottom-style{
  border-bottom: 1px solid #D4D9DF;
  margin-bottom: 5px;
}

.my-friends-no-records-found {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.buy-plan-link{
  color: var(--bs-link-color);
  cursor: pointer;
  text-decoration: underline;
}

.s-title{
  font-size: 20px;
  font-weight: 600;
}