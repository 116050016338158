.games_title_text{
    font-size: 25px;
    font-weight: bold;
}

.add_new_games_btn{
    background-color: #f07820 !important;
    color: white !important;
    border-radius: 20px; 
    padding: 10px;
    width: 150px;
}
.card_game_title{
    font-size: 20px;
    font-weight: bold;
}
.card_game_sub_title{
    font-size: 16px;
}
.game_dot_icon{
    color: #778190;
}
.play_of_time{
    color: #778190;
}
.play_game_icon{
    color: #778190;
    margin-right: 3px;
}
.search_course_name{
    border: 1px solid grey;
    padding: 4px;
    

}

 
  
  .name_title_text {
    font-size: 12px;
    font-weight: normal;
    color: #778190;
  }
  .name_text {
    color: #3b4357;
    font-size: 16px;
  }
  
  .games_card{
    padding: 13px;
    margin-right: 3px;
    width: 350px;
    border-radius: 6px;
    border: solid 2px #f1f3f6;
    background-color: #fff;
    cursor: pointer;
  }
 
  .upload_game_file{
    font-size: 14px;
    font-weight: 600;
}
.upload_b_text_file{
    font-size: 12px;
    color: #778190;
}
  .kids_course {
    color: #778190;
    font-size: 14px;
  }
  .kids_name {
    color: #3b4357;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    top: 6px;
  }
  .kids_text_section {
    margin-left: 15px;
  }
  
  .payment_table_header {
    background-color: #3b4357;
  }
  .payment_table_header_text {
    color: white !important;
  }
  
  
  
  .list-group-item{
    float: left;
    list-style-type: none;
  }
  
  
  
  .nav-link {
    border: none;
    outline: none;
   
    
    cursor: pointer;
  }
  /* popup */
 
  .add_kids_close_icon {
    position: absolute;
    right: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  .deploy_game_btn{
    background-color: #f07820 !important;
    color: white !important;
    border-radius: 20px; 
    width: 100px;
    font-size: 12px;
  }

  .games_save_btn{
    background-color: #f07820 !important;
    color: white !important;
    border-radius: 20px; 
    width: 100px;
    padding: 10px;
    margin-right: 10px;
  }

  .games_save_btn_disabled{
    background-color: #bd9f9f !important;
    color: white !important;
    border-radius: 20px; 
    padding: 10px;
    margin-right: 10px;
  }

  .upload_section {
    position: relative;
    top: 30%;
  }
  .mykids_upload_text{
    font-size: 14px;
    
  }