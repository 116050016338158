.form-bg {
  background-image: url("../../../assets//img/landing-banner.png");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

/* RESPONSIVE SCREENS */
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .form-bg {
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-bg-c {
    width: 100%;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .form-bg {
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-bg-c {
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .form-bg-c {
    width: 50%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .form-bg-c {
    width: 50%;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .form-bg-c {
    width: 50%;
  }
}

.parent_reg_section_box {
  background-color: grey;
  height: 100vh;
}

.small_collabor_box {
  background-color: white;
  border-radius: 25px;
  border: 1px solid gray;
  padding: 12px;
  margin-left: 5px;
}
.small_inside_collabor_box {
  padding: 30px;
  border-radius: 4px;
  background-color: #8080807d;
  margin-bottom: 20px;
}
.card_collabor_circle {
  width: 62px;
  height: 61px;
  margin-left: 8px;
  background-color: darkgray;
  border-radius: 50px;
  align-items: center;
  text-align: center;
}
.collabor_text {
  font-size: 23px;
  font-weight: bold;
  margin-top: 10px;
  color: white;
}
.par_regi_meeku__text {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}
.par_regi_form_session {
  padding: 30px;
  border: 1px solid gray;
}
.parent_regi_next_btn {
  background-color: #f07820 !important;
  color: white;
  border-radius: 20px !important;
  margin-top: 7px;
  width: 100%;
  padding: 7px;
  font-size: 17px;
}
.parent_regi_addkid_btn {
  background-color: #f07820 !important;
  color: white;
  border-radius: 0px !important;
  width: 100%;
  padding: 7px;
  font-size: 17px;
}
.kid_info_card {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}
.parent_courses_ul {
  list-style-type: none;
}
.parent_kids_name {
  font-size: 18px;
  font-weight: bold;
}
.kids_years {
  font-size: 12px;
}
._parent_kids_text_section {
  margin-left: 15px;
}
.parent_kid_addkid_btn {
  background-color: #f07820 !important;
  color: white;
  border-radius: 0px !important;
  width: 100%;
  padding: 7px;
  font-size: 17px;
}
