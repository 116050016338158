.side-bar-container {
  height: 88vh;
}

.fa-gradient {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f00),
    to(#333)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  color: rgb(184, 149, 11);
}

.side-bar-item {
  padding: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #dd8233;
  font-weight: 900;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}

/* .side-bar-item:hover {
    background: #f8de8a8c;
    border-left: 5px solid #dd8233;
} */

.side-bar-item-active {
  position: relative;
  padding: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #dd8233;
  font-weight: 900;
  margin-bottom: 10px;
  background: #f8de8a8c;
  border-left: 5px solid #dd8233;
}

.meeku-settings {
  position: absolute;
  bottom: 8vh;
  left: 3vh;
}

.settings-menu {
  cursor: pointer;
  position: fixed;
  margin-left: 28px;
  bottom: 40px;
}

.settings-icon {
  font-size: 25px;
  margin-right: 10px;
}

.general-notification-dot-side-bar{
  position: absolute;
  top:0;
  right: 0;
  width: 15px;
  height: 15px;
  background: rgb(23, 211, 23);
  border-radius: 50%;
}