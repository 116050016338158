.board {
    width: 100%;
    height: 100%;
    overflow: scroll;
 
    
}

.sketch {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    /* width: 3000px;
    height: 1000px;
    overflow: scroll; */
}