.e-pods-selected{
  border: 2px solid rgb(3, 150, 3) !important;
}
.e-pods-not-selected{
  border: 2px solid rgb(216, 172, 172) !important;
}

.pods_label_t_text {
  font-size: 13px;
  color: #778190;
  font-weight: 600;
}

.pods_label_s_t_text {
  font-size: 16px;
  font-weight: 600;
}

.pod_1_text {
  font-weight: 600;
  margin-top: 10px;
}

.card_pods_text_section {
  background-color: whitesmoke;
  padding: 10px;
}

.no_groups_box {
  border: 2px solid orange;
  background-color: #fffee7;
  padding: 10px;
  border-radius: 3px;
}

.no_groups_text {
  color: orange;
}

.pod_box_section_2 {
  border: 2px solid lightgray;
  padding: 10px;
  border-radius: 3px;
}

.pod_box_complete_text {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.pod_box_session_text {
  font-size: 13px;
  font-weight: 600;
  color: #778190;
}

.pods_card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: var(--card-border-radius);
}

.pod_box_date_text {
  font-size: 13px;

  color: #778190;
}

.pod_start_btn {
  color: white !important;
  background-color: #f07820 !important;
  border-radius: 15px;
  padding: 3px;
  width: 130px;
  margin-top: 16px;

}

.pod_view_btn {
  color: #f07820 !important;
  border: 2px solid lightgray;
  border-radius: 15px;
  padding: 2px;
  width: 130px;
  margin-top: 16px;
}

.badge_Enrolled_btn {
  margin-left: 20px;
  margin-right: 20px;
}

.e-pod-chats {
  position: absolute;
  right: 0px;
}

.e-pods-streams-container {
  display: flex;
  margin-top: 15px;
}

.e-pods-streams-boxes {
  cursor: pointer;
  width: 77px;
  height: 98px;
  margin-right: 10px;
  border: 2px solid rgb(201, 140, 140);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.e-pods-streams-boxes-completed {
  background-color: rgb(244 232 232);
  border: none !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.e-pods-footer-labels {
  font-size: 14px;
  font-weight: 600;
  color: #0089ff;
}

.e-pods-streams-boxes-not-completed {
  border: 2px solid rgb(3, 150, 3) !important;
  cursor: pointer !important;
}

.e-pods-streams-boxes-upcoming {
  background-color: #fff !important;
  border: 1.5px solid rgb(233 208 208);
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.e-pods-start-date {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

/* CHATS */
.e-pods-chat {
  color: green;
}



.e-pods-chat-main {
  position: relative;
  height: 100%;
}

.e-pods-chat-header {
  padding: 10px 15px 10px 15px;
  color: #fff;
}

.e-pods-chat-body {
  height: calc(100% - 223px);
}

.e-pods-chat-footer {
  position: absolute;
  width: 100%;
  bottom: 2px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  .e-pods-chat-container {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 20%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .e-pods-chat-container {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 40%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .e-pods-chat-container {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 40%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .e-pods-chat-container {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 60%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
 
  .e-pods-chat-container {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 70%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
  
}