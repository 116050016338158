

.form-bg-demo{
    background-image: url('../../../assets/img/learner-login.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

.company-logo-container{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.company-text{
    font-size: 15px;
}

/* RESPONSIVE SCREENS */
/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .form-bg-demo{
        padding-left: 20px;
        padding-right: 20px;
    }
    .form-bg-c-demo{
        width: 100%;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .form-bg-demo{
        padding-left: 20px;
        padding-right: 20px;
    }
    .form-bg-c-demo{
        width: 100%;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .form-bg-c-demo{
        width: 30%;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .form-bg-c-demo{
        width: 33%;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
.form-bg-c-demo{
    width: 33%;
}
}