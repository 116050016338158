.navbar-cv2-course-header {
    background: #FFFFFF !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 35px;
    position: fixed;
    width: 100%;
    z-index: 2000;
}

.nav-item-active{
    border-bottom: 4px solid #591FFF;
    border-radius: 4px;
}

.navbar-hv3 {
    background: unset !important;
}

.navbar-hv3-mobile-course-header {
    background: #FFFFFF !important;
    position: fixed;
    width: 100%;
    z-index: 2000;
}

.navbar-header-hv3 {
    /* position: absolute; */
    z-index: 2;
    /* top: 1%; */
}

.nav-link-text-hv3 {
    color: #FFFFFF !important;
}


.nav-link-text-cv2 {
    font-weight: 600;
    font-size: 15px;
    color: #591FFF !important;
    font-family: 'Inter Tight Medium';
    letter-spacing: 0.5px;
}

.m-icon {
    position: absolute;
    top: 49%;
}

.userIcon-hv3 {
    width: 55%;
}

.toggler-icon-hv3 {
    border: none !important;
    box-shadow: none !important;
}

.meeku_mobile_logo_cv2 {
    width: 126px;
    cursor: pointer;
}



/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
    .mobile-screen-course-header {
        display: block;
    }

    .large-screen-course-header {
        display: none;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .mobile-screen-course-header {
        display: block;
    }

    .large-screen-course-header {
        display: none;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .mobile-screen-course-header {
        display: none;
    }

    .large-screen-course-header {
        display: block;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .mobile-screen-course-header {
        display: none;
    }

    .large-screen-course-header {
        display: block;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .mobile-screen-course-header {
        display: none;
    }

    .large-screen-course-header {
        display: block;
    }
}
