.share-code-course-det-session-div {
    background-color: whitesmoke !important;
    padding-top: 30px;
    padding-bottom: 30px;
}

.share-code-course-det-session {
    display: flex;
    justify-content: center;
}

.share-code-course-det-title {
    box-sizing: border-box;
    min-width: 0;
    margin-bottom: 24px;
    color: #454545;
    font-weight: 900;
    font-size: 22px;
    line-height: 36px;
}

.share-code-course-det-img {
    width: 304px;
    height: 171px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 20px;

}

.share-code-course-det-your-challenge-session {
    width: 100%;
    height: 60px;
    background-color: white !important;
    border-radius: 54px;
    display: flex;
    flex-direction: row;
    padding: 0 12px 0 84px;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.share-code-course-det-header-title {
    font-size: 22px;
    color: #684ff8;
    font-weight: 900;
    text-transform: uppercase;
}

.share-code-course-det-end-text {
    margin-right: 10px;
    font-weight: 700;
    font-size: 17px;
}

.share-code-course-det-checkpoint-icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.share-code-course-det-checkpoint-bullet {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #b2b2b2;
    margin-left: 18px;
}

.share-code-course-det-content {
    margin-left: 52px;
    margin-top: 50px;
    margin-bottom: 10px;
}

.share-code-course-det-line-component.rounded-s-h {
    border-bottom-left-radius: 18px !important;
    border-bottom-right-radius: 18px !important;
}

.share-code-course-det-line-component {
    width: 8px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #b2b2b2;
}

.share-code-course-det-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 26px;
    margin-top: -21px;
    position: relative;
}