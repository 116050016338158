.faq-container-section {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #F2F6F8;
    width: 100%;
    /* height: 100vh; */
}

.faq-section {
    padding-top: 35px;
}

.Accordion-Frequently-sec-faq {
    z-index: 1;
}

.Accordion-Summar-Frequently-sec-faq {
    padding-top: 10px;
    padding-bottom: 10px;
}

.mm-img-Frequently-faq {
    position: absolute;
    bottom: 0px;
}

.Accordion-title-Frequently-faq {
    font-family: 'Inter Tight Medium';
}

.faq-section-title {
    color: #591FFF !important;
    font-size: 40px !important;
    text-align: center;
    font-family: 'Inter Tight Medium';

    .strong {
        font-family: 'Inter Tight Medium';
    }
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
    .faq-section-container-div {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom:30px;
    }
    .multi-union-2-img-faq {
        position: absolute;
        top: 15%;
        right: 10%;
        width: 140px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .faq-section-container-div {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom:50px;
    }
    .multi-union-2-img-faq {
        position: absolute;
        top: 9%;
        right: 7%;
        width: 140px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .faq-section-container-div {
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom:50px;
    }
    .multi-union-2-img-faq {
        position: absolute;
        top: 9%;
        right: 7%;
        width: 140px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .faq-section-container-div {
        padding-left: 115px;
        padding-right: 115px;
        padding-bottom:60px;
    }
    .multi-union-2-img-faq {
        position: absolute;
        top: 9%;
        right: 7%;
        width: 140px;
    }

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .faq-section-container-div {
        padding-left: 165px;
        padding-right: 165px;
        padding-bottom:60px;
    }

    .multi-union-2-img-faq {
        position: absolute;
        top: 9%;
        right: 7%;
        width: 140px;
    }

}