.quiz_text {
  font-size: 25px;
  font-weight: bold;
}
.add_new_quiz_btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 10px;
  width: 140px;
}
.search_quiz_name {
  border: 1px solid grey;
  padding: 4px;
}
.card_quiz_title {
  font-size: 20px;
  font-weight: bold;
}
.card_quiz_sub_title {
  font-size: 14px;
  color: #778190;
}
.quiz_dot_icon {
  color: #778190;
  cursor: pointer;
}
.card_courses_linked {
  color: #778190;
  font-size: 13px;
}
.play_game_icon {
  color: #778190;
  margin-right: 3px;
}

.quiz_card {
  padding: 13px;
  margin-top: 10px;

  border-radius: 6px;
  border: solid 2px #f1f3f6;
  background-color: #fff;
}
.badge_quiz {
  margin-left: 25px;
}

.dialog_action{
  padding: 20px 24px;
}