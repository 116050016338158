.close-btn{
    float: right;
    cursor: pointer;
    margin-left: 15px;
    margin-bottom: 35px;
    font-weight: 900;
}
textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: none;
  }
  .submit-btn{
    float: right;
  }
  .con-email-text{
margin-right: 30px;
margin-top: 8px;
  }
  
  
  .become-subtitle{
    font-size: 18px;
    text-align: center;
    justify-content: center;
  }

  .become-title{
    font-size: 23px;
    text-align: center;
    justify-content: center;
  }
  .err-msg{
    color: red;
  }