.sign-text-expe-v2 {
    font-family: 'Inter Tight Medium';
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-top: 18px;
    margin-bottom: 20px;
}

.login-btn-expe-v2 {
    background: #F9BA1A !important;
    color: #3B4357;
    border-radius: 38px;
    font-family: 'Inter Tight Medium';
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
}

.left-side-expe-v2 {   
    color: #fff;
}

.right-side-expe-v2 {
    color: #fff;
    height: 100%;
    width: 100%;
}

