/* .container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #03A9F4, #4CAF50);
} */
.tools-section {
  position: absolute;
  left: 3%;
  align-items: center;
  color: black!important;
  height: 100%;
  display: flex;
  z-index: 1;
}

.tools{
  background: #fff;
    justify-content: center;
    text-align: center;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-top: 10px;
    padding-bottom: 15px;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 16%);
}

.tools div{
  padding-top:15px;
}

.tools input{
  width: 24px;
}

.tools select{
  width: 40px;
}
.board-container{
  background-color: #fff;
}
/* .board-container{
  background-color: #fff;
    background-image: radial-gradient(#000 10%, transparent 5%), radial-gradient(#000 10%, transparent 3%);
    background-size: 5px 5px;
} */

/* .board-container {
    /* width: 90%;
    /* height: 90%;
    margin: auto;
    margin-top: 1%;
    background: white;
} */

div:hover > #hov{
    display : block;
    opacity: 0.4;
    cursor: auto;
  
  }
  div:hover > #myself{
    display : block;
    opacity: 0.4;
    cursor: auto;
  
  }
  #myself{
    display: none;
    margin: 10px;
    position:absolute;
    font-style: italic;
    font-size:10px;
    font-weight: bold;
    color: rgb(44, 12, 12);
    opacity: 0.4;
    cursor: auto;
  }
  #hov{
    margin: 10px;
    position:absolute;
    font-style: italic;
    font-size:10px;
    font-weight: bold;
    color: rgb(44, 12, 12);
    opacity: 0.4;
    cursor: auto;
  }
  
  .my-cursor{
    color: blue!important;
    width: 50px;
    height: 20px;
    border-radius: 30px;
    display: flex;
    background-color: #4CAF50;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .my-cursor-content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:3px;
  }
.swipe_img{
  width: 30px;
}
  .pen_img{
    width: 26px;
  }
  .rect_img{
    width: 23px;
  }
  .arrow_img{
    width: 30px;
  }

  .text_img{
    width: 16px;
  }