.experts__name_title {
  font-size: 18px;
  font-weight: bold;
}

.block_ex_de_btn {
  width: 132px;
  padding: 9px;
  color: white !important;
  border-radius: 27px;
  background-color: red !important;
}

.tab_btn {
  border: 1px solid #ccc;
  border-radius: 10px;
}

.block_expert_submit_btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 7px;
  width: 120px;
}
.expert_profile_box {
  background-color: #d3d3d336;
  padding: 20px;
  border-radius: 12px;
}
.expert_name_title_text {
  font-size: 12px;
  font-weight: normal;
  color: #778190;
}
.expert__submit_btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 7px;
  width: 120px;
}
