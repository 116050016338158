.my_schedule_section_1 {
  background-color: whitesmoke;
  max-width: 100%;
  padding: 15px;
}
.schedule_title_text {
  color: rgb(58 14 245 / 78%);
  font-size: 30px;
  font-weight: bolder;
}
.sample_img {
  width: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_schedule_title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.674);
}
.my_schedule_session {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.781);
}
.my_schedule_next_session {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.587);
  font-weight: bold;
}
.my_schedule_next_date {
  position: relative;
  padding: 5px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.587);
}
.my_schedule_btn_group {
  padding-left: 10px;
  margin-bottom: 15px;
}
.start_btn {
  color: white !important;
  background-color: #f07820 !important;
  border-radius: 30px;
  padding: 3px;
  width: 150px;
  height: 40px;
  border: none;
  margin-top: 20px;
  float: right;
}
.schedule_info_btn {
  border: none !important;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.587);
}
.Schedule_btns {
  color: #080808 !important;
  background-color: white !important;
  border-radius: 20px;
  border-color: #c0b9b9;

  width: 105px;
  margin-top: 20px;
  margin-right: 50px;
  font-size: 12px;
  padding: 7px;
}

.schedule_btns_active{
  border-color: #6c757d!important;
  border: 2px solid;
}

.my_schedule_button_div {
  margin-right: 10px;
}

.curriculum_btn:active,
.curriculum_btn:focus {
  border-color: #181615;
  border-width: thin;
}
.schedule_card {
  border: 2px solid;
  border-color: lightgray;
  border-radius: 8px;
padding: 10px;
  width: 750px;
}
.schedule_card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.my_schedule_grid_container {
  padding-top: 15px;
}
.my_schedule_linear {
  background-color: rgb(233 237 231);
}
.my_schedule_time_text{
  font-size: 13px;
  font-weight: 500;
  color: #778190;
}

.my_schedule_text_section_cap{
  text-transform: capitalize;
}