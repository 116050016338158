.close-icon {
  font-size: 25px;
  cursor: pointer;
}
.role-label {
  background: #3b4357;
  position: relative;
  width: 100%;
  color: white;
  padding: 8px;
  font-weight: bold;
  font-size: 20px;
}
.host-course-item-v2{
  padding: 5px;
  display: flex;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
}
.course_list_ul-v2{
  list-style: none;
}
.course_list_ul-v2 li:hover {
  /* background-color: #f1f3f6; */
  cursor: pointer;
}

.course_list_li_text-v2{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
}

.dropdown-check-list {
  display: inline-block;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list.visible .items {
  display: block;
}

.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.play-btn-span :hover{
  cursor: pointer;
  border: 3px solid #522bc1;
  border-radius: 50px;
}

.active-session-v2{
  border-left: 5px solid #ffffff;
  background-color: transparent;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px
}

.left-course-icons-v2{
  width: 18px;
}