#submit-button{
    color: white !important;
    background-color: orange;
    width: 18%;
    margin-top: 25px;
    border-radius: 15px;
    float: right;
}

.link_text{
    color: #7b7be0;
    cursor: pointer;
}
.link_text:hover{
    padding-bottom: 5px;
  transition-duration: 0.2s;
}

#tab-button{
    color: black;
    background-color: rgb(215, 193, 193);
    width: 18%;
    margin-bottom: 25px;
    border-radius: 15px;
}

.tab-btn-active{
    color: white !important;
    background-color: orange !important;
}

.whiteboard-flag{
    font-size: 12px;
    background: #33b16f;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    font-weight: 600;
}

.game-flag{
    font-size: 12px;
    background: #03285e;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    font-weight: 600;
}