.profile_name_title {
  font-size: 23px;
  font-weight: bold;
  margin-left: 10px;
}
.block_parent_btn {
  width: 142px;
  padding: 7px;
  color: white !important;
  border-radius: 27px;
  background-color: #ea0000 !important;
}
.tab_btn {
  border: 1px solid #ccc;
  border-radius: 10px;
}

.Mui-selected {
  background-color: #f1f3f6;
  /* color: black !important; */
}
.MuiTabs-indicator {
  background-color: #f1f3f6;
  transition: none;
}
.profile_box_section {
  padding: 10px;

  border-radius: 12px;
  background-color: #f1f3f6;
}
/* .profile_name_section {
  margin-right: 30px;
} */
.name_title_text {
  font-size: 12px;
  font-weight: normal;
  color: #778190;
}
.name_text {
  color: #3b4357;
  font-size: 16px;
}
.kids_title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 5px;
}
#kids_box {
  padding: 13px;
  margin-right: 3px;
  width: 200px;
  border-radius: 6px;
  border: solid 2px #f1f3f6;
  background-color: #fff;
}
.kids_course {
  color: #778190;
  font-size: 14px;
}
.kids_name {
  color: #3b4357;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  top: 6px;
}
.kids_text_section {
  margin-left: 15px;
}

.payment_table_header {
  background-color: #3b4357;
}
.payment_table_header_text {
  color: white !important;
}
/* .col_1{
  width: 80px;
}
.col_2{
  width: 100px;
}
.col_3{
  width: 20px;
}
.col_4{
  width: 250px;
} */

/* .horizontal-list {
  display: flex;
} */


.list-group-item{
  float: left;
  list-style-type: none;
}



.nav-link {
  border: none;
  outline: none;
 
  
  cursor: pointer;
}

/* Style the active class (and buttons on mouse-over) */
