.link_text{
    color: #7b7be0;
    cursor: pointer;
}
.link_text:hover{
    padding-bottom: 5px;
  transition-duration: 0.2s;
}

.dialog_content{
    width: 50%;
}

.stream-kids{
    background: #3737a3;
    color: #fff;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
}

.badge-kids-count{
    background: orange;
    padding: 2px 2px 2px 2px;
    border-radius: 50px;
}

.pod_detail_col{
    background: #5bc85b;
    padding: 3px 0px 3px 0px;
    border-radius: 4px;
    font-weight: 600;
    width: 150px;
    text-align: center;
    font-size: 12px;
}