.login-btn-container-det {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: -10px;
}

.login-btn-text-white {
  color: #fff;
}

.login-btn-text-blue {
  color: #591fff;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

  .mobile-screen-login-button-btn {
    display: block;
  }

  .large-screen-login-button-btn {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

  .mobile-screen-login-button-btn {
    display: block;
  }

  .large-screen-login-button-btn {
    display: none;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

  .mobile-screen-login-button-btn {
    display: block;
  }

  .large-screen-login-button-btn {
    display: none;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .mobile-screen-login-button-btn {
    display: none;
  }

  .large-screen-login-button-btn {
    display: block;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

  .mobile-screen-login-button-btn {
    display: none;
  }

  .large-screen-login-button-btn {
    display: block;
  }
}

.avatar-margin-large {
  cursor: pointer;
}

.dropdown-menu {
  cursor: pointer;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -100%;
  margin-top: var(--bs-dropdown-spacer);
}