html, body {
    width: 100% ;
    height: 100% ;
  }
  #sketch {
    /* border: 10px solid gray; */
    /* width: 3000px;
    height: 1000px; */
    height: 100vh;
    width: 100%;
    overflow: hidden;
    /* position: relative; */
  }
  /* .sketch {
 
    width: 3000px;
    height: 1000px;
    overflow: scroll;
} */
  #tmp_canvas {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    right: 0;
    bottom: 0;
    top: 0;
    /* cursor: crosshair; */
  }

  #paint{
    height: 100%;
    width: 100%;
  }