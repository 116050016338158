.block_req_name_title {
    font-size: 25px;
    font-weight: bold;
   
    color: blue;
  }
  .search_by_name_b_re {
    border: 1px solid grey;
    padding: 0px;
    height: 35px;
    margin-left: 5px;
  }
  .block_btn_b_r {
    width: 122px;
    padding: 9px;
    color: white !important;
    border-radius: 27px;
    background-color: #ea0000 !important;
  }
  .unblock_btn_b_r{
    width: 122px;
    padding: 9px;
    border: 2px solid red;
    border-radius: 27px;
    color: #ea0000 !important;
  }
  .tab_btn {
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .block_expert_submit_btn{
    background-color: #f07820 !important;
    color: white !important;
    border-radius: 20px; 
    padding: 7px;
    width: 120px;
 
  }