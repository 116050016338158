/** #region RESPONSIVE SCREENS */
.navbar-hv3 {
    background: unset !important;
}

.navbar-hv3-mobile {
    background: #FFFFFF !important;
}

.navbar-header-hv3 {
    /* position: absolute; */
    z-index: 2;
    /* top: 1%; */
}

.nav-link-text-hv3 {
    color: #FFFFFF !important;
    font-family: 'Inter Tight Medium';
}

.nav-link-text-mobile-hv3 {
    font-family: 'Inter Tight Medium';
}

.m-icon {
    position: absolute;
    top: 49%;
}

.userIcon-hv3 {
    width: 55%;
}

.toggler-icon-hv3 {
    border: none !important;
    box-shadow: none !important;
}

.get-started-btn-hv3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 50px;
    left: 120px;
    top: 1701px;
    background: #F9BA1A;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
}

.watch-video-btn-hv3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 50px;
    left: 120px;
    top: 1701px;
    /* background: transparent; */
    border: #F9BA1A 2px solid;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
}

.get-started-btn-hv3 span {
    font-family: 'Inter Tight Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 103%;
    text-align: center;
    color: #000000;
}

.watch-video-btn-hv3 span {
    font-family: 'Inter Tight Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 103%;
    text-align: center;
    color: #F9BA1A;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

    .header-section-hv3 {
        position: absolute;
        top: 2%;
        /* margin-left: 120px;
    margin-right: 120px */
    }

    .meeku-icon-hv3 {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 19px;
        /* left: 120px;
        top: 1701px; */
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
    }

    /** COMMON */
    .spacing {
        padding-left: 25px;
        padding-right: 25px;
    }

    .white-desc {

        font-family: 'Inter Tight Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #FDFDFD;

    }

    /**SECTION 1*/
    .desktop-section-1-container-hv3 {
        position: absolute;
        top: 27%;
        width: 450px;
        left: 120px;
        height: 163px;
    }

    .desktop-section-1-title {
        font-family: 'Inter Tight ExtraBold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 103%;
        color: #FDFDFD;
    }


    .mobile-section-1-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 33px;
        line-height: 103%;
        color: #FDFDFD;
    }


    .mobile-text-section-1-container {
        position: absolute;
        top: 13%;
        height: 163px;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .mobile-section-1-container {
        position: relative;
        margin-top: 15px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .get-started-btn-hv3-mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        left: 120px;
        top: 1701px;
        background: #F9BA1A;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 44px;
    }

    .watch-video-btn-hv3-mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        left: 120px;
        top: 1701px;
        /* background: transparent; */
        border: #F9BA1A 2px solid;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 44px;
    }

    /** SECTION - 2 */
    .section-2-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-left: 10px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
        padding-right: 10px;
    }

    /** SECTION - 4 */


    .m-icon {
        display: none;
    }

    .section3_star_img_hv3 {
        display: none;
    }

    .yellowLine-img-hv3 {
        display: none;
    }


    /** FOOTER */

    .footer-subscribe-section {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .subscribe-btn {
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: none;
    }

    .footer-value-text {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #2861C4;
    }

    .f-label {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #2861C4;
    }

    .f-value {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen {
        display: block;
    }

    .large-screen {
        display: none;
    }


}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

    /** COMMON */

    .header-section-hv3 {
        position: absolute;
        top: 2%;
        /* margin-left: 120px;
        margin-right: 120px */
    }

    .spacing {
        padding-left: 25px;
        padding-right: 25px;
    }

    .white-desc {
        font-family: 'Inter Tight Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #FDFDFD;

    }

    /**SECTION 1*/
    .desktop-section-1-container-hv3 {
        position: absolute;
        top: 27%;
        width: 450px;
        left: 75px;
        height: 163px;
    }

    .desktop-section-1-title {
        font-family: 'Inter Tight ExtraBold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 103%;
        color: #FDFDFD;
    }


    .mobile-section-1-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 103%;
        color: #FDFDFD;
    }


    .mobile-text-section-1-container {
        position: absolute;
        top: 23%;
        height: 163px;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .mobile-section-1-container {
        position: relative;
        margin-top: 15px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .get-started-btn-hv3-mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 195px;
        height: 50px;
        left: 120px;
        top: 1701px;
        background: #F9BA1A;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 44px;
        color: #000000;
        font-weight: 700;
    }

    .watch-video-btn-hv3-mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 195px;
        height: 50px;
        left: 120px;
        top: 1701px;
        /* background: transparent; */
        border: #F9BA1A 2px solid;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 44px;
        color: #F9BA1A !important;
        font-weight: 700;
    }

    /** SECTION - 2 */
    .section-2-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-left: 10px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
        padding-right: 10px;
    }

    /** SECTION - 4 */

    .m-icon {
        display: none;
    }

    .section3_star_img_hv3 {
        display: none;
    }

    .yellowLine-img-hv3 {
        display: none;
    }






    /** FOOTER */

    .footer-subscribe-section {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .subscribe-btn {
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: none;
    }

    .footer-value-text {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #2861C4;
    }

    .f-label {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #2861C4;
    }

    .f-value {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen {
        display: block;
    }

    .large-screen {
        display: none;
    }


}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .header-section-hv3 {
        position: absolute;
        top: 2%;
        /* margin-left: 120px;
        margin-right: 120px */
    }

    /** COMMON */
    .spacing {
        padding-left: 25px;
        padding-right: 25px;
    }

    .white-desc {
        font-family: 'Inter Tight Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #FDFDFD;

    }

    /**SECTION 1*/
    .desktop-section-1-container-hv3 {
        position: absolute;
        top: 27%;
        width: 450px;
        left: 75px;
        height: 163px;
    }

    .desktop-section-1-title {
        font-family: 'Inter Tight ExtraBold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 103%;
        color: #FDFDFD;
    }


    .mobile-section-1-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 103%;
        color: #FDFDFD;
    }


    .mobile-text-section-1-container {
        position: absolute;
        top: 13%;
        height: 163px;
        text-align: center;
        padding-left: 98px;
        padding-right: 98px;
    }

    .mobile-section-1-container {
        position: relative;
        margin-top: 15px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .get-started-btn-hv3-mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 195px;
        height: 50px;
        left: 120px;
        top: 1701px;
        background: #F9BA1A;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 44px;
    }

    .watch-video-btn-hv3-mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 195px;
        height: 50px;
        left: 120px;
        top: 1701px;
        /* background: transparent; */
        border: #F9BA1A 2px solid;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 44px;
    }

    /** SECTION - 2 */
    .section-2-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        padding-left: 10px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
        padding-right: 10px;
    }

    /** SECTION - 4 */


    .m-icon {
        display: none;
    }

    .section3_star_img_hv3 {
        display: none;
    }

    .yellowLine-img-hv3 {
        display: none;
    }

    /** FOOTER */

    .footer-subscribe-section {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .subscribe-btn {
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: none;
    }

    .footer-value-text {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #2861C4;
    }

    .f-label {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #2861C4;
    }

    .f-value {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen {
        display: block;
    }

    .large-screen {
        display: none;
    }


}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .header-section-hv3 {
        position: absolute;
        top: 2%;
        /* margin-left: 120px;
        margin-right: 120px */
    }

    /** COMMON */
    .spacing {
        padding-left: 75px;
        padding-right: 75px;
    }

    .background-baby-img {
        height: 100vh;
    }

    .white-desc {
        font-family: 'Inter Tight Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #FDFDFD;

    }

    /**SECTION 1*/
    .desktop-section-1-container-hv3 {
        position: absolute;
        top: 27%;
        width: 450px;
        left: 75px;
        height: 163px;
    }

    .desktop-section-1-title {
        font-family: 'Inter Tight ExtraBold';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 103%;
        color: #FDFDFD;
    }


    .mobile-section-1-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 110%;
        color: #FDFDFD;
    }


    .mobile-text-section-1-container {
        position: absolute;
        top: 22%;
        height: 163px;
        text-align: center;
        padding-left: 98px;
        padding-right: 98px;
    }

    .mobile-section-1-container {
        position: relative;
        margin-top: 15px;
        margin-left: 0px;
        margin-right: 0px;
    }



    /** SECTION - 2 */
    .section-2-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 33px;
        text-align: center;
        line-height: 110%;
        color: #2861C4;
    }

    /** SECTION - 4 */

    .m-icon {
        display: none;
    }

    .section3_star_img_hv3 {
        display: none;
    }

    .yellowLine-img-hv3 {
        display: block;
    }






    /** FOOTER */

    .footer-subscribe-section {
        width: 100%;
    }

    .subscribe-btn {
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: center;
    }

    .footer-value-text {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #2861C4;
        text-align: left;
    }

    .f-label {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #2861C4;
    }

    .f-value {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen {
        display: none;
    }

    .large-screen {
        display: block;
    }


}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .header-section-hv3 {
        position: absolute;
        top: 2%;
        /* margin-left: 120px;
        margin-right: 120px */
    }

    /** COMMON */
    .spacing {
        padding-left: 75px;
        padding-right: 75px;
    }

    .background-baby-img {
        height: unset !important;
    }



    /**SECTION 1*/
    .desktop-section-1-container-hv3 {
        position: absolute;
        top: 27%;
        width: 450px;
        left: 120px;
        height: 163px;
    }

    .desktop-section-1-title {
        font-family: 'Inter Tight ExtraBold';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 113%;
        color: #FDFDFD;
    }

    .white-desc {
        font-family: 'Inter Tight Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
        color: #FDFDFD;
        text-align: left;
    }

    /** SECTION - 2 */
    .section-2-title {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 121%;
        color: #2861C4;
        margin-left: 75px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
    }

    /** SECTION - 4 */

    .m-icon {
        display: none;
    }

    .section3_star_img_hv3 {
        display: block;
    }

    .yellowLine-img-hv3 {
        display: block;
    }






    /** FOOTER */

    .footer-subscribe-section {
        width: 70%;
    }

    .subscribe-btn {
        background: linear-gradient(180deg, #4D95F6 0%, #6555E1 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        width: 250px;
        float: left;
    }

    .footer-value-text {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #2861C4;
        text-align: left;
    }

    .f-label {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #2861C4;
    }

    .f-value {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #2861C4;
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-screen {
        display: none;
    }

    .large-screen {
        display: block;
    }


}

/** #endregion */

/** SECTION 1 */
.main-container-hv3 {
    height: 100%;
    width: 100%;
}

/* section-1-container */
.section-1-container-hv3 {
    position: relative;
    /* margin-top: 15px; */
    margin-left: 0px;
    margin-right: 0px;
}

.section-2-container-hv3 {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #E7FEFF;
    width: 100%;
    /* height: 100vh; */
}

.desktop-section-1-container-hv3-mobile {
    /* position: absolute;
    top: 2%; */
    padding: 20px;
}

.desktop-section-1-title-mobile {
    font-family: 'Inter Tight ExtraBold';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 103%;
    color: #FDFDFD;
}

.white-desc-mobile {
    font-family: 'Inter Tight Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FDFDFD;
}

.groupRatingIcon-hv3 {
    display: flex;
    justify-content: center;
    position: relative;
    top: -56px;
}

.groupRatingIcon-mobile-hv3 {
    display: flex;
    justify-content: center;
    position: relative;
    top: -40px;
}


.section-2-hv3 {
    position: relative;
    top: -50px;
    padding-left: 10px;
    padding-right: 10px;
}

.sections-title-com-hv3 {
    color: #591FFF !important;
    font-size: 40px !important;
    text-align: center;
    font-family: 'Inter Tight Medium';

    .strong {
        font-family: 'Inter Tight Medium';
    }
}

.sections-title-com-white-color-hv3 {
    color: #FFFFFF !important;
    font-size: 40px !important;
    text-align: center;

    .strong {
        font-family: 'Inter Tight Medium';
    }
}

.yellowLine-img-hv3 {
    margin-left: 240px;
    width: 21%;
}

.tackle-card-hv3 {
    padding: 30px 25px;
    background-color: #FFFFFF;
    border-radius: 18px;
    display: flex;
    align-items: center;
    box-shadow: 0px 30px 50px 0px #00000026;
    height: 135px;
    z-index: 1;
}

.tackle-card-text-hv3 {
    font-family: 'Inter Tight Bold';
    line-height: 110%;
    color: #818694;

}

.With-screens-text-hv3 {
    text-align: center;
    font-weight: 500;
    margin-left: 40px;
    margin-right: 40px;
    font-family: 'Inter Tight Medium';
}

.register-free-course-btn-hv3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background: #591FFF;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
}

.register-free-course-btn-hv3 span {
    font-family: 'Inter Tight Medium';
    color: white !important;
    font-weight: 600;
}

.multi-union-img-hv3 {
    position: absolute;
    top: 37%;
    right: 3%;
}

/* section-3-container */

.section-3-container-hv3 {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #FFEFC6;
    width: 100%;
    /* height: 100vh; */
}

.section-3-hv3 {
    padding-top: 35px;
}

.yellowLine-img-hv3-2 {
    margin-left: 10px;
    /* width: 11%; */
}

.meeku-world-card-hv3 {
    padding: 30px 25px;
    background-color: #FFFFFF;
    border-radius: 18px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.meeku-world-card-title-hv3 {
    font-family: 'Poppins Bold';
    font-size: 24px;
}

.meeku-world-card-text-hv3 {
    font-family: 'Poppins';
    font-size: 20px;
}



/* AutoRotateAndShowHideCards.css */

.image-container-hv3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: 50px; */
}

.cards-container {
    display: flex;
    flex-direction: column;
}

.card_meeku_world_v3 {
    display: none;
    transition: opacity 0.5s ease-in-out;
}

.card_meeku_world_v3.visible {
    display: block;
    opacity: 1;
}

.section3_star_img_hv3 {
    position: absolute;
    top: 15%;
    width: 13%;
    right: 99px;
}



.m-icon {
    display: block;
}

.interactive-card-hv3-mobile {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: transparent;
    border: 2px black solid;
}



.interactive-sec-text-hv3-mobile {
    font-size: 20px;
}




/* section-5-container */

.section-5-container-hv3 {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    /* background-color: #FFFFFF; */
    width: 100%;
    /* height: 100vh; */
}

.section-5-hv3 {
    padding-top: 35px;
}

.courses-offered-btn-hv3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background: #591FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
}

.courses-offered-btn-hv3 span {
    color: white;
    text-transform: uppercase;
    font-family: 'Inter Tight Medium';
}





.carousel-indicators-Parents-sec-4-hv3 {
    position: absolute;
    right: 0;
    bottom: -3px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}


.text-75-hv3 {
    color: #591fff;
    font-size: 60px;
    font-weight: bold;
}

.text-8-hv3 {
    color: #13b8ff;
    font-size: 60px;
    font-weight: bold;
}

.text-50-hv3 {
    color: #fdb600;
    font-size: 60px;
    font-weight: bold;
}

.subscribe-completion-text {
    font-size: 13px;
    color: #fff;
    padding-left: 20px;
}