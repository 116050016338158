.admin_pods_text {
    font-size: 25px;
    font-weight: bold;
  }
  .parent_assign_btn {
    background-color: #f07820 !important;
    color: white !important;
    border-radius: 20px;
    padding: 8px;
    width: 100px;
  }
  .pods_search_course_name {
    border: 1px solid grey;
    padding: 4px;
  }
  .parent_kids_select {
    background-color: white !important;
  }
  .admin_pods_badge{
      margin-left: 17px;
  }
  .pods_table_header {
    background-color: #3b4357;
  }
  .pods_table_header_text {
    color: white !important;
  }
  .invoice_col{
    color: blue;
  }