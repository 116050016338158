.my_courses_section_1 {
  background-color: whitesmoke;
  max-width: 100%;
  padding: 15px;
}
.courses_title_text {
  color: black;
  font-size: 30px;
  font-weight: bolder;
}
.sample_img {
  width: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my_courses_text_section {
  padding: 10px;
}
.my_courses_title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.781);
}
.my_courses_session {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.781);
}
.my_courses_next_session {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.587);
  font-weight: bold;
}
.my_courses_next_date {
  position: relative;
    bottom: 9px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.587);
}
.my_courses_btn_group {
  padding-left: 10px;
  margin-bottom: 15px;
}
.pods_btn {
  color: var(--btn-color-primary) !important;
  background-color: var(--btn-bg-color-primary) !important;
  border-radius: 15px;
  padding: 3px;
  width: 105px;
  font-weight: 700;
}
.course_info_btn {
  border: none !important;
  font-size: 12px;

  padding: 3px;
  color: rgba(0, 0, 0, 0.587);
}
.curriculum_btn {
  color: var(--btn-color-primary) !important;
  background-color: white !important;
  border-radius: 15px;
  padding: 3px;
  width: 105px;
  font-weight: 700;
}
.course_card {
  border: 2px solid;
  border-color: lightgray;
  border-radius: var(--card-border-radius);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  width: 750px;
}
.next_session_div{
  margin-top: 50px;
}
.badge_course{
  margin-left: 25px;
  
  margin-bottom: 8px;
}

.pending-sessions-container{
  width: 100%;
  background: #38388d;
  border-radius: 5px;
  margin-bottom: 2px;
  color: #fff;
  font-weight: 600;
  padding-left: 10px;
}
.pending-join-btn {
  color: white !important;
  background-color: #f07820 !important;
  border-radius: 30px;
  padding: 3px;
  width: 150px;
  height: 40px;
  border: none;
  margin-bottom: 2px;
  margin-top: 2px!important;
}
.search-input{
  width: 100%;
  padding-right: 8px;
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  .e_main_my_course_padding{
    padding-top: 0px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .e_main_my_course_padding{
    padding-top: 0px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .e_main_my_course_padding{
    padding-top: 0px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .e_main_my_course_padding{
    padding-top: 23px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .e_main_my_course_padding{
    padding-top: 23px;
  }
}
