.subscription-section-1 {
  padding-top: 100px;
  padding-bottom: 20px;
  /* background-color: #a7c3d1; */
  background-image: linear-gradient(180deg, #807db3, #a7c3d1);
}

.choose-a-plan-text {
  font-size: 32px;
  color: white;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  /* font-family: 'Inter Tight ExtraBold'; */
  /* font-family: cursive; */
}

.choose-a-plan-sub-text {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Inter Tight ExtraBold';
  color: #424c6a;
}

.subscription-section-2 {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}

.plan-option-text {
  font-size: 32px;
  color: #424c6a;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  /* font-family: 'Inter Tight ExtraBold'; */
  /* font-family: cursive; */
}

.plan-option-sub-text {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: green;
  font-family: 'Inter Tight ExtraBold';
}

.plan-option-des-text {
  text-align: center;
  font-family: 'Inter Tight Medium';
}



.subscription-title-session {
  background-color: #6c75f6;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  font-family: 'Inter Tight ExtraBold';
}

.subscription-title-div {
  position: relative;
  bottom: 20px;
}

.subscription-card {
  width: 245px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11), inset 0 -3px 0 0 rgba(0, 0, 0, 0.11);
}

.subscription-card-title-text {
  font-size: 20px;
  color: white;
  /* font-family: cursive; */
  /* font-family: 'Inter Tight ExtraBold'; */
}

.big {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #6c75f6;
}

.small {
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  color: #6c75f6;

}



.subscription-starting-at {
  text-align: center;
  font-size: 13px;
  font-family: 'Inter Tight Medium';
}

.subscription-month {
  text-align: center;
  font-family: 'Inter Tight Medium';
}

.subscription-btn-session {
  padding: 20px;
}

.start-a-free-tr-btn {
  background: #F9BA1A;
  border-radius: 8px;
  border: none;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Inter Tight Medium';
  color: #5c5656;
}

.card-session-subscription {
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow-x: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .card-session-subscription {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    overflow-x: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.navbar-subscription-header {
  background: #FFFFFF !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  position: fixed;
  width: 100%;
  z-index: 2000;
}

.select-payment-period {
  padding: 10px;
}

.select-payment-period-text {
  font-size: 13px;
  color: #282428;
}

.redio-option-value {
  color: #282428;
  font-size: 15px;
  font-weight: 700;
}

.redio-option-label {
  color: #282428;
  font-size: 12px;
}

.continue-to-pay-btn-subscrition {
  text-align: center;
  margin-top: 10px;
}

.US_month-text {
  font-size: 12px;
  font-weight: bold;
}

.big sup {
  position: relative;
  top: -1em;
  color: #6c75f6;
  font-size: 14px;
  left: -2px;
}

.start-a-free-tr-btn-div {
  text-align: center;
  margin-top: 10px;
}

.subscription-details-c-bottom-session {
  background-color: white;
  border-radius: 0px 0px 20px 20px;
  border: 2px solid #f0f4f5;
  padding-top: 20px;
  /* max-width: 256px; */
  width: 245px;
  position: relative;
  bottom: 15px
}

.subscription-page-active-note-div {
  background-color: #101010;
  color: white;
  position: absolute;
  top: 68px;
  width: 100%;
  text-align: center;
}



.subscription-con-details-row {
  text-align: left;
  padding: 13px 10px 10px;
  border-bottom: 3px solid #f0f4f5;
  color: #547c92;
  font-size: 15px;
  font-family: 'Inter Tight Medium';
}