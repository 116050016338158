.account_inf_section{
    padding: 10px;
}
.account_inf_text{
    font-size: 20px;
    font-weight: bold;
}
.remove_pic_text{
    text-decoration: none;
    font-size: 13px;
    color: grey;
    margin-top: 5px;
}
.account_holder_name{
    margin-top: 10px !important;
    font-size: 20px;
    font-weight: bold;
}
.personal_inf_text{
    font-size: 20px;
    font-weight: bold;
   
}
.my_profile_save_btn{
    background-color: #f07820 !important;
    color: white !important;
    border-radius: 20px; 
    padding: 10px;
    width: 100px;
    margin-right: 10px;
  }