/* .body{
    margin-left: 15%;
    margin-right: 15%;
} */

.container-terms-and-conditions {
  margin-top: 80px;
  padding-bottom: 15px;
}

.header-terms-and-conditions {
  text-align: center;
}

.content-terms-and-conditions {
  font-family: "Poppins Regular";
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}

.deletion-terms-and-conditions {
  font-family: "Poppins Regular";
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
}

.dash {
  list-style-type: "-     ";
  font-family: "Poppins Regular";
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.font-weight-bold-privacy {
  font-weight: 600;
}