.desktop-section-1-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 37px;
    font-weight: 600;
    /* line-height: 96.3px; */
    letter-spacing: 0.05em;
    text-align: left;
    color: white;
}



@media (max-width: 1600px) {
    .section-1-background-hv4 {
        position: relative;
        background-position: unset !important;
        padding-bottom: 100px;
    }
}


.section-1-background-hv4 {
    background-color: #c8fafc;
    position: relative;
    background-position: bottom;
    padding-bottom: 100px;
}

.section-1-container-hv4 {
    /* position: absolute; */
    /* margin-top: 15px; */
    margin-left: 0px;
    margin-right: 0px;
}

.home_panda_image {
    /* position: absolute;
    right: 10px; */
    width: 350px;
}

@media (max-width: 768px) {
    .home_panda_image {
        display: none;
    }

    /* .desktop-section-1-title-hv4 {
        font-size: 25px;
    } */
}

.section-2-container-hv4 {
    background-color: #c8fafc;
}

.desktop-section-2-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 35px;
    font-weight: 600;
    line-height: 52.87px;
    text-align: center;
    color: #4011C6;
    margin-top: 40px;
    margin-bottom: 30px;
}

.section-2-dec-hv4 {
    font-family: 'Poppins Regular';
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: black;

}

.desktop-section-3-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 35px;
    font-weight: 500;
    line-height: 52.87px;
    text-align: left;
    color: #4011C6;
    margin-top: 40px;
    margin-bottom: 30px;
}

.desktop-section-3-title-how-meeku-solve-hv4 {
    font-family: 'Pompiere-Regular';
    color: #4011C6;
    font-size: 35px;
    font-weight: 600;
    line-height: 52.87px;
    text-align: center;

}

.section-3-container-hv4 {
    background: #E4F5FF;
    margin-top: -55px;
}

.coding-adven-card-1,
.coding-adven-card-2,
.coding-adven-card-3,
.coding-adven-card-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 225px;
    border: none;
    box-shadow: 0px 2px 10px 0px #00000012;
}

.coding-adven-card-1 {
    border-radius: 30px 30px 0px 30px;
}

.coding-adven-card-2 {
    border-radius: 30px 30px 30px 0px;
}

.coding-adven-card-3 {
    border-radius: 30px 0px 30px 30px;
}

.coding-adven-card-4 {
    border-radius: 0px 30px 30px 30px;
}



.section-4-container-hv4 {
    background-color: white;
    margin-top: -55px;
}

.section-5-container-hv4 {
    background-color: #ffffff;
}

.card_learning_pathways_img {
    margin-top: 20px;
}

.section-6-container-hv4 {
    background: #f3f6fb;
    position: relative;
}

.how_to_adventure_back_img {
    position: absolute;
    bottom: 0;
}

.be-a-leader-card-hv4 {
    border-radius: 20px 20px 20px 100px;
    background: linear-gradient(0deg, rgba(56, 127, 219, 0.05) 0%, rgba(56, 125, 218, 0.12) 100%);
}

.sub-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 22px;
    font-weight: 500;
    line-height: 36.05px;
    text-align: left;
    color: #4011C6;
}

.dec-text-hv4 {
    font-family: 'Poppins Regular';
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: #000000;
}

.be_a_leader_div {
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-right: 10px;
}

.be-a-leader-card-hv4-2 {
    border-radius: 20px 100px 20px 20px;
    background: linear-gradient(0deg, rgba(56, 127, 219, 0.05) 0%, rgba(56, 125, 218, 0.12) 100%);
}

.be_a_leader_div_2 {
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 10px;
}

.section-7-container-hv4 {
    background: linear-gradient(90deg, #3766CF 0%, #399CE9 100%);
}

.desktop-section-7-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 35px;
    font-weight: 600;
    line-height: 52.87px;
    text-align: center;
    color: white;
    margin-top: 40px;
    margin-bottom: 30px;
}

.section-7-title {
    font-family: 'Pompiere-Regular';
    font-size: 35px;
    font-weight: 600;
    text-align: left;
    color: #FFFFFF;
}

.section-7-sub-title {
    font-family: 'Poppins Regular';
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
}

.section-8-container-hv4 {
    background-color: #EAEDE7;
}

.section-9-container-hv4 {
    background-color: #f3f6fb;
}

.section-9-card-hv4 {
    background: #2D2D74;
    border-radius: 15px;
    padding: 15px;
}

.ready-to-start-text-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: #F9C704;
}

.ready-to-start-dec-hv4 {
    font-family: 'Poppins Regular';
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: white;
}

.start-for-free-hv4 {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white !important;
    border-radius: 44px;
    background: #4011C6;
    padding-left: 25px;
    font-weight: 500;
    border: none;
    transition: background-color 300ms ease-out, transform 300ms ease-out;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}



.sign-up-btn-hv4 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 44px;
    color: #4011C6 !important;
    padding-left: 25px;
    font-weight: 500;
    border: none;
}

.btn-text-hv4 {
    line-height: 7px;
}

.section-10-background-hv4 {
    /* background-color: #f3f6fb; */
    background-color: #c9cb8d;
    padding-top: 120px;
}

.desktop-section-10-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 35px;
    font-weight: 500;
    line-height: 52.87px;
    text-align: center;
    color: #4011C6;
    margin-top: 50px;
    margin-bottom: 30px;
}

.card_meeku_blog {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 15px;
}

.card_meeku_blog_1 {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 10px;
}

.card-time-text-hv4 {
    font-family: 'Poppins Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 33px;
    color: #6D6D6D;
}

.card_blog_card_title {
    font-family: 'Poppins Regular';
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #000000;
}

.card_blog_card_dec {
    font-family: 'Poppins Regular';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #000000;
}

.coding_btn_hv4 {
    background: #4011C6;
    border-radius: 7px;
    color: white;
    border: none;
    font-family: 'Poppins Regular';
    font-size: 15px;
    font-weight: 500;
    padding: 5px 15px 5px 15px;
    cursor: text !important;
}


.section-11-background-hv4 {
    background-color: #F7F7F7;
}

.desktop-section-11-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 35px;
    font-weight: 500;
    line-height: 52.87px;
    text-align: center;
    color: #4011C6;
    margin-bottom: 30px;
    position: relative;
    bottom: 46px;
}

.card_our_success {
    border-radius: 15px;
    padding: 15px;
    background: #F5E6DB;
    position: relative;
    border: none;
}

.video_img_hv4 {
    position: relative;
    left: 320px;
    width: 28%;
    bottom: 30px;
}

.section-12-background-hv4 {
    background-color: #D4E3E3;
}

.desktop-section-12-title-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 35px;
    font-weight: 500;
    line-height: 52.87px;
    text-align: center;
    color: #4011C6;
    margin-bottom: 30px;
}

.Accordion-title-hv4 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: 'Poppins Regular';
    color: #000000;
}

.Accordion-Summar-Frequently-hv4 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Accordion-dec-hv4 {
    font-weight: 500;
    font-family: 'Poppins Regular';
    color: #1B1B1B;
}

.video-container-home-vhv4 {
    transform: rotate(-3deg);
    border: 8px solid white;
    border-radius: 15px;
    width: fit-content;
    background-color: white;
    box-shadow: 0px 0px 20px 0px #00000040;
}

.video-container-home-vhv4-2 {
    border: 8px solid white;
    border-radius: 15px;
    width: fit-content;
    background-color: white;
    box-shadow: 0px 0px 20px 0px #00000040;
}

.video-text-hv4 {
    font-family: 'Pompiere-Regular';
    font-size: 22px;
    font-weight: 400;
    line-height: 42.06px;
    text-align: center;
    color: #07701F;
    background-color: white;
}

@media only screen and (max-width: 768px) {
    .be-a-leader-card-hv4 {
        border-radius: 20px 20px 20px 20px;
    }

    .be-a-leader-card-hv4-2 {
        border-radius: 20px 20px 20px 20px;
    }

    .section-10-background-hv4 {
        padding-top: 180px;
    }
}

.accordion-pen-icon-hv4 {
    width: 20px;
}


.video-container-background-block-vhv4 {
    background-color: #000000;
}




/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {


    .section-1-mobile-view-hv4 {
        display: block;
    }

    .section-1-large-view-hv4 {
        display: none;
    }

    .mobile-section-1-title-hv4 {
        font-family: 'Pompiere-Regular';
        font-size: 20px;
        font-weight: 600;
        /* line-height: 96.3px; */
        letter-spacing: 0.05em;
        text-align: left;
        color: white;
    }

    .section-1-background-hv4-mobile {
        background-position: bottom;
        padding-top: 10px;
        padding-bottom: 30px;
        background-color: #c8fafc;
        position: relative;
    }


}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {


    .section-1-mobile-view-hv4 {
        display: block;
    }

    .section-1-large-view-hv4 {
        display: none;
    }

    .mobile-section-1-title-hv4 {
        font-family: 'Pompiere-Regular';
        font-size: 23px;
        font-weight: 600;
        /* line-height: 96.3px; */
        letter-spacing: 0.05em;
        text-align: left;
        color: white;
    }

    .section-1-background-hv4-mobile {
        background-position: bottom;
        padding-top: 20px;
        padding-bottom: 50px;
        background-color: #c8fafc;
        position: relative;
    }

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {


    .section-1-mobile-view-hv4 {
        display: none;
    }

    .section-1-large-view-hv4 {
        display: block;
    }

    .mobile-section-1-title-hv4 {
        font-family: 'Pompiere-Regular';
        font-size: 25px;
        font-weight: 600;
        /* line-height: 96.3px; */
        letter-spacing: 0.05em;
        text-align: left;
        color: white;
    }

    .section-1-background-hv4-mobile {
        background-position: bottom;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #c8fafc;
        position: relative;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

    .section-1-mobile-view-hv4 {
        display: none;
    }

    .section-1-large-view-hv4 {
        display: block;
    }

    .mobile-section-1-title-hv4 {
        font-family: 'Pompiere-Regular';
        font-size: 10px;
        font-weight: 600;
        /* line-height: 96.3px; */
        letter-spacing: 0.05em;
        text-align: left;
        color: white;
    }

    .section-1-background-hv4-mobile {
        background-position: bottom;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

    .section-1-mobile-view-hv4 {
        display: none;
    }

    .section-1-large-view-hv4 {
        display: block;
    }

    .mobile-section-1-title-hv4 {
        font-family: 'Pompiere-Regular';
        font-size: 10px;
        font-weight: 600;
        /* line-height: 96.3px; */
        letter-spacing: 0.05em;
        text-align: left;
        color: white;
    }

    .section-1-background-hv4-mobile {
        background-position: bottom;
    }
}