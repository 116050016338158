.host-img {
  max-width: 100%;
}

.host-label {
  background: #1a1a1a;
  position: relative;
  width: 100%;
  color: white;

  padding: 12px;
}

.item-label {
  margin-left: 10px;
}

.item-icon {
  font-size: 20px;
}

.hsot-content {
  padding-left: 10px;
  padding-right: 10px;
}

.host-image-container {
  /* max-height: 20vh; */
}

.selected-course-title {
  background: #fff;
  padding: 10px;
  font-weight: 500;
  margin-top: 3px;

}

.selected-course-view {
  margin-top: 10px;

}

body {
  background: #dee2e6;
}

/* .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
} */

.course-view-frame {
  height: 65vh;
  width: 100%;
}

.participant_list {
  padding-bottom: 8px;
}

.participant-item-card {
  background: #2e343a;
  border-radius: 10px;
  width: 220px;
  padding: 5px;
  margin-right: 5px;
}

.participant-item-card-footer {
  background: #0f1010;
  color: #fff;
  padding: 3px;
  display: flex;
}

.participant-item-card-footer-icon {
  margin: 3px;
  font-size: 18px;
  color: #dc3545;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #82878d;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #ffff;
}

.participant-item-card-body {
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  padding: 5px;
  height: 170px;
}

.participant-item-card-body-display-hide {
  display: none;
}

.participant-view {
  height: auto;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.leaners-host-image-container {
  /* max-height: 20vh; */
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 10px;
}

.class_room_text_section {
  background-color: #d6dbde;
  min-height: 100vh;
}

.class_room_session_1 {
  min-height: 100vh;
}

.learner_share_view_canvas {
  width: 100%;
  height: 100%;
  transform: none !important;
}

/* CHAT */
.chat-my-msg-container {
  width: 80%;
  float: right;
  background-color: #ffeb93;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-name-container {
  text-align: right;
}

.my-name {
  font-size: 13px;
  font-weight: 600;
  color: #3b4357;
}

.my-msg-container {
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #3b4357;
}

.chat-rvr-msg-container {
  width: 80%;
  float: left;
  background-color: #dfe4eb;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rvr-name-container {
  text-align: left;
}

.rvr-name {
  font-size: 13px;
  font-weight: 600;
  color: #3b4357;
}

.rvr-msg-container {
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #3b4357;
}

.send-input-box {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.send-input {
  font-size: 12px;
}

#self-view-video,
#self-view-canvas {
  width: 100%;
  height: auto;
  display: none;
}

.sub-session-participants {
  margin-top: 15px;
  margin-left: 25px;
}

.host-rounded-video-off {
  height: 20vh;
  padding: 10px;
  /* background: #fff;
  padding: 10px;
  border-radius: 30px */
}

.host-rounded-video-off-div {
  justify-content: center;
  align-content: center;
  display: flex;
  top: 40%;
  position: relative;
}

.host-video-off-initial {
  background: #fff;
  padding: 10px;
  border-radius: 30px;
}

.participant-item-card-no-video {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  padding: 5px;
  height: 160px;
}

.participants-initials-rounded-view {
  background: #fff;
  padding: 10px;
  border-radius: 30px
}

/* canvas{
  transform: scaleX(-1)!important;
} */

/* video{
  transform: scaleX(-1)!important;
} */