.courses-description-div-v4 {
    background-color: #305368;
    background-image: url('../../../../assets/img/courses_img_v4/game_background_img_1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding: 30px;

}


.login-as-kis-v4 {
    display: flex;
    align-items: center;
    color: black !important;
    border-radius: 44px;
    background: white;
    font-weight: 600;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px
}

.courses-description-title-v4 {
    font-family: 'Pompiere-Regular';
    font-size: 25px;
    font-weight: 400;
    line-height: 51.64px;
    letter-spacing: 0.08em;
    text-align: left;
    color: #FFFFFF;
    text-transform: uppercase;
}

.cour-description-text-v4 {
    font-family: 'Poppins Regular';
    font-size: 20px;
    font-weight: 600;
    line-height: 35.41px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #F90404;
}

.cour-des-text-v4 {
    font-family: 'Poppins Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 29.51px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #D3D3D3;
}

.cour-features-text-v4 {
    font-family: 'Poppins Regular';
    font-size: 20px;
    font-weight: 600;
    line-height: 35.41px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #F90404;
}

.cour-features-des-v4 {
    font-family: 'Poppins Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 29.51px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #D3D3D3;
}

.courses-video-container-v4 {
    /* border: 5px solid #E89F26;
    border-radius: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px; */

    background-image: url('../../../../assets/img/courses_img_v4/video_background_img_2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* min-height: 100vh; */
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    border-radius: 15px;
}

.video-container-div-cv4 {
    background-color: black;
    border-radius: 4px;
    padding-top: 4px;
}

.back-btn-des-cgv4 {
    float: right;
    margin-right: 20px;
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
    .courses-video-container-v4 {
        padding-top: 50px;
        padding-bottom: 140px;
        min-height: unset;
    }

    .back-btn-des-cgv4 {
        margin-top: 85px;
    }
}



/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .courses-video-container-v4 {
        padding-top: 60px;
        padding-bottom: 150px;
        min-height: 100vh;
    }

    .back-btn-des-cgv4 {
        margin-top: 80px;
    }
}




/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .courses-video-container-v4 {
        padding-top: 70px;
        padding-bottom: 170px;
        min-height: unset;
    }

    .back-btn-des-cgv4 {
        margin-top: 100px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .back-btn-des-cgv4 {
        margin-top: 100px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1180px) {
    .courses-video-container-v4 {
        padding-top: 70px;
        padding-bottom: 165px;
        min-height: unset;
    }

    .back-btn-des-cgv4 {
        margin-top: 100px;
    }
}

@media only screen and (min-width : 1200px) {

    .courses-video-container-v4 {
        padding-top: 48px;
        padding-bottom: 165px;
        min-height: unset;
    }

    .back-btn-des-cgv4 {
        margin-top: 90px;
    }
}