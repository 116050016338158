.parent_payment_text {
  font-family: 'Inter Tight Medium';
  font-size: 24px;
  color: #3B4357;
  font-weight: 700;
}
.parent_assign_btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 8px;
  width: 100px;
}
.parent_search_course_name {
  border: 1px solid grey;
  padding: 4px;
  width: 30%;
  border-radius: 8px;
}
.parent_search_input{
  width: 100%;
}
.parent_kids_select {
  background-color: white !important;
}
.parent_badge_courses{
    margin-left: 17px;
}
.parent_courses_table_header {
  background-color: #3b4357;
}
.parent_courses_table_header_text {
  color: white !important;
}
.parent_courses_done_btn{
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px; 
  padding: 7px;
  width: 120px;

}
.assign_close_icon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
} .assign_kids_close_icon {
 
  right: 5px;
  font-size: 12px;
  cursor: pointer;
}

.parent-no-kids-assigned{
  cursor: pointer;
}

.add-kid-selected-coursename{
  font-size: 15px;
  font-weight: 600;
}