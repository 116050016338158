


.l-pods-container {
  background-color: whitesmoke;
  max-width: 100%;
  padding: 15px;
}

.l-pods-card{
  background-color: whitesmoke;
  padding: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: var(--card-border-radius);
}

.l-pods-completed-sessions-label{
  margin-top: 20px;
}

.l-pods-streams-container{
  display: flex;
}

.l-pods-streams-boxes-width{
  width: 160px !important;
}

.l-pods-streams-boxes{
  cursor: pointer !important;
  width: 77px;
  height: 98px;
  margin-right: 10px;
  border: 2px solid rgb(201, 140, 140);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.l-pods-streams-boxes-completed{
  background-color: rgb(240 228 228);
  cursor: not-allowed !important;
}

.l-pods-streams-boxes-not-completed{
  border: 2px solid rgb(3, 150, 3) !important;
}

.l-pods-start-date{
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.l-pods-chat{
  color: green;
}


.l-pods-chat-main{
  position: relative;
  height: 100%;
}

.l-pods-chat-header{
  padding: 10px 15px 10px 15px;
  color: #fff;
}

.l-pods-chat-body{
  height: calc(100% - 223px);
}
.l-pods-chat-footer{
  position: absolute;
  width: 100%;
  bottom: 2px;
}

.l-pods-coursename{
  text-transform: capitalize;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  .l-pods-main{
    position: relative;
    height: 100%;
    padding: 0px 14px 0px 14px;
    margin-top: 100px;
  } 
  .l-pods-chat-container{
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 20%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .l-pods-main{
    position: relative;
    height: 100%;
    padding: 0px 40px 0px 40px;
    margin-top: 100px;
  }
  .l-pods-chat-container{
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 40%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .l-pods-main{
    position: relative;
    height: 100%;
    padding: 0px 80px 0px 80px;
    margin-top: 100px;
  }
  .l-pods-chat-container{
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 40%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .l-pods-main{
    position: relative;
    height: 100%;
    padding: 0px 124px 0px 124px;
    margin-top: 100px;
  }
  .l-pods-chat-container{
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 60%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
 
  .l-pods-main{
    position: relative;
    height: 100%;
    padding: 0px 124px 0px 124px;
    margin-top: 100px;
  }

  .l-pods-chat-container{
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: calc(100% - 70%);
    height: 85%;
    border-radius: 20px;
    background-color: #000000;
    opacity: 75%;
    z-index: 1100;
  }
  
}