.e-participants-initials-rounded-view-image-in {
  background-color: #0f1010;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 27px;
  border: 2px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.host-video-off-initial-image-in {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  border: 2px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.e-host-rounded-video-off-div-image-in {
  justify-content: center;
  align-content: center;
  display: flex;
  top: 15%;
  position: relative;
}

.host-img {
  max-width: 100%;
}

.e-host-rouded-video-off-main {
  height: calc(100% - 49px);
}

/* .host-course-list{
    height: 100vh;
    overflow: auto;
    position: relative;
    top: 220px;
  } */

.e-host-label {
  background: #1a1a1a;
  position: relative;
  width: 100%;
  color: white;
  padding: 12px;
  position: absolute;
  bottom: 0px;
}

.item-label {
  margin-left: 10px;
}

.item-icon {
  font-size: 20px;
}

.hsot-content {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
}

/* .host-image-container {
    max-height: 20vh;
  } */

.selected-course-title {
  background: #fff;
  padding: 10px;
  font-weight: 500;
  margin-top: 3px;

}

.e-selected-course-view-75 {
  /* margin-top: 10px; */
  height: 100%;
  padding-bottom: 10px;
}

.e-selected-course-view-100 {
  height: 100%;
}

body {
  background: #dee2e6;
}

/* .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
      --bs-gutter-x: 0;
      --bs-gutter-y: 0;
      width: 100%;
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
      margin-right: auto;
      margin-left: auto;
  } */

.course-view-frame {
  height: 65vh;
  width: 100%;
}

.participant_list_expert {
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  height: 25%;
}

.e-participant-item-card {
  background: #2e343a;
  border-radius: 10px;
  /* width: 220px; */
  width: 20%;
  padding: 5px;
  position: relative;
  height: 100%;
}

.hide-participant-card {
  background: #fff !important;
  color: #fff !important;
}

.participant-item-card-footer {
  background: #0f1010;
  color: #fff;
  padding: 3px;
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 14px;
  width: 100%;
}

.e-participant-item-card-border {
  margin-right: 5px;
  /* border-right: 5px solid #fff;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px; */
}

.participant-item-card-gallery {
  background: #2e343a;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 5px;
  position: relative;
}

.join-in-middle-btn-loading {
  padding: 2px 10px 2px 10px;
  cursor: no-drop;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: 10px;
  background: #a2aca2;
  cursor: pointer;
  color: #2e343a;
}

.join-in-middle-btn {
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: 10px;
  background: #44d644;
  cursor: pointer;
  color: #2e343a;
}

.join-in-middle-btn:hover {
  box-shadow: inset 0 0 10px #000000;
}

.participant-item-card-footer-gallery {
  background: rgb(15, 16, 16);
  color: rgb(255, 255, 255);
  padding: 3px;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 100%;
  left: 0;
}

.participant-item-card-footer-icon {
  margin: 3px;
  font-size: 18px;
  color: #dc3545;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #82878d;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #ffff;
}

.e-participant-item-card-body {
  /* display: block; */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  padding: 5px;
  height: 100%;
}

.e-particiipants-video-enabled-text {
  color: #fff;
  font-weight: 600;
}

.participant-item-card-body-gallery {
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  padding: 5px;
  height: 100%;
  position: relative;
}

.e-participant-item-card-body-audio-enable {
  border: 1px solid #11b511 !important;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.participant-item-card-body-display-hide {
  display: none;
}

.e-participant-item-card-no-video {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  padding: 5px;
  height: 100%;
}

.participant-item-card-no-video-gallery {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  padding: 5px;
  height: 100%;
  position: relative;
}

.e-participant-item-card-audio-border-enable {
  border: 1px solid #11b511;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.participant-item-card-audio-border-disable {
  border: none;
}

.e-participants-initials-rounded-view {
  background: #fff;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 30px
}

.e-participant-view {
  border-radius: 10px;
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 2;
  object-fit: cover;
}

.e-host-rounded-video-off {
  height: 100%;
  padding: 10px;
}

.e-host-rounded-video-off-div {
  justify-content: center;
  align-content: center;
  display: flex;
  top: 40%;
  position: relative;
}

.e-host-image-container {
  display: block;
  background: #41464b;
  height: 100%;
  position: relative;
}

.host-image-container-display-hide {
  display: none;
}

.e_class_room_text_section {
  background-color: #d6dbde;
  /* min-height: 100vh; */
}

.e-class_room_session_1 {
  min-height: 100vh;
  background-color: #f7f8f8;
  height: 100%;
}

.breakout-room-button {
  background: #5d5dc1;
  color: white;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 20px;
  cursor: pointer;
}

.room-title {
  font-weight: 600;
}

.rooms {
  padding: 12px 12px 12px 12px;
  background: #e5e5f8;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 4px;
  cursor: pointer;
}

.group-list-container-hover:hover {
  background: #dadbdd;
}

.group-list-container {
  padding: 10px 20px 10px 15px;
  border-bottom: 1px solid #dadbdd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-group-badge-notification {
  background: #f07820;
  display: flex;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 2px 10px 2px 10px;
}

.group-name {
  color: #3b4357;
  font-weight: 600;
}

.group-people-img {
  height: 18px;
  width: 18px;
}

.group-member-desc {
  display: flex;
  color: #3b4357;
  align-items: center;
  font-weight: 500;
}

.selected-group-main {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.selected-group-container {
  display: flex;
  align-items: center;
  justify-content: left !important;
}


/* CHAT */
.expert-msg-list-v1 {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  height: calc(100% - 365px);
  position: absolute;
  width: 100%;
  top: 220px;
  overflow: auto;
}

.expert-msg-list-v1-inside-b-room {
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - 505px);
  position: absolute;
  width: 100%;
  top: 98px;
  overflow: auto;
}

.chat-my-msg-container {
  width: 80%;
  float: right;
  background-color: #ffeb93;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-name-container {
  text-align: right;
}

.my-name {
  font-size: 13px;
  font-weight: 600;
  color: #3b4357;
}

.my-msg-container {
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #3b4357;
}

.chat-rvr-msg-container {
  width: 80%;
  float: left;
  background-color: #dfe4eb;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rvr-name-container {
  text-align: left;
}

.rvr-name {
  font-size: 13px;
  font-weight: 600;
  color: #3b4357;
}

.rvr-msg-container {
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #3b4357;
}

.send-input-box {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}

.send-screenshot {
  cursor: pointer;
}

.send-input {
  font-size: 12px;
  margin-right: 5px;
}

.white-boards-tab {
  overflow: auto;
}

/* .white-boards-tab {
    padding: 20px;
  
    margin-top: 20px;
    overflow-y: scroll;
    max-height: 88vh;
} */

.breakout-progress-navbar {
  background: rgb(250, 236, 34);
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  border: 5px;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #522bc1;
  height: 25px;
}

.group-title {
  cursor: pointer;
  font-weight: 600;
  height: 38px;
  opacity: 0.75;
  background-color: #000;
  width: 170px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  left: 15px;
  top: 15px;
  position: absolute;
  z-index: 3;
}

.group-actions-main {
  position: absolute;
  right: 15px;
  top: 1px;
  display: block;
  z-index: 99999;
}

.group-actions {
  margin-top: 6px;
  cursor: pointer;
}

.group-actions:hover {
  border: 2px solid #522bc1;
  border-radius: 50px;
}

.break-out-room-users {
  position: absolute;
  bottom: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.b-users {
  background-color: #656565;
  width: 45px;
  height: 45px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.b-users-active {
  border: 3px solid #2065D1;
}

.opened_group_floating_lable {
  position: absolute;
  top: 0px;
  left: 50%;
  z-index: 2000;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #23237c;
  padding: 6px 25px 6px 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.game-grid {
  height: 300px;
  cursor: pointer;
}

.e-game-grid-breakout {
  height: 100%;
  cursor: pointer;
}

.game-title {
  font-weight: 600;
  height: 38px;
  opacity: 0.75;
  background-color: #000;
  width: 170px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  left: 15px;
  top: 15px;
  position: absolute;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important
}

#e-self-view-video,
#e-self-view-canvas {
  width: 100%;
  height: 170px;
  display: none;
}

.sub-session-participants {
  margin-top: 15px;
  margin-left: 25px;
}

.max_game {
  height: 84vh;
}

.loading-bg {
  background-color: #babad3 !important;
}

canvas {
  transform: scaleX(-1) !important;
}

/* video{
  transform: scaleX(-1)!important;
} */
/* #wrap {
  width: 750px;
  height: 1500px;
  padding: 0;
  overflow: hidden;
} */
#wrap {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
}

/* .scaled-frame {
  width: 797px;
  height: 635px;
  border: 0px;
} */

#scaled-frame {
  width: 797px;
  height: 635px;
  border: 0px;
}

#scaled-frame {
  zoom: 0.55;
  -moz-transform: scale(0.55);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.55);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.55);
  -webkit-transform-origin: 0 0;
}

.whiteboard-locked-morph-div-expert-b-view {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.leave-grp-btn {
  background: #5d5dc1;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  left: 23px
}

.expert-bird-eye-view-container {
  width: 50%;
  height: calc(14vw * 1.72);
  padding-bottom: 20px;
}

.group-item-expert-bird-view {
  cursor: pointer;
  margin-bottom: 0px;
  height: 100%;
}

.group-item-expert-bird-view-active {
  cursor: pointer;
  margin-bottom: 0px;
  border: 2px solid black;
  height: 100%;
}

.scaled-frame {
  width: 100%;
  height: 100%;
}

/* .scaled-frame {
  width: 649px;
  height: 376px;
  zoom: 0.55;
  -moz-transform: scale(0.34);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.34);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.34);
  -webkit-transform-origin: 0 0;
}

@media screen and (-webkit-min-device-pixel-ratio:1) {
  .scaled-frame {
    zoom: 2;
  }
} */

.expert-video-inside-b-room-container {
  position: absolute;
  bottom: 28px;
  right: 38px;
}

.gallery-view-hide {
  display: none !important;
}

.gallery-height {
  height: calc(100% - 50%) !important;
}

.quiz-time-center-text {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 44%;
  justify-content: center;
  display: flex;
}

.quiz-time-center-text span {
  background: rgb(2 167 2);
  padding: 7px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.quiz-question-pop {
  font-size: 14px;
  font-weight: 600;
}

.quiz-answer-pop {
  font-size: 14px;
  padding-left: 10px;
}

.quiz-correct-answer-pop {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}

.quiz-status-flag-completed {
  font-size: 12px;
  background: green;
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  font-weight: 600;
}

.quiz-status-flag-inprogress {
  font-size: 12px;
  background: #1414b1;
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  font-weight: 600;
}

.quiz-learner-answer-correct {
  color: green;
}

.quiz-learner-answer-wrong {
  color: red;
}

.select_game_close_btn {
  cursor: pointer;
}

.refresh-quiz {
  cursor: pointer;
}

.e-session-container {
  display: flex;
  position: relative;
}

.e-session-left-panel {
  height: 100%;
  position: fixed;
  width: 280px;
}

.e-session-left-panel-hide {
  display: none;
}

.e-session-left-panel-show {
  display: block;
}

.e-session-right-panel {
  position: absolute;
  right: 0;
  width: calc(100% - 281px);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: auto;
}

.e-session-right-panel-100 {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: auto;
}

.e-session-host-container {
  height: 220px;
  position: fixed;
  width: 280px;
  z-index: 2;
}

.e-session-chat-text-box {
  bottom: 0;
  position: fixed;
  background: #fff;
}

#participant-videos-canvas {
  width: 100%;
  height: auto;
}

.expert-self-video-enabled-text {
  position: absolute;
  text-align: center;
  align-items: center;
  top: 32%;
  left: 30%;
  color: #fff;
  font-weight: 600;
}

/* #participant-videos-canvas-horizontal-view{
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 1;
   border: 1px solid #000;
} */

#participant-videos-canvas-horizontal-view-card {
  width: 100%;
  height: auto;
  position: absolute;
  transform: scaleX(1) !important;
  z-index: 1;
  object-fit: cover;
}

.e-partipants-pagination-bg {
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  /* margin-top: 64px; */
  position: absolute;
  z-index: 3;
}

.e-partipants-pagination-right {
  right: 0;
}

/* .e-partipants-pagination-left{
  right: 0;
} */

.back-to-bird-eye-view {
  position: absolute;
  z-index: 1;
  top: 18px;
  right: 30px;
}