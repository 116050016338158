.navbar-header-hv4 {
    background-color: white;
}

.login-btn-hv4 {
    padding: 8px 24px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    border: 1px solid #4011C6;
    border-radius: 25px;
    color: #4011C6;
    background-color: white !important;

}

.start-free-btn-hv4 {
    padding: 8px 24px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    border: 1px solid #4011C6;
    border-radius: 25px;
    color: white;
    background-color: #4011C6;
}

.nav-link-text-hv4 {
    color: #4011C6 !important;
    font-family: 'Inter Tight Medium';
}