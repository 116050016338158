.left-column-pr-v2 {
  background-color: #591FFF;
  position: relative;
  /* height: 100vh; */
}

.right-column-pr-v2 {
  background-color: #EAE5F5;
  /* height: 100vh; */
}


.meeku-icon-prv2-div {
  width: 85px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 19px;
  /* left: 120px;
  top: 1701px; */
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 44px;
}

.Coding-With-Title-prv2 {
  font-family: 'Inter Tight Medium';
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
}

.Coding-With-card-prv2 {
  background: #591FD6;
  border-radius: 9px;
  padding: 10px;

}

.Coding-With-card-le-text-prv2 {
  font-family: 'Inter Tight Medium';
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
}

.Coding-With-card-ri-text-prv2 {
  font-family: 'Inter Tight Medium';
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
}

.m-icon-prv2 {
  position: absolute;
  top: 222px;
  width: 70px;
}

.yellow_star_img_prv2 {
  width: 150px;
}

.userIcon-prv2 {
  width: 62px;
}

.form-hi-text-pr-v2 {
  font-family: 'Inter Tight Medium';
  font-size: 28px;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;
}

.Let-us-know-text-pr-v2 {
  font-family: 'Inter Tight Medium';
  color: #818694;
  font-size: 18px;
  font-weight: 500;
}

.free-course-reg-card-prv2 {
  background: #FFFFFF;
  padding: 0px;
  border-radius: 9px;
  z-index: 1;
}

.free-course-reg-pr-v2 {
  font-family: 'Inter Tight Medium';
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3em;
  text-align: center;
}

.reg-card-prv2 {
  background: linear-gradient(89.98deg, #FF5866 15.58%, #4316C4 94.7%);

}

.reg-card-prv2-1 {
  padding: 10px 17px;
  position: relative;
}

.edit-course-in-registration {
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 0px;
}

.course-schedule-pr-v2 {
  font-family: 'Inter Tight Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.course-schedule-date-pr-v2 {
  font-family: 'Inter Tight Medium';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.parent-reg-card-prv2 {
  background: #FFFFFF;
  border-radius: 9px;
  padding: 15px 28px;
  z-index: 1;
}

.parent-form-prv2-input {
  font-family: 'Inter Tight Medium';
  height: 40px !important;
  border-radius: 10px;
  /* border: none; */
  width: 100%;
  padding-left: 20px;
  color: #2861C4;
  font-size: 14px;
}

.section3_star_img_prv2 {
  position: absolute;
  top: 220px;
  width: 100px;
}

.interactive_section_mm_img_prv2 {
  width: 50%;
}

.registration-complete-text-prv2 {
  font-family: 'Inter Tight Medium';
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #591FFF;
}

.Submit-btn-prv2 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: #F9BA1A;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 44px;
  border: none;
}

.Submit-btn-prv2 span {
  color: #000000 !important;
  font-weight: 700;
}

.complete-qu-text-prv2 {
  font-family: 'Inter Tight Medium';
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.complete-an-text-prv2 {
  font-family: 'Inter Tight Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4357;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

  .left-column-pr-v2-section {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
    padding-bottom: 20px
  }

  .right-column-pr-v2-section {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    padding-bottom: 20px
  }

  .m-icon-prv2 {
    display: none;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .left-column-pr-v2-section {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 70px;
    padding-bottom: 20px
  }

  .right-column-pr-v2-section {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 25px;
    padding-bottom: 20px
  }

  .m-icon-prv2 {
    display: block;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

  .left-column-pr-v2-section {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 70px;
    padding-bottom: 20px
  }

  .right-column-pr-v2-section {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 25px;
    padding-bottom: 20px
  }

  .m-icon-prv2 {
    display: block;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

  .left-column-pr-v2-section {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 70px;
    padding-bottom: 20px
  }

  .right-column-pr-v2-section {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 25px;
    padding-bottom: 20px
  }

  .m-icon-prv2 {
    display: block;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .left-column-pr-v2-section {
    padding-left: 125px;
    padding-right: 125px;
    padding-top: 70px;
    padding-bottom: 20px
  }

  .right-column-pr-v2-section {
    padding-left: 125px;
    padding-right: 125px;
    padding-top: 25px;
    padding-bottom: 20px
  }

  .m-icon-prv2 {
    display: block;
  }
}

.parent-reg-mobile-number {
  border-radius: 8px;
}

.requiredField {
  color: #FF4842;
}

.dont-type-real-name {
  color: #6c75f6;
}

.login_cat_img {
  width: 150px;
  position: absolute;
  bottom: 0;
}

.small-note-text {
  color: #4316C4;
}