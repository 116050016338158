.close-icon {
    font-size: 25px;
    cursor: pointer;
  }
  .role-label {
    background: #3b4357;
    position: relative;
    width: 100%;
    color: white;
    padding: 8px;
    font-weight: bold;
    font-size: 20px;
  }
.host-course-item {
    padding: 5px;
    display: flex;
    color: #6c757d;
}
.notes-input{
    width: 100%;
}
.notes-textarea{
    border: none;
    background: #f8f9fa;
    width: 100%;
    /* width: 77vh; */
}
.notes-action {
    height: 40px;
    /* background: red; */
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #adb5bd;
}
.notes-color{
    display: flex;
}
.notes-red{
    height: 20px;
    width: 20px;
    background: #f15c5c;
    border-radius: 50%;
    margin-right: 6px;
}
.notes-blue{
    height: 20px;
    width: 20px;
    background: #0dcaf0;
    border-radius: 50%;
    margin-right: 6px;
}
.notes-yellow{
    height: 20px;
    width: 20px;
    background: #ffc107;
    border-radius: 50%;
    margin-right: 6px;
}
.notes-violet{
    height: 20px;
    width: 20px;
    background: #9b79fb;
    border-radius: 50%;
    margin-right: 6px;
}
.add-note-v2{
    font-weight: 700;
    cursor: pointer;
    color: #fff;
}
.note-item {
    color: white;
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
}
.removeNotes{
    float: right;
}
.required_alert_text{
    color: red;
    padding-left: 20px;
    font-weight: 500;
    font-size: 13px;
    text-align: end;
    padding-right: 20px;
}
/* .notes-area {
    height: 40vh;
    overflow-y: scroll;
}
@media only screen and (max-width: 1700px) {
    .notes-area {
        height: 46vh;
        overflow-y: scroll;
    }
  } */

  .host-course-list-v2{
      padding: 10px 10px 10px 10px
  }