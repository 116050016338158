/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

    .interactive_m_icon {
        display: none;
    }

    .mobile-screen-integrated-section {
        display: block;
    }

    .large-screen-integrated-section {
        display: none;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .interactive_m_icon {
        display: none;
    }

    .mobile-screen-integrated-section {
        display: block;
    }

    .large-screen-integrated-section {
        display: none;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    .interactive_m_icon {
        display: none;
    }

    .mobile-screen-integrated-section {
        display: none;
    }

    .large-screen-integrated-section {
        display: block;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

    .interactive_m_icon {
        display: block;
    }

    .mobile-screen-integrated-section {
        display: none;
    }

    .large-screen-integrated-section {
        display: block;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

    .interactive_m_icon {
        display: block;
    }

    .mobile-screen-integrated-section {
        display: none;
    }

    .large-screen-integrated-section {
        display: block;
    }
}


.integrated-container {
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #E6FFBE;
    width: 100%;
    /* height: 100vh; */
}

.integrated-section {
    padding-top: 35px;
}

.border-left-integrated-section {
    cursor: pointer;
    transition: background-color 0.3s;
}

.border-left-integrated-section.active {
    border-left: 3px solid grey;
}

.interactive-sec-text {
    font-size: 21px;
    display: none;
    font-family: 'Inter Tight Medium';
}

.interactive-sec-text.active {
    font-size: 21px;
    display: block;
    font-family: 'Inter Tight Medium';
    /* color: chocolate; */
}

.interactive-sec-text-mobile {
    font-size: 20px;
    font-family: 'Inter Tight Medium';
}

.interactive-title-text {
    color: #591FFF !important;
    font-size: 40px !important;
    text-align: center;
    font-family: 'Inter Tight Medium';

    .strong {
        font-family: 'Inter Tight Medium';
    }
}

.interactive-sec-text-title {
    font-weight: bold;
    font-size: 25px;
    font-family: 'Inter Tight Bold';
}

.interactive-sec-text-title-active {
    font-weight: bold;
    font-size: 25px;
    font-family: 'Inter Tight Bold';
    /* color: chocolate; */
}

.interactive-sec-text-title-mobile {
    font-weight: bold;
    font-size: 24px;
    font-family: 'Inter Tight Bold';
}

.interactive-card-mobile {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: transparent;
    border: 2px black solid;
}

.interactive-card-image-sec-mobile {
    padding-left: 20px;
    padding-right: 20px;
}

.interactive_m_icon {
    width: 120px;
    top: 221px;
    position: absolute;
    right: 0px;
}

.yellowLine-img-4 {
    margin-left: 194px;
    width: 218px;
}

.register-free-course-btn-inte {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background: #591FFF;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
}

.register-free-course-btn-inte span {
    color: white !important;
    font-weight: 600;
    font-family: 'Inter Tight Medium';
}

.carousel-indicators-mobile-sec-4-inte {
    position: absolute;
    right: 0;
    bottom: -3px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

/* Use a more specific selector */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: none;
}


.custom-svg {
    background-color: #310f3b;
    border-radius: 50%;
    fill: white;
    width: 40px;
    height: 40px;
}
