/* section-8-container footer */
.meeku_logo_footer {
  cursor: pointer;
}
.footer-container-n {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #591fff;
  width: 100%;
  /* height: 100vh; */
}

.footer-section {
  padding-top: 65px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.safe-environment-footer-text {
  color: white;
  font-size: 16px !important;
  font-family: "Inter Tight Medium";
}

.Meeku-Learning-footer-text {
  font-size: 12px !important;
  text-align: center;
  color: white !important;
  font-family: "Inter Tight Medium";
}

.atag-color {
  cursor: pointer;
  color: white !important;
  text-decoration: underline;
}

/* new */
.content {
  height: 40px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 60px;
  overflow: hidden;
}

.subscription {
  position: relative;
  width: 100%;
  height: 100%;
}

.subscription .add-email {
  font-family: "Inter Tight Medium";
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 20px;
}

.subscription .submit-email {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 2px);
  width: 100px;
  border: none;
  border-radius: 60px;
  outline: none;
  margin: 1px;
  padding: 0 20px;
  cursor: pointer;
  background: #4316c3;
  color: #ffffff;
  transition: width 0.35s ease-in-out, background 0.35s ease-in-out;
}

.subscription.done .submit-email {
  width: calc(100% - 2px);
  background: #c0e02e;
}

.subscription .submit-email .before-submit,
.subscription .submit-email .after-submit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 38px;
  transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
}

.subscription.done .submit-email .before-submit,
.subscription:not(.done) .submit-email .after-submit {
  visibility: hidden;
  opacity: 0;
}

.subscription .submit-email .after-submit {
  transition-delay: 0.35s;
}

.subscription:not(.done) .submit-email .before-submit,
.subscription.done .submit-email .after-submit {
  visibility: visible;
  opacity: 1;
}
