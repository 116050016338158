.introduction_text {
  color: blueviolet;
  font-size: 25px;
  font-weight: bold;
}
.price_session_text {
  font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.546);
}
.price_session_number {
  font-size: 22px;
  color: black;
}
.instructors_text {
  font-size: 18px;
}
.Patricia_text {
  color: blue;
  font-size: 15px;
}
.Design_Expert_text {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.546);
}
.about_course_text {
  font-size: 23px;
  font-weight: 600;
}
.about_course_discription_text {
  font-size: 17px;
}
.course_glance_text {
  font-weight: bold;
  font-size: 20px;
}
.Duration_text {
  color: rgba(0, 0, 0, 0.637);
}
.between_text {
  color: rgba(0, 0, 0, 0.82);
}
.course_glance_card {
  border: 2px solid blueviolet;
}
.learners_card {
  border: 2px solid blueviolet;
}
.required_text {
  color: rgba(0, 0, 0, 0.637);
}
.Categories_btn {
  border-radius: 20px;
  color: black;
  font-size: 13px;
  margin-right: 5px;
}
.Session_title_text {
  font-weight: bold;
  font-size: 18px;
}
.instructors_text_2 {
  font-weight: bold;
  font-size: 23px;
}

.Categories__btn {
  border: 2px solid orange !important;
  background-color: white !important;
  color: black !important;
  border-radius: 18px !important;
}
.select_slot_btn {
  background-color: black !important;
  color: white !important;
  border-radius: 0px;
  margin-top: 20px;
  width: 75%;
}
.cart_close_icon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  top: 18px;
}
.cart_drawer_box {
  width: 60px;
  height: 70px;
  background-color: grey;
  border-radius: 0px;
  border: 1px solid gray;
}
.footer {
  position: absolute;
  bottom: 20px;
  right: 0px;
}
.make_payment_btn {
  background-color: black !important;
  color: white !important;
  border-radius: 0px;
  width: 80%;
}

.course_section_box {
 
  padding: 15px;
  border-radius: 25px;
  border: 1px solid gray;
}
.course_slots_text {
  font-size: 20px;
  font-weight: bold;
}
.starting_text_slots {
  font-size: 20px;
}
.date_text_slots {
  font-size: 22px;
  font-weight: bold;
}
.time_text_slots {
  font-size: 15px;
}
.week_text_slots {
  margin-top: 10px !important;
  font-size: 20px;
}

.slots_remaining_box {
  margin-top: 15px;
  padding: 5px;
  border-radius: 25px;
  background-color: darkgrey;
  border: 1px solid gray;
}

.slots_remain_text {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.enrool__btn {
  background-color: orange !important;
  color: white;
  border-radius: 0px !important;
  margin-top: 7px;
  width: 100%;
  font-size: 16px;
}
.enrool__price_text {
  margin-top: 13px;
  margin-right: 40px;
  font-size: 20px;
  font-weight: bold;
}
.view_session_text{
    text-align: center;
    font-size: 18px;
}
