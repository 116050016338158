.tour-start-btn {
    color: white !important;
    background-color: #591FFF !important;
    border-radius: 7px;
    padding: 3px;
    padding-right: 8px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    font-family: 'Poppins Regular';
}

.Take-the-Tour-btn-myc {
    margin-top: 10px;
    background: #FDB600 !important;
    font-family: 'Poppins Regular';
    color: #000000 !important;
    border-radius: 27px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 6px;
}

.no-scroll {
    overflow: hidden !important;
    height: 100% !important;
    position: fixed !important;
    width: 100%;
}

/* .cat-img-gif-cgv4 {
    position: absolute;
    right: -150px;
    width: 40%;
    bottom: -28px;
}

.star-img-cgv4 {
    position: absolute;
    right: 8px;
    top: 8px;
} */

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(50px);
        /* Start slightly off-screen */
    }

    100% {
        opacity: 1;
        transform: translateX(0);
        /* End at the original position */
    }
}

.cat-img-gif-cgv4,
.star-img-cgv4 {
    position: absolute;
    animation: slideInFromRight 1s ease-in-out forwards;
    /* Smooth slide-in animation */
    opacity: 0;
    /* Ensure the initial state matches animation */
}

.cat-img-gif-cgv4 {
    right: -150px;
    width: 40%;
    bottom: -28px;
}

.star-img-cgv4 {
    right: 8px;
    top: 8px;
}