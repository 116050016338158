/** #region RESPONSIVE SCREENS */

/* section-1 */
.desktop-section-1-title-cv2 {
    font-family: 'Inter Tight Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.white-desc-cv2 {
    font-family: 'Inter Tight Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: #FDFDFD;
}

.toggler-icon-cv2 {
    border: none !important;
    box-shadow: none !important;
}

.register-free-course-btn-cv2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background: #F9BA1A;;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
}

.register-free-course-btn-cv2 span {
    color: black !important;
    font-weight: 600;
    font-family: 'Inter Tight Medium';
}

.register-free-date-cv2 {
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'Inter Tight Medium';
}

.fire-text-cv2 {
    font-family: 'Inter Tight Medium';
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
}

/* section-2 */
.section-2-container-cv2 {
    background: #FFFFFF;
}

.section-2-cv2 {
    position: relative;
    bottom: 73px;
}


.Beginner-level-card-cv2 {
    padding: 30px 25px;
    background-color: #FFFFFF;
    border-radius: 18px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    /* height: 135px; */
    z-index: 1;
}

.Beginner-level-card-title-cv2 {
    font-family: 'Inter Tight Bold';
    color: #000000;
    font-size: 20px;
    font-weight: 600;

}

.Beginner-level-card-text-cv2 {
    font-family: 'Inter Tight Medium';
    font-size: 17px;
    font-weight: 500;
    color: #818694;
}


.What-kids-learn-card-cv2 {
    padding: 30px 25px;
    background-color: #F9F6FF;
    border-radius: 28px;
    align-items: center;
    z-index: 1;
    border: 2px #591FFF solid;
}

.section-2-kids-learn-titles-cv2 {
    font-family: 'Inter Tight Bold';
    font-size: 25px;
    font-weight: 700;
    color: #000000;
}

.What_kids_text_cv2 {
    font-family: 'Inter Tight Medium';
    font-size: 17px;
}

.section-2-Course-doc-cv2 {
    font-family: 'Inter Tight Medium';
    font-size: 17px;
    font-weight: 500;
    color: #3B4357;
}

.section_1_blue_star-icon {
    width: 14px;
    margin-right: 8px;
}

.Topics-covered-card-cv2 {
    padding: 30px 12px;
    background-color: #F2F6F8;
    border-radius: 28px;
    align-items: center;
    z-index: 1;
}

.Topics-covered-title-cv2 {
    font-family: 'Inter Tight Bold';
    font-weight: 700;
    font-size: 17px;
}

.Topics-covered-doc-cv2 {
    font-family: 'Inter Tight Medium';
    font-weight: 500;
    font-size: 17px;
}

.Course-Glance-card-cv2 {
    padding: 23px 21px;
    background-color: #FFFFFF;
    border-radius: 18px;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    /* height: 135px; */
    z-index: 1;
}

.Course-Glance-titles-cv2 {
    font-family: 'Inter Tight Bold';
    font-weight: 700;
    font-size: 22px;
}

.register-free-course-btn-2-cv2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 47px;
    background: #F9BA1A;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
}

.register-free-course-btn-2-cv2 span {
    color: black !important;
    font-weight: 600;
}

.Share-text-cv2 {
    text-align: center;
    font-size: 18px;
    color: #000000;
    cursor: pointer;
}

.Instructor-card-cv2 {
    padding: 30px 25px;
    background-color: #FFFFFF;
    border-radius: 18px;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    /* height: 135px; */
    z-index: 1;
}

.Instructor-card-title-cv2 {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}

.Instructor-card-doc-cv2 {
    font-size: 17px;
    font-weight: 400;
    color: #818694;
}

/* section-3 */

.sections-title-com-cv2 {
    color: #591FFF !important;
    font-size: 40px !important;
    text-align: center;

    /* .strong {
        font-family: 'Poppins Bold';
    } */
}

/* section-4 */
.section-4-container-cv2 {
    background: #591FFF;
    padding-bottom: 20px;
}



.sections-title-com-color-w-cv2 {
    font-family: 'Inter Tight Medium';
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    text-align: center;

    /* .strong {
        font-family: 'Poppins Bold';
    } */
}

.form-top-text-cv2 {
    font-family: 'Inter Tight Medium';
    line-height: 31px;
    font-size: 22px;
    font-weight: 700;
    color: #FFFFFF;
}

.child-ready-form {
    height: 40px !important;
    border-radius: 10px;
    border: none;
    width: 100%;
    padding-left: 20px;
    color: black;
    font-size: 14px;
}

.child-ready-form-selected-text {
    height: 40px !important;
    border-radius: 10px;
    border: none;
    width: 100%;
    padding-left: 20px;
    color: black;
    font-size: 14px;
    font-weight: bold;
}

.Continue-btn-cv2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background: #F9BA1A;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 44px;
    border: none;
}

.Continue-btn-cv2 span {
    color: #000000 !important;
    font-weight: 700;
    font-family: 'Inter Tight Medium';

}

.register-free-course-btn-cv2-2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background: #591FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
    .register-free-course-btn-cv2 span {
        color: black !important;
        font-weight: 600;
        font-family: 'Inter Tight Medium';
        font-size: 12px;
    }

    .section-4-container-form-cv2 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .social-media-img-5 {
        display: flex;
    }

    .form_course_img_2_cv2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .background-image-sec-1-cv2 {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .m-icon-cv2 {
        display: none;
        width: 64px;
        position: absolute;
        top: 198px;
    }

    .section_1_white_star-cv2 {
        display: none;
        position: absolute;
        top: 226px;
        right: 31px;
    }



    .course-d-stream-sessions-box-inactive {
        background-color: #EAE2FF;
        margin-top: 6px;
        width: 90px;
        height: 59px;
        cursor: not-allowed;
    }

    .course-d-stream-sessions-box-active {
        align-items: center;
        background-color: #591FFF;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin-top: 6px;
        text-align: center;
        text-transform: uppercase;
        width: 90px;
        height: 59px;
    }

    .mobile-screen-course-v2 {
        display: block;
    }

    .large-screen-course-v2 {
        display: none;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .register-free-course-btn-cv2 span {
        color: black !important;
    font-weight: 600;
    font-family: 'Inter Tight Medium';
        font-size: 12px;
    }

    .section-4-container-form-cv2 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .form_course_img_2_cv2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .background-image-sec-1-cv2 {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .m-icon-cv2 {
        display: none;
        width: 64px;
        position: absolute;
        top: 198px;
    }

    .section_1_white_star-cv2 {
        display: none;
        position: absolute;
        top: 226px;
        right: 31px;
    }

    .course-d-stream-sessions-box-inactive {
        background-color: #EAE2FF;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }

    .course-d-stream-sessions-box-active {
        align-items: center;
        background-color: #591FFF;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin-top: 6px;
        text-align: center;
        text-transform: uppercase;
        width: 143px;
        height: 59px;
    }

    .course-d-stream-sessions-box-booked {
        align-items: center;
        background-color: #c4b5e9;
        cursor:not-allowed;
        display: flex;
        font-weight: 600;
        justify-content: center;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }

    .mobile-screen-course-v2 {
        display: block;
    }

    .large-screen-course-v2 {
        display: none;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
.register-free-course-btn-cv2 span {
    color: black !important;
    font-weight: 600;
    font-family: 'Inter Tight Medium';
        font-size: 12px;
    }

    .section-4-container-form-cv2 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .form_course_img_2_cv2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .background-image-sec-1-cv2 {
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .m-icon-cv2 {
        display: none;
        width: 64px;
        position: absolute;
        top: 198px;
    }

    .section_1_white_star-cv2 {
        display: block;
        position: absolute;
        top: 493px;
        right: 31px;
    }


    .course-d-stream-sessions-box-inactive {
        background-color: #EAE2FF;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }

    .course-d-stream-sessions-box-active {
        align-items: center;
        background-color: #591FFF;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin-top: 6px;
        text-align: center;
        text-transform: uppercase;
        width: 143px;
        height: 59px;
    }


    .course-d-stream-sessions-box-booked {
        align-items: center;
        background-color: #c4b5e9;
        cursor:not-allowed;
        display: flex;
        font-weight: 600;
        justify-content: center;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }
    .mobile-screen-course-v2 {
        display: none;
    }

    .large-screen-course-v2 {
        display: block;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
.register-free-course-btn-cv2 span {
    color: black !important;
    font-weight: 600;
    font-family: 'Inter Tight Medium';
        font-size: 16px;
    }


    .section-4-container-form-cv2 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .form_course_img_2_cv2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .background-image-sec-1-cv2 {
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .m-icon-cv2 {
        display: none;
        width: 64px;
        position: absolute;
        top: 198px;
    }

    .section_1_white_star-cv2 {
        display: none;
        position: absolute;
        top: 226px;
        right: 31px;
    }



    .course-d-stream-sessions-box-inactive {
        background-color: #EAE2FF;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }

    .course-d-stream-sessions-box-active {
        align-items: center;
        background-color: #591FFF;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin-top: 6px;
        text-align: center;
        text-transform: uppercase;
        width: 143px;
        height: 59px;
    }

    .course-d-stream-sessions-box-booked {
        align-items: center;
        background-color: #c4b5e9;
        cursor:not-allowed;
        display: flex;
        font-weight: 600;
        justify-content: center;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }

    .mobile-screen-course-v2 {
        display: none;
    }

    .large-screen-course-v2 {
        display: block;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .register-free-course-btn-cv2 span {
        color: black !important;
    font-weight: 600;
    font-family: 'Inter Tight Medium';
        font-size: 16px;
    }

    .section-4-container-form-cv2 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .form_course_img_2_cv2 {
        padding-left: 50px;
        padding-right: 50px;
    }


    .background-image-sec-1-cv2 {
        padding-top: 100px;
        padding-bottom: 75px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .m-icon-cv2 {
        display: block;
        width: 64px;
        position: absolute;
        top: 198px;
    }

    .section_1_white_star-cv2 {
        display: block;
        position: absolute;
        top: 145px;
        right: 31px;
    }


    .course-d-stream-sessions-box-inactive {
        background-color: #EAE2FF;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }

    .course-d-stream-sessions-box-active {
        align-items: center;
        background-color: #591FFF;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin-top: 6px;
        text-align: center;
        text-transform: uppercase;
        width: 143px;
        height: 59px;
    }

    .course-d-stream-sessions-box-booked {
        align-items: center;
        background-color: #c4b5e9;
        cursor:not-allowed;
        display: flex;
        font-weight: 600;
        justify-content: center;
        margin-top: 6px;
        width: 143px;
        height: 59px;
        cursor: not-allowed;
    }

    .mobile-screen-course-v2 {
        display: none;
    }

    .large-screen-course-v2 {
        display: block;
    }
}

/** #endregion */

/** SECTION 1 */
.main-container-cv2 {
    height: 100%;
    width: 100%;
}

/* section-1-container */
.section-1-container-cv2 {
    position: relative;
    /* margin-top: 15px; */
    margin-left: 0px;
    margin-right: 0px;
}



.desktop-section-1-container-cv2-mobile {
    position: absolute;
    top: 2%;
    padding: 20px;
}



.desktop-section-1-title-mobile {
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 103%;
    color: #FDFDFD;
}



.groupRatingIcon {
    position: absolute;
    top: -11%;
    left: 22%;
}

.groupRatingIcon-mobile {
    display: flex;
    justify-content: center;
}


.sections-title-com-white-color-cv2 {
    color: #FFFFFF !important;
    font-size: 40px !important;
    text-align: center;

    /* .strong {
        font-family: 'Poppins Bold';
    } */
}

.yellowLine-img-cv2 {
    margin-left: 240px;
    width: 21%;
}



.tackle-card-text-cv2 {
    font-weight: bold;
    line-height: 110%;
}

.With-screens-text-cv2 {
    text-align: center;
    font-weight: 500;
    margin-left: 40px;
    margin-right: 40px;
}

.multi-union-img-cv2 {
    position: absolute;
    top: 44%;
    right: 3%;
}

/* section-3-container */


.section-3-cv2 {
    padding-top: 35px;
}

.section-3-cv2-mobile {
    padding-top: 35px;
}

.yellowLine-img-cv2-2 {
    margin-left: 10px;
    /* width: 11%; */
}

.meeku-world-card-cv2 {
    padding: 30px 25px;
    background-color: #FFFFFF;
    border-radius: 18px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.meeku-world-card-title-cv2 {
    font-family: 'Poppins Bold';
}

.meeku-world-card-text-cv2 {
    font-family: 'Poppins';
}



/* AutoRotateAndShowHideCards.css */

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
}

.cards-container {
    display: flex;
    flex-direction: column;
}

.card_meeku_world_v3 {
    display: none;
    transition: opacity 0.5s ease-in-out;
}

.card_meeku_world_v3.visible {
    display: block;
    opacity: 1;
}

.section3_star_img_cv2 {
    position: absolute;
    top: 13%;
    width: 13%;
    right: 149px;
}

/* section-3-container */


.yellowLine-img-cv2-4 {
    margin-left: 194px;
    width: 218px;
}

.section-4-cv2-mobile {
    padding-top: 35px;
}


/* section-5-container */



.courses-offered-btn-cv2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background: #591FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
}

.courses-offered-btn-cv2 span {
    color: white;
    text-transform: uppercase;
}

/* SCHEDULE DESIGNS */
.course-d-streams-main {
    display: flex;
}

.course-d-streams {
    margin-right: 6px;
}

.course-d-stream-date {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.course-d-stream-day {
    color: #000000;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
}

.course-d-stream-sessions-time {
    align-items: center;
    color: #591FFF;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: start;
    margin-top: 6px;
    width: 95px;
    height: 59px;
    text-transform: uppercase;
    text-align: start;
}

.seats-more{
    font-size: 12px;
    text-transform: initial;
}


.success_c {
    display: flex;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  
.success_img_c {
    justify-content: center;
    font-family: 'Khand SemiBold';
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  
  .success_img_div {
    display: flex;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  
  .success_icon {
    font-family: 'Khand SemiBold';
    padding-bottom: 8px;
    width: 25%;
  }
  
  .right-side-detail-padding{
    padding: 23px 25px;
  }