.my_courses_section_1 {
  background-color: whitesmoke;
  max-width: 100%;
  padding: 15px;
}

.courses_title_text {
  font-family: 'Inter Tight Medium';
  font-size: 24px;
  color: #3B4357;
  font-weight: 700;
}

.sample_img {
  width: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_courses_text_section {
  padding: 10px;
}

.my_courses_title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.781);
}

.my_courses_session {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.781);
}

.my_courses_next_session {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.587);
  font-weight: bold;
}

.my_courses_next_date {
  position: relative;
  bottom: 9px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.587);
}

.my_courses_btn_group {
  padding-left: 10px;
  margin-bottom: 15px;
}

.l_pods_btn {
  color: var(--btn-color-primary) !important;
  background-color: var(--btn-bg-color-primary);
  border-radius: 15px;
  padding: 3px;
  width: 105px;
  border: none;
  font-weight: 700;
}

.l_pods_btn:hover{
  border: none;
  background-color: var(--btn-bg-color-primary);
  color: var(--btn-color-primary) !important;
}
.course_info_btn {
  border: none !important;
  font-size: 12px;

  padding: 3px;
  color: rgba(0, 0, 0, 0.587);
}

.l_curriculum_btn {
  color: var(--btn-color-primary)  !important;
  background-color: white !important;
  border-radius: 15px;
  padding: 3px;
  width: 105px;
  border: 1px solid #D4D9DF;
  font-weight: 700;
}

.l_curriculum_btn:hover{
  border: 1px solid #D4D9DF;
}

.course_card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.course_card_len {
  border: 2px solid;
  border-color: lightgray;
  border-radius: 8px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.badge_course {
  margin-left: 25px;

  margin-bottom: 8px;
  background-color: var(--btn-color-primary) !important;
}


/* DESKTOP */



.l-my-course-img-main {
  width: calc(100% - 70%);
  height: 100%;
}

.l-my-course-info-main {
  width: calc(100% - 50%);
  padding: 12px 12px 12px 12px;
}

.l-my-course-info-cname {
  color: rgba(0, 0, 0, 0.781);
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  text-transform: capitalize;
  top: 8px;
  width: calc(100% - 50%);
}

.l-my-course-info-sessions {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.781);
  text-transform: uppercase;
}

.l-my-course-actions-main {
  text-align: center;
  width: calc(100% - 70%);
}

.l-my-course-info-next-session-label {
  color: rgba(0, 0, 0, 0.587);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.l-my-course-info-next-session-data {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.587);
}

.l-my-course-info-bottom {
  position: absolute;
  bottom: 8px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

  .l_main_my_course_padding{
    padding-top: 0px;
  }

  .l-my-course-main-container {
    padding: 10px 50px 10px 50px;
  }

  .l-my-course-card-main-desktop {
    align-items: center;
    display: unset;
    height: unset;
    margin-top: 0px;
    width: 100%;
    border-radius: var(--card-border-radius);
  }
  .l-my-course-card-main-desktop-view {
    display: none;
  }

  .l-my-course-card-main-mobile-view {
    display: block;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .l_main_my_course_padding{
    padding-top: 0px;
  }

  .l-my-course-main-container {
    padding: 10px 50px 10px 50px;
  }

  .l-my-course-card-main-desktop {
    align-items: center;
    display: flex;
    height: 150px;
    margin-top: 0px;
    width: 100%;
    border-radius: var(--card-border-radius);
  }
  .l-my-course-card-main-desktop-view {
    display: block;
  }

  .l-my-course-card-main-mobile-view {
    display: none;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .l_main_my_course_padding{
    padding-top: 0px;
  }

  .l-my-course-main-container {
    padding: 10px 150px 10px 150px;
  }

  .l-my-course-card-main-desktop {
    align-items: center;
    display: flex;
    height: 150px;
    margin-top: 0px;
    width: 100%;
    border-radius: var(--card-border-radius);
  }
  .l-my-course-card-main-desktop-view {
    display: block;
  }

  .l-my-course-card-main-mobile-view {
    display: none;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .l_main_my_course_padding{
    padding-top: 14px;
  }

  .l-my-course-main-container {
    padding: 10px 224px 10px 224px;
  }

  .l-my-course-card-main-desktop {
    align-items: center;
    display: flex;
    height: 150px;
    margin-top: 0px;
    width: 100%;
    border-radius: var(--card-border-radius);
  }
  .l-my-course-card-main-desktop-view {
    display: block;
  }

  .l-my-course-card-main-mobile-view {
    display: none;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .l_main_my_course_padding{
    padding-top: 14px;
  }
  
  .l-my-course-main-container {
    padding: 10px 224px 10px 224px;
  }

  .l-my-course-card-main-desktop {
    align-items: center;
    display: flex;
    height: 150px;
    margin-top: 0px;
    width: 100%;
    border-radius: var(--card-border-radius);
  }

  .l-my-course-card-main-desktop-view {
    display: block;
  }

  .l-my-course-card-main-mobile-view {
    display: none;
  }
}

.css-v1cn2r-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #591FFF !important;
}