/* Check your CSS for any unintended transforms */
.pdf-viewer-container canvas {
    transform: none !important;
}

/* Hide the Download button */
.pdf-viewer-container .rpv-toolbar__item button[data-testid="get-file__download-button"] {
    display: none !important;
}

/* Hide the Print button, if needed, using the specific data-testid attribute */
.pdf-viewer-container .rpv-toolbar__item button[data-testid="print__button"] {
    display: none !important;
}

/* Hide the open__button, if needed, using the specific data-testid attribute */
.pdf-viewer-container .rpv-toolbar__item button[data-testid="open__button"] {
    display: none !important;
}

/* Hide the toolbar__more-actions-popover-target, if needed, using the specific data-testid attribute */
.pdf-viewer-container .rpv-toolbar__item button[data-testid="toolbar__more-actions-popover-target"] {
    display: none !important;
}