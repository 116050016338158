/* COMMON */
.height-100 {
  height: 100%;
}

.text-color-white {
  color: #ffffff;
}

.border-radius-20 {
  border-radius: 20px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .mobile-screen {
    display: block;
  }

  .mobile-screen-portrait {
    display: block;
  }

  .mobile-screen-landscape {
    display: none;
  }

  .large-screen {
    display: none;
  }

  .learner-group-space-container-gv2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 7px 0px 7px;
    width: 100%;
  }

  /* HEADER */
  .learner-group-space-header-height {
    align-items: center;
    height: 30px;
  }

  .learner-group-space-logo-container-gv2 {
    align-items: center;
    display: flex;
  }

  .learner-group-space-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 17px;
    height: 17px;
  }

  .learner-group-space-bread-crumbs {
    cursor: pointer;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    cursor: pointer;
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
  }

  .text-color-active-breadcrumbs-inactive {
    cursor: pointer;
    color: #fff;
  }

  .text-color-active-breadcrumbs-inactive:hover {
    text-decoration: underline;
  }

  .learner-group-space-title-gv2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .learner-group-space-session-timing-gv2 {
    width: 49.87px;
    height: 20.29px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-group-space-session-timing-gv2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  /* CONTENT */
  .learner-group-space-content-height {
    height: calc(100% - 60px);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .mobile-screen {
    display: block;
  }

  .mobile-screen-portrait {
    display: none;
  }

  .mobile-screen-landscape {
    display: block;
  }

  .large-screen {
    display: none;
  }

  .learner-group-space-container-gv2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 7px 0px 7px;
    width: 100%;
  }

  /* HEADER */
  .learner-group-space-header-height {
    align-items: center;
    height: 30px;
  }

  .learner-group-space-logo-container-gv2 {
    align-items: center;
    display: flex;
  }

  .learner-group-space-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 17px;
    height: 17px;
  }

  .learner-group-space-bread-crumbs {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
  }

  .learner-group-space-title-gv2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .learner-group-space-session-timing-gv2 {
    width: 49.87px;
    height: 20.29px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-group-space-session-timing-gv2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  /* CONTENT */
  .learner-group-space-content-height {
    height: calc(100% - 60px);
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .mobile-screen {
    display: block;
  }

  .mobile-screen-portrait {
    display: none;
  }

  .mobile-screen-landscape {
    display: block;
  }

  .large-screen {
    display: none;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .mobile-screen {
    display: none;
  }

  .large-screen {
    display: block;
  }

  .learner-group-space-container-gv2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 20px 0px 20px;
    width: 100%;
  }

  /* HEADER */
  .learner-group-space-header-height {
    align-items: center;
    height: 60px;
  }

  .learner-group-space-logo-container-gv2 {
    align-items: center;
    display: flex;
  }

  .learner-group-space-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 32px;
    height: 32px;
  }

  .learner-group-space-bread-crumbs {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
  }

  .learner-group-space-title-gv2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
  }

  .learner-group-space-session-timing-gv2 {
    width: 75.67px;
    height: 32px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-group-space-session-timing-gv2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }

  /* CONTENT */
  .learner-group-space-content-height {
    height: calc(100% - 8px);
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .mobile-screen {
    display: none;
  }

  .large-screen {
    display: block;
  }

  .learner-group-space-container-gv2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 20px 0px 20px;
    width: 100%;
  }

  /* HEADER */
  .learner-group-space-header-height {
    align-items: center;
    height: 60px;
  }

  .learner-group-space-logo-container-gv2 {
    align-items: center;
    display: flex;
  }

  .learner-group-space-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 32px;
    height: 32px;
  }

  .learner-group-space-bread-crumbs {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
  }

  .learner-group-space-title-gv2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
  }

  .learner-group-space-session-timing-gv2 {
    width: 75.67px;
    height: 32px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-group-space-session-timing-gv2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }

  /* CONTENT */
  .learner-group-space-content-height {
    height: calc(100% - 0px);
  }
}

.cursor-pointer {
  cursor: pointer;
}

/* CONTENT */
.learner-group-space-content-row {
  /* display: flex; */
  height: calc(100% - 88px);
}

.multi-div-gv2 {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  overflow: auto;
}

.learner-group-space-content-container-extended-gv2 {
  border-radius: 20px;
  /* width: calc(100% - 554px); */
  width: 100%;
}

.learner-group-space-content-container-gv2 {
  border-radius: 20px;
  width: calc(100% - 160px);
}

.class_room_text_section-gv2 {
  min-height: 100vh;
}

/* PARTICIPANTS - EXPERT */

.participants-column-gv2 {
  /* overflow: auto; */
  width: 160px;
  padding-left: 20px;
  padding-right: 20px;
}

.learner-group-space-expert-box-gv2 {
  background: #474792;
  border-radius: 24px;
  height: 164px;
  width: 120px;
  text-align: center;
  padding-top: 5px;
}

.expert-lable {
  color: #ffffff;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  position: relative;
  text-transform: uppercase;
  top: 5px;
}

.host-image-container-gv2 {
  background: #41464b;
  border-radius: 32px;
  border: 2px solid #ffffff;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.host-image-container-gv2-audio-enable {
  border: 2px solid #11b511 !important;
}

.host-image-container-gv2 canvas {
  border-radius: 32px;
  height: 100% !important;
  width: 100% !important;
}

.host-image-container-gv2 video {
  border-radius: 32px;
  height: 100% !important;
  width: 100% !important;
}

.host-container-gv2 {
  position: relative;
  top: 8px;
  width: 80px;
  height: 80px;
}

.learner-group-space-expert-container-gv2 {
  display: flex;
  justify-content: center;
}

.host-rounded-video-off-gv2 {
  padding: 5px;
}

.host-rounded-video-off-gv2-audio-enable {
  border-radius: 50px;
  border: 1.5px solid #11b511;
}

.host-rounded-video-off-gv2-audio-disable {
  border-radius: none;
}

.host-rounded-video-off-div-gv2 {
  justify-content: center;
  align-content: center;
  display: flex;
  top: 40%;
  position: relative;
}

.host-video-off-initial-gv2 {
  background: #fff;
  padding: 5px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
}

.host-label-gv2 {
  color: #ffffff;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.participant-item-card-footer-icon-gv2 {
  bottom: 10px;
  left: 10px;
  position: absolute;
  width: 28px;
}

.footer-active {
  background: #213d5e !important;
}

/* PARTICIPANTS - LEARNERS LIST */
.learners-list-box-gv2 {
  text-align: center;
  display: inline-block;
  position: relative;
  left: 20px;
}

.learners-list-containter-gv2 {
  margin-top: 39px;
}

/* FOOTER CONTROLS */

.clicked-raised-hand {
  background: #213d5e !important;
}

.general-notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  background: rgb(23, 211, 23);
  border-radius: 50%;
}

.footer-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 17px;
}
.footer-controls-large {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.controls-container {
  display: flex;
}

.control-box {
  position: relative;
  background-color: #2b5d9a;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  margin-right: 16px;
  color: #fff;
}

.control-red-bg {
  background-color: #f34444 !important;
}

.footer-control-icons-gv2 {
  color: #ffffff;
  font-size: 24px;
}

.page-menu-item-img {
  width: 24px;
}

/* RIGHT SIDE NAV BAR */
.right-sidebar {
  width: 394px;
  position: absolute;
  top: 75px;
  right: 188px;
  height: calc(100% - 220px);
}

.right-sidebar-container {
  background-color: #000000;
  border-radius: 24px;
  width: 394px;
  height: 100%;
  opacity: 75%;
}

.right-sidebar-heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.right-sidebar-heading span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}

.right-sidebar-body {
  height: calc(100% - 172px);
  overflow: auto;
  position: relative;
}

/* CHAT */
.chat-msg-list-gv2 {
  flex-direction: column-reverse;
  display: flex;
  overflow-y: auto;
  height: 100%;
}

.chat-box-container-gv2 {
  position: absolute;
  bottom: 0;
  padding: 5px 15px 5px 15px;
  width: 100%;
}

.send-input-box-gv2 {
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}

.input-group-append {
  margin-right: 20px;
}

.send-screenshot-gv2 {
  cursor: pointer;
}

.send-input-gv2 {
  font-size: 16px;
  margin-right: 5px;
  border-radius: 16px;
}

.leave-session-warn {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  /* grey90 */
  color: #3b4357;
}

.confirm-no-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 48px;
  text-align: center;
  /* White */
  background: #ffffff;
  /* grey40 */
  border: 1px solid #d4d9df;
  border-radius: 12px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  /* grey90 */
  color: #3b4357;
}

.confirm-yes-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 48px;
  text-align: center;
  background: #ea3323;
  border-radius: 12px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #ffffff;
}

/* CHAT */
.chat-my-msg-main-gv2 {
  padding: 5px 15px 5px 15px;
}

.chat-my-msg-container-gv2 {
  width: 80%;
  float: right;
  background-color: #13b8ff;
  padding: 8px 10px 8px 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-name-container-gv2 {
  text-align: right;
}

.my-name-gv2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.my-msg-container {
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #3b4357;
}

.chat-rvr-msg-container-gv2 {
  float: left;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
  padding: 10px 10px 10px 10px;
  width: 80%;
}

.chat-rvr-name-gv2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.chat-rvr-msg-gv2 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  word-wrap: anywhere;
}

.rvr-container-gv2 {
  display: flex;
  align-items: center;
  position: relative;
}

.rvr-name-gv2 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.rvr-msg-container-gv2 {
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #fff;
  word-wrap: anywhere;
}

.rvr-avatar-container {
  background: #084159 !important;
  width: 40px;
  height: 40px;
  margin-right: 6px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.rvr-msg-container-div {
  margin-left: 46px;
}

.send-input-box {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.send-input {
  font-size: 16px;
}

.chat-send-btn {
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  background: #13b8ff !important;
  color: #fff !important;
  border: none !important;
}

.chat-send-btn:hover {
  background: #13b8ff !important;
  color: #fff !important;
}

.chat-send-btn:focus {
  box-shadow: none !important;
}

.chat-send-btn img {
  width: 16px;
  margin-right: 5px;
}

.emoji-menu {
  top: -185px !important;
}

.learner-user-name-container {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.learner-user-name-icon {
  width: 30px;
  height: 30px;
  background-color: #8eb3df;
  border-radius: 36px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #1e0e0e;
}

.whiteboard-tabs-gsp {
  background-color: #d6dbde;
  display: flex;
  width: 100%;
  overflow: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.whiteboard-tab-container-gsp {
  cursor: pointer;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 7px;
  align-items: center;
  background-color: #5901e6;
}

.whiteboard-in-active-gsp {
  background-color: #f1f3f6;
  cursor: pointer;
}

.whiteboard-active-gsp {
  background-color: #fff;
  cursor: pointer;
  color: #5901e6;
}

.whiteboard-tab-button-gsp {
  align-items: center;
  color: #3b4357;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 15px 15px;
  width: 330px;
}

.whiteboard-tab-name-gsp:hover {
  color: #5901e6;
}

.whiteboard-iframe-gsp {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* QUIZ */

.gsp-results-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
}

.gsp-quiz-question-pop {
  font-size: 14px;
  font-weight: 600;
}

.gsp-quiz-learner-answer-correct {
  color: green;
}

.gsp-quiz-learner-answer-wrong {
  color: red;
}

.gsp-quiz-answer-pop {
  font-size: 14px;
  padding-left: 10px;
}

.gsp-quiz-correct-answer-pop {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}

.gsp-quiz-status-flag-completed {
  font-size: 12px;
  background: green;
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  font-weight: 600;
}

.gsp-quiz-status-flag-inprogress {
  font-size: 12px;
  background: #1414b1;
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  font-weight: 600;
}

.gsp-game-property-text {
  text-transform: capitalize;
}

.gsp-no-quiz-found-container {
  font-size: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: 600;
}

.game-emty-link-div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  height: 100%;
  width: 100%;
  background: #13b8ff;
}

.game-card-container-gsp {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.game-card-in-active-gsp {
  font-size: 17px;
  font-weight: bold;
  padding: 13px;
  margin-right: 10px;
  width: 350px;
  border-radius: 6px;
  border: solid 2px #f1f3f6;
  background-color: #fff;
  cursor: pointer;
}

.game-card-in-active-gsp:hover {
  font-size: 17px;
  font-weight: bold;
  padding: 13px;
  margin-right: 10px;
  width: 350px;
  border-radius: 6px;
  border: solid 2px #f1f3f6;
  background-color: #fff;
  cursor: pointer;
  color: #5901e6;
  box-shadow: 0px 0px 3px 3px #f6f1f1;
}

.game-card-active-gsp {
  font-size: 17px;
  font-weight: bold;
  padding: 13px;
  margin-right: 10px;
  width: 350px;
  border-radius: 6px;
  border: solid 2px #f1f3f6;
  background-color: lightgray;
  cursor: pointer;
  color: white;
  box-shadow: 0px 0px 2px 2px #f6f1f1;
}

.whiteboard-container-section-div {
  position: relative;
}

.dropdown-wb-gsp {
  position: absolute;
  left: 80px;
  top: 20px;
  z-index: 1;
}

.game-container-section-div {
  position: relative;
}

.game-link-iframe-gsp {
  position: absolute;
}
.whiteboard-link-iframe-gsp {
  position: absolute;
}

.game-back-icon-gsp {
  color: green !important;
  position: absolute;
  background-color: white !important;
  z-index: 1;
  right: 4px;
  top: 4px;
}

.play_game_btn_gsp {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  width: 100px;
  font-size: 12px;
  padding: 4px;
  border: none;
  margin-top: 5px;
}
