/* CURRICULAM STEP - 4 */
.main_curriculam{
    border-bottom: 2px solid grey;
    padding-bottom: 8px;
    padding-top: 8px;
}
.content-file-upload>input {
    display: none;
  }

  .whiteboard-flag{
    font-size: 12px;
    background: #33b16f;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    font-weight: 600;
}

.quiz-flag{
    font-size: 12px;
    background: #1414b1;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    font-weight: 600;
}

.game-flag{
    font-size: 12px;
    background: #03285e;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    font-weight: 600;
}
.cursor-pointer{
    cursor: pointer;
}
.navbar{
    background: whitesmoke !important;
}

.stepper-section{
    padding-top: 30px;
    background-color: whitesmoke !important;
    height: 100vh;
}
.stepper_nav{
    border-bottom: 2px solid gray;
    
}
.publish_btn{
    color: white !important;
    background-color: orange !important;
    width: 10%;
    border-radius: 15px; 
    padding: 10px;
}
.stepper_total_content{
    padding-top: 30px;
}
.stepper-section-1{
    background-color: whitesmoke !important;
    padding-left: 20px;
}
.Slider{
    color: black;
}
.MuiSlider-rail{
    color: gray !important;
}
.MuiSlider-track{
    color: orange !important;
}
.MuiSlider-thumb{
    color: orange !important;
}
#next-btn{
    color: white !important;
    background-color: orange;
    width: 18%;
   margin-top: 25px;
    border-radius: 15px;
}
#prev-btn{
    border: 2px solid rgb(128, 128, 128);
    background-color: white;
    color: orange;
    border-radius: 15px;
    width: 18%;
    margin-top: 25px;
   
  
}
.completed-text{
    padding-left: 20px !important;
}
.add_curriculum_btn{
    padding: 10px;
   background-color: #f1f3f6;
   color: rgba(0, 0, 0, 0.664);
}

#session-header{
    background-color: #f9fafb !important;
}
.characters {
    list-style: none;
    padding-left: 0;
 }
 
 .characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
 }
 
 .characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
 }
 
 .characters-thumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
 }
 .characters-thumb img {
    display: block;
    width: 100%;
    height: auto;
 }
 #fade-button{
    color: black;
 }
 #cancel_btn{
    border: 2px solid rgb(128, 128, 128);
    background-color: white;
    color: orange;
    border-radius: 15px;
    width: 18%;
    margin-top: 17px;
    margin-right: 10px;
 }

 #add_btn{
    color: white !important;
    background-color: orange;
    width: 18%;
   margin-top: 17px;
    border-radius: 15px;
}
.intro_robot_card{
    border-radius: 8px;
    margin-bottom:9px;
    border: 2px solid;
    border-color:lightgray;
}
.intro_robot_card_lesson{
    border: 2px solid;
    border-color: lightgray;;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,0.2);
}
/* .Mui-expanded {
     margin: 0px !important; 
} */

/* .css-1dy2dt4-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
     min-height: 64px !important; 
} */

.lesson_name{
   
   border-radius: 8px;
   
   
}
.lesson_text{
    font-size: 16px;
}
.upload_course{
    font-size: 13px;
}
.upload_file{
    font-size: 14px;
    font-weight: 600;
}
.select_file_btn{
    margin-top: 13px;
    margin-bottom: 8px;
    background-color: #f1f3f6;
    color: rgba(0, 0, 0, 0.664);
}
.paste_btn{
    margin-top: 13px;
    margin-bottom: 8px;
    background-color: #f1f3f6;
    color: rgba(0, 0, 0, 0.664);
}
.paste_link{
    font-size: 14px;
    font-weight: 600;
}
#select_file_btn_menu{
    margin-right: 50px;
}
.pre_assessment{
    border-radius: 8px;
    border: 2px solid;
    border-color:lightgray;
}
.select_quiz_close_btn {
    
    cursor: pointer;
    font-size: 20px;
    
}

.select_quiz_text{
    font-size: 19px;
    color: black;
    font-weight: 600;
}
.select_quiz_card{
  padding: 10px;
  cursor: pointer;
}
.select_quiz_card:hover{
    box-shadow: 0 0 5px rgb(50, 71, 139);
}
.assessment_questions_text{
    font-size: 20px;
    font-weight: 600;
}
.questions_text{
    font-size: 14px;
}
.courses_linked_text{
    font-size: 13px;
}
.card_img_background{
background-color: #d3d3d3;
}

.add_new_linked_btn{
    color: black;
    padding: 10px;
}

/* Stream */
.add_stream_btn{
  background-color: #f1f3f6;
  color: black;
   
}
.stream_card{
    border: 2px solid;
    border-color: lightgray;;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,0.2);
}
.done_btn{
    color: white !important;
    background-color: orange !important;
    width: 18%;
    border-radius: 15px;
    
}

.session_text{
   font-weight: 600;
}

.pre_assessment_list_accordion{
    border: 2px solid;
    border-color: lightgray;
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgb(0 0 0 / 20%);
}

.pre_assessment_list_card{
    border: 2px solid;
    border-color: lightgray;;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,0.2);
    border-top-width: 10px;
    border-top-color: orange;
}
.add_curriculum_card{
    background-color: hsl(0, 0%, 83%);
   
    
}
.add_option_text{
    font-size: 12px;
}
.correct_ans_dis_text{
   padding-left: 4%;
   padding-right: 4%;
   
}
.correct_ans_text{
    font-size: 13px;
   
}
.correct_answer_text{
    margin-right: 80px;
    color: green;
}

/* FILE UPLOADER STYLES */
:root {
    --body-bg: #f5f8ff;
    --box-bg: #fff;
    --input-bg: #f5f8ff;
    --txt-color: #2f2d2f;
    --txt-second-color: #ccc;
    --border-color: #4267b2;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.box {
    background-color: var(--box-bg);
    padding: 30px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
}

.course-info-cover-pic-container{
    position: relative;
}

.course-info-cover-pic-delete-icon{
    position:absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
/* FILE UPLOADER STYLES */

/* COURSE SESSIONS STEP 2 */
.course-sessions-document-container{
    position: relative;
}

.course-sessions-document-name{
    font-size: 14px;
    cursor: pointer;
}

.course-sessions-document-delete-icon{
    position:absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}