.courses-staream-s-circle {
  height: 10px;
  width: 10px;
  color: green;
  background-color: green;
  border-radius: 50%;
  margin-right: 3px;
}

.courses-staream-e-circle {
  height: 10px;
  width: 10px;
  color: red;
  background-color: red;
  border-radius: 50%;
  margin-right: 3px;
}
.courses-staream-status-text-s {
  color: green;
  background: aliceblue;
}
.courses-staream-status-text-e {
  color: red;
  background: aliceblue;
}
