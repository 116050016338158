.Account-Information-div-up {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Account-Information-text-up {
    font-size: 17px;
    font-weight: bold;
}

.profile-card {
    text-align: center;
    padding: 20px;
}

.profile-image {
    background-color: #3b4357;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
    border: 2px #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.name-up {
    font-size: 17px;
    font-weight: bold;
}

.email-up {
    font-size: 14px;
    color: gray;
}

.remove-pic-up {
    font-size: 12px;
    color: gray;
}

.Payment-Account-div-up {
    border: 1px solid rgb(245, 242, 242);
    padding: 15px;
}

.stripe-text-up-1 {
    font-size: 21px;
    font-weight: bold;
}

.stripe-text-up-2 {
    font-size: 16px;
}

.stripe-text-up-3 {
    font-size: 13px;
    color: grey;
}

.connect-btn-up {
    cursor: pointer;
    border-radius: 26px;
    padding-left: 16px;
    padding-right: 16px;
    color: orange !important;
    background-color: white !important;
    border: 2px solid rgb(245, 242, 242) !important;
}

.connect-btn-up span {
    color: orange !important;
    font-size: 15px;
    font-weight: bold;
}

.MuiTabs-scroller {
    overflow: auto !important;
}

.manage-subs-text {
    font-size: 17px;
    font-weight: bold;
}

.info-title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #424d6a;
}

.info-pare {
    font-size: 14px;
    color: #6d6d6d;
    text-transform: capitalize;
}

.manage-subs-card {
    padding: 10px;
}

.manage-subs-btn {
    float: right;
    color: white !important;
    background-color: orange;
    width: 18%;
    margin-top: 25px;
    border-radius: 15px;
}

.manage-subs-btn:hover {
    background-color: orange;
}

.buy-new-subscription {
    display: flex;
    align-items: center;
}

.buy-new-subscription-btn-cv4 {
    color: #4011C6 !important;
    background-color: transparent !important;
    border: 1px solid #4011C6;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: all 0.3s ease;
    /* Smooth transition for hover effect */
}

.buy-new-subscription-btn-cv4:hover {
    background-color: #cfced3;
    /* Darker shade for hover */
    box-shadow: 0px 4px 10px rgba(64, 17, 198, 0.5);
    /* Optional shadow effect */

}

.buy-new-subscription span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.no_of_kids_exceed_text {
    font-size: 14px;
    background: #e2cfcf;
    font-weight: 300;
    padding: 2px 2px 2px 2px;
    border-radius: 4px;
}

.manage-invite-btn {
    /* float: right; */
    color: white !important;
    background-color: orange;
    border-radius: 15px;
}

.manage-invite-btn:hover {
    background-color: orange;
}

.manage-invite-reject-btn {
    /* float: right; */
    color: white !important;
    background-color: red;
    border-radius: 15px;
}

.kid_count_bage_div {
    position: relative;
}

.kid_count_bage {
    top: -10px;
    right: -5px
}

.profile-picture-edit-icon-up {
    position: relative;
    left: -4px;
    font-size: 20px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}