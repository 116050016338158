
/* NOTES */
.get-notes-learner-main {
  flex-direction: column-reverse;
  display: flex;
  overflow-y: auto;
  height: 80%;
  position: absolute;
  width: 100%;
  top: 50px;
}

.note-item-learner {
  color: white;
  padding: 10px;
  border-radius: 15px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  word-wrap: break-word;
  position: relative;
}

.removeNotes-learner {
  float: right;
}

.close-icon-learner {
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
}

.notes-textarea-learner {
  border: none;
  background: #f8f9fa;
  width: 100%;
  /* width: 77vh; */
}

.notes-action-learner {
  height: 40px;
  /* background: red; */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #adb5bd;
}
.notes-color-learner {
  display: flex;
}
.notes-red-learner {
  height: 20px;
  width: 20px;
  background: #f15c5c;
  border-radius: 50%;
  margin-right: 6px;
}
.notes-blue-learner {
  height: 20px;
  width: 20px;
  background: #0dcaf0;
  border-radius: 50%;
  margin-right: 6px;
}
.notes-yellow-learner {
  height: 20px;
  width: 20px;
  background: #ffc107;
  border-radius: 50%;
  margin-right: 6px;
}
.notes-violet-learner {
  height: 20px;
  width: 20px;
  background: #9b79fb;
  border-radius: 50%;
  margin-right: 6px;
}
.add-note-v2-learner {
  font-weight: 700;
  cursor: pointer;
  color: #fff;
}

.notes-input-learner {
  position: absolute;
  bottom: 0;
  padding: 5px 15px 5px 15px;
  width: 100%;
}

/* COMMON */
.height-100 {
  height: 100%;
}

.text-color-white {
  color: #ffffff;
}

.border-radius-20 {
  border-radius: 20px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .mobile-screen {
    display: block;
  }
  .mobile-screen-portrait {
    display: block;
  }
  .mobile-screen-landscape {
    display: none;
  }
  .large-screen {
    display: none;
  }

  .learner-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 7px 0px 7px;
    width: 100%;
  }

  /* HEADER */
  .learner-class-room-header-height {
    align-items: center;
    height: 30px;
  }

  .learner-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .learner-class-room-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 17px;
    height: 17px;
  }

  .learner-class-room-bread-crumbs {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .learner-class-room-session-timing-v2 {
    width: 49.87px;
    height: 20.29px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 115px);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .mobile-screen {
    display: block;
  }
  .mobile-screen-portrait {
    display: none;
  }
  .mobile-screen-landscape {
    display: block;
  }
  .large-screen {
    display: none;
  }

  .learner-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 7px 0px 7px;
    width: 100%;
  }

  /* HEADER */
  .learner-class-room-header-height {
    align-items: center;
    height: 30px;
  }

  .learner-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .learner-class-room-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 17px;
    height: 17px;
  }

  .learner-class-room-bread-crumbs {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .learner-class-room-session-timing-v2 {
    width: 49.87px;
    height: 20.29px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 115px);
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .mobile-screen {
    display: block;
  }
  .mobile-screen-portrait {
    display: none;
  }
  .mobile-screen-landscape {
    display: block;
  }
  .large-screen {
    display: none;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .mobile-screen {
    display: none;
  }
  .large-screen {
    display: block;
  }

  .learner-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 20px 0px 20px;
    width: 100%;
  }

  /* HEADER */
  .learner-class-room-header-height {
    align-items: center;
    height: 60px;
  }

  .learner-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .learner-class-room-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 32px;
    height: 32px;
  }

  .learner-class-room-bread-crumbs {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
  }

  .learner-class-room-session-timing-v2 {
    width: 75.67px;
    height: 32px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 70px);
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .mobile-screen {
    display: none;
  }
  .large-screen {
    display: block;
  }

  .learner-class-room-container-v2 {
    background: linear-gradient(
      287.59deg,
      #591fff 0%,
      #39b8ff 57.58%,
      #4ba9ff 110.55%
    );
    height: 100%;
    overflow: hidden;
    padding: 0px 20px 0px 20px;
    width: 100%;
  }

  /* HEADER */
  .learner-class-room-header-height {
    align-items: center;
    height: 60px;
  }

  .learner-class-room-logo-container-v2 {
    align-items: center;
    display: flex;
  }

  .learner-class-room-short-logo {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 32px;
    height: 32px;
  }

  .learner-class-room-bread-crumbs {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }

  .text-color-active-breadcrumbs {
    color: #ede8e8;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
  }

  .learner-class-room-title-v2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
  }

  .learner-class-room-session-timing-v2 {
    width: 75.67px;
    height: 32px;
    background-color: #2b5d9a;
    border-radius: 36px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .learner-class-room-session-timing-v2 span {
    opacity: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  /* CONTENT */
  .learner-class-room-content-height {
    height: calc(100% - 70px);
  }
}

.cursor-pointer {
  cursor: pointer;
}
/* CONTENT */

.screen-resize {
  position: absolute;
  right: 10px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.5;
}

.screen-resize:hover {
  opacity: 1;
}
.learner-class-room-content-row {
  display: flex;
  height: calc(100% - 10px);
}

.learner-class-room-content-container-extended-v2 {
  border-radius: 20px;
  /* width: calc(100% - 554px); */
  width: 100%;
  position: relative;
}

.learner-class-room-content-container-v2 {
  border-radius: 20px;
  width: calc(100% - 170px);
  position: relative;
}

.learner-class-room-content-container-v2-fullscreen {
  border-radius: 20px;
  width: 100%;
  position: relative;
  overflow: auto;
}

.class_room_text_section-v2 {
  min-height: 100vh;
}

/* PARTICIPANTS - EXPERT */

.participants-column {
  overflow: auto;
  width: 160px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}

.participants-column-hidden {
  display: none;
}

.learner-class-room-expert-box-v2 {
  background: #474792;
  border-radius: 24px;
  height: 170px;
  width: 100%;
  /* width: 120px; */
  text-align: center;
  padding-top: 5px;
}

.expert-lable {
  color: #ffffff;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  position: relative;
  text-transform: uppercase;
  top: 5px;
}

.host-image-container-v2-transparent {
  background: transparent;
  border-radius: 32px;
  border: 2px solid transparent;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}
.host-image-container-v2-transparent canvas {
  border-radius: 20px;
  height: 90% !important;
  width: 100% !important;
}

.host-image-container-v2-transparent video {
  border-radius: 20px;
  height: 90% !important;
  width: 100% !important;
}

.host-image-container-v2 {
  background: #41464b;
  border-radius: 32px;
  border: 2px solid #ffffff;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.host-image-container-v2-audio-enable {
  border: 2px solid #11b511 !important;
}

.host-image-container-v2 canvas {
  border-radius: 2px;
  height: auto !important;
  width: 100% !important;
}

.host-image-container-v2 video {
  border-radius: 32px;
  height: 100% !important;
  width: 100% !important;
}

.host-container-v2 {
  position: relative;
  top: 8px;
  width: 110px;
  height: 105px;
  display: inline-block;
  text-align: center;
}

.host-container-expert-gallery-v2 {
  position: relative;
  top: 8px;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.h-90 {
  height: 90%;
}

.h-80 {
  height: 80%;
}

.learner-class-room-expert-container-v2 {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
}

.host-rounded-video-off-v2 {
  padding: 5px;
}

.host-rounded-video-off-v2-audio-enable {
  border-radius: 50px;
  border: 1.5px solid #11b511;
}

.host-rounded-video-off-v2-audio-disable {
  border-radius: none;
}

.host-rounded-video-off-div-v2 {
  justify-content: center;
  align-content: center;
  display: flex;
  top: 40%;
  position: relative;
}

.host-video-off-initial-v2 {
  background: #fff;
  padding: 5px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
}

.host-video-off-initial-v2-in-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.host-label-v2 {
  color: #ffffff;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.participant-item-card-footer-icon-v2 {
  bottom: 10px;
  left: 10px;
  position: absolute;
  width: 28px;
}

/* PARTICIPANTS - LEARNERS LIST */
.learners-list-box-v2 {
  text-align: center;
  display: inline-block;
  /* position: relative;
  left: 20px; */
}

.learners-list-containter-v2 {
  margin-top: 12px;
}

/* FOOTER CONTROLS */

.clicked-raised-hand {
  background: #213d5e !important;
}

.general-notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  background: rgb(23, 211, 23);
  border-radius: 50%;
}
.footer-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 17px;
}

.controls-container {
  display: flex;
  justify-content: center;
}

.l-class-control-box {
  color: #fff;
  position: relative;
  background-color: #2b5d9a;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-right: 16px;
}

.control-red-bg {
  background-color: #f34444 !important;
}

.footer-control-icons-v2 {
  color: #ffffff;
  font-size: 24px;
}

.page-menu-item-img {
  width: 24px;
}

/* RIGHT SIDE NAV BAR */
.right-sidebar {
  width: 394px;
  position: absolute;
  top: 54px;
  right: 10px;
  height: calc(100% - 60px);
}

.right-sidebar-container {
  background-color: #000000;
  border-radius: 24px;
  width: 394px;
  height: 100%;
  opacity: 75%;
}

.right-sidebar-heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.right-sidebar-heading span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}

.right-sidebar-body {
  height: calc(100% - 172px);
  overflow: auto;
  position: relative;
}

/* CHAT */
.chat-msg-list-v2 {
  flex-direction: column-reverse;
  display: flex;
  overflow-y: auto;
  height: 100%;
}

.chat-box-container-v2 {
  position: absolute;
  bottom: 0;
  padding: 5px 15px 5px 15px;
  width: 100%;
}

.send-input-box-v2 {
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}

.input-group-append {
  margin-right: 20px;
}

.send-screenshot-v2 {
  cursor: pointer;
}

.send-input-v2 {
  font-size: 16px;
  margin-right: 5px;
  border-radius: 16px;
}

.leave-session-warn {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  /* grey90 */
  color: #3b4357;
}

.confirm-no-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 48px;
  text-align: center;
  /* White */
  background: #ffffff;
  /* grey40 */
  border: 1px solid #d4d9df;
  border-radius: 12px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  /* grey90 */
  color: #3b4357;
}

.confirm-yes-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 48px;
  text-align: center;
  background: #ea3323;
  border-radius: 12px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #ffffff;
}

/* CHAT */
.chat-my-msg-main-v2 {
  padding: 5px 15px 5px 15px;
}

.chat-my-msg-container-v2 {
  width: 80%;
  float: right;
  background-color: #13b8ff;
  padding: 8px 10px 8px 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.my-name-container-v2 {
  text-align: right;
}
.my-name-v2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.my-msg-container {
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #3b4357;
}

.chat-rvr-msg-container-v2 {
  float: left;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
  padding: 10px 10px 10px 10px;
  width: 80%;
}

.chat-rvr-name-v2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.chat-rvr-msg-v2 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  word-wrap: anywhere;
}

.rvr-container-v2 {
  display: flex;
  align-items: center;
  position: relative;
}
.rvr-name-v2 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.rvr-msg-container-v2 {
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  color: #fff;
  word-wrap: anywhere;
}

.rvr-avatar-container {
  background: #084159 !important;
  width: 40px;
  height: 40px;
  margin-right: 6px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.rvr-msg-container-div {
  margin-left: 46px;
}
.send-input-box {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.send-input {
  font-size: 16px;
}

.chat-send-btn {
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  background: #13b8ff !important;
  color: #fff !important;
  border: none !important;
}

.chat-send-btn:hover {
  background: #13b8ff !important;
  color: #fff !important;
}

.chat-send-btn:focus {
  box-shadow: none !important;
}

.chat-send-btn img {
  width: 16px;
  margin-right: 5px;
}

.emoji-menu {
  top: -185px !important;
}

.learner-user-name-container {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.learner-user-name-icon {
  width: 30px;
  height: 30px;
  background-color: #8eb3df;
  border-radius: 36px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #1e0e0e;
  overflow: hidden;
}

/** GALLERY VIEW */
.learner-gallery-view-row {
  /* //height: calc(100% - 22%); */
  height: 100%;
  position: relative;
  overflow: auto;
}

.learner-gallery-height {
  height: calc(100% - 50%) !important;
}

.learner-gallery-height-col-2 {
  height: calc(100% - 50%) !important;
}

/* QUIZ VIEW */
.quiz-container-v2 {
  max-width: 100%;
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 60px;
  height: 100%;
  overflow: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-container-v2 .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #800080;
}

.quiz-container-v2 .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container-v2 h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.quiz-container-v2 ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container-v2 ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}

.quiz-container-v2 ul .selected-answer {
  background: #ffd6ff;
  border: 1px solid #800080;
}

.quiz-container-v2 button {
  background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-back-btn {
  background: #fff;
  border-radius: 9px;
  font-size: 18px;
  color: #000;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  border: 1px solid;
  cursor: pointer;
}

.retry-quiz-btn {
  background: #fff;
  border-radius: 9px;
  font-size: 18px;
  color: #000;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  border: 1px solid;
  cursor: pointer;
}
.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}
.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}

.result p {
  font-size: 16px;
  font-weight: 500;
}

.result p span {
  color: #800080;
  font-size: 22px;
}

/* #learner-participant-videos-canvas-vertical-view{
  width: 130px;
  height: 60%;
  position: absolute;
  z-index: 1;
  border: 1px solid #000;
}

#learner-participant-videos-canvas-vertical-view-card{
  width: 130px;
  height: 60%;
  position: absolute;
} */

.learner-participants-canvas-main-div {
  height: calc(100% - 220px);
  position: relative;
}

.learner-participants-canvas-main-div-left-adjustment {
  left: -70px;
}

.learner-participants-prev-videos-div {
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.learner-participants-next-videos-div {
  /* position: absolute;
  bottom: 0; */
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

#learner-participant-videos-canvas-vertical-view {
  position: absolute;
  z-index: 1;
  height: auto;
  /* transform: scaleX(1)!important; */
}

#learner-participant-videos-canvas-vertical-view-card {
  transform: scaleX(1) !important;
  position: absolute;
}

.learner-participants-pagination-main {
  position: absolute;
  bottom: 60px;
  width: 160px;
}

/* INFORMATION BOX */
.info-box-label{
  font-size: 14px;
  font-weight: 500;
  padding-right: 5px;
}

.info-box-text{
  font-size: 15px;
  font-weight: 600;
}