.my_kids_btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 10px;
  width: 120px;
}

.table_header {
  background-color: rgba(0, 0, 0, 0.731) !important;
  padding: 0 !important;
}
.table_header_text {
  color: white !important;
}
.my_kids_text {
  font-family: "Inter Tight Medium";
  font-size: 24px;
  color: #3b4357;
  font-weight: 700;
}
.search_course_name {
  border: 1px solid grey;
  padding: 4px;
}

.name_title_text {
  font-size: 12px;
  font-weight: normal;
  color: #778190;
}
.name_text {
  color: #3b4357;
  font-size: 16px;
}

#my_kids_card {
  padding: 13px;
  margin-right: 3px;

  border-radius: 6px;
  border: solid 2px #f1f3f6;
  background-color: #fff;
}
.my_kids_dot_icon {
  margin-left: 20px;
  color: #778190;
}
.kids_course {
  color: #778190;
  font-size: 14px;
}
.kids_name_in_parent {
  cursor: pointer;
  color: #3b4357;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  top: 0px;
}
.kids_text_section_in_parent {
  margin-left: 4px;
}

.payment_table_header {
  background-color: #3b4357;
}
.payment_table_header_text {
  color: white !important;
}

.list-group-item {
  float: left;
  list-style-type: none;
}

.nav-link {
  border: none;
  outline: none;

  cursor: pointer;
}
/* popup */

.add_kids_close_icon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
}
.my_kids_save_btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  margin-right: 10px;
}
.upload_section {
  position: relative;
  text-align: center;
}
.mykids_upload_text {
  font-size: 14px;
}
.cinemography_text {
  font-weight: 600;
  margin-top: 5px;
}
.section_text_label {
  font-size: 14px;
  color: #778190;
}
.complete_text_cor_inf {
  color: green;
  font-size: 13px;
  font-weight: 600;
}
.section_date {
  font-weight: 600;
  color: #778190;
}
.missed_text_cor_inf {
  color: red;
  font-size: 13px;
  font-weight: 600;
}
.badge_mykids {
  margin-left: 15px;
}

.horizontal-list.list-group-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.my-kids-list-myk {
  overflow: auto;
}
.active-my-kid {
  border: 2px solid #591fff !important;
  border-radius: 5px !important;
}

.kid-detail-main {
  padding: 15px 15px 15px 15px;
  background: #ecdfdf;
  border-radius: 6px;
}

.kid-detail-label {
  font-size: 14px !important;
  font-weight: 400;
}

.kid-detail-value {
  font-size: 14px !important;
  font-weight: 600;
}

.kid-detail-empty-value {
  margin-left: 21px;
}
.remove-pic-up-kid {
  font-size: 12px;
  color: gray;
  cursor: pointer;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.error-meg-for-img-size {
  color: red;
}
.add-pic-up-kid {
  color: gray;
  font-size: 14px;
}
.remove-pic-up-kid:hover {
  color: red; /* Change text color to red on hover */
}

.remove-pic-up-kid:hover svg {
  fill: red; /* Change icon color to red on hover */
}
