.free-trial-label {
  font-size: 12px;
  background: orange;
  padding: 2px 5px 5px 2px;
  border-radius: 5px;
  font-weight: 600;
}

.self-learning-main-container {
  height: 100%;
}

.solo-player-text {
  font-size: 10px;
  position: absolute;
  background: #d1d157;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.dim-opacity {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.locked-icon-div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.share-code-btn {
  float: right;
  padding: 5px 5px 5px 5px;
  background: #aeaef2;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.share-code-btn:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.share-code-div-cgv4 {
  color: white;
  box-shadow: 0px 0px 20px 0px #7C7C7C inset;
  font-size: 13px;
  font-weight: 200;
  border-radius: 25px;
  cursor: text;
  font-family: 'Poppins Regular';
  padding: 8px 12px 8px 12px;
  position: absolute;
  /* top: 210px; */
}

.share-code-text-in-detail {
  padding: 5px 5px 5px 5px;
  background: #aeaef2;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  cursor: text;
  text-align: center;
}

.self-learning-w-close {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
}

.self-learning-whiteboard-iframe-gsp {
  width: 100%;
  height: 87%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid black;
}

.self-learning-empty-board {
  text-align: center;
  height: 87%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border: 1px solid black;
}

.share-code-start-game-btn {
  background-color: #f07820 !important;
  color: white !important;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  margin-right: 10px;
}



.players-status-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: green;
  margin-top: 4px;
}

.chat-floating-icon {
  position: absolute;
  bottom: 25px;
  right: 25px;
  background: #cec9f0 !important;
  color: #9165f5;
  border-radius: 50%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition for hover effect */
}

.chat-floating-icon:hover {
  transform: scale(1.1);
  /* Scale up the button */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  /* Add a subtle shadow */
  background: #87cefa;
  /* Optional: Change background color on hover */
}

.my-friends-list-main-div {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 2;
  width: 100%;
  /* background: black; */
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  /* padding: 10px 10px 10px 10px; */
  overflow: hidden;
  border-top: 1px solid #D4D9DF;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
}

.my-friends-list-inner-div-cgv4 {
  height: 75%;
  width: 27%;
  background: #fff;
  position: fixed;
  right: 0;
  padding: 10px 10px 50px 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  .my-friends-list-inner-div-cgv4 {
    width: 95%;
    height: 100%;
  }
}




.my-friends-title {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins Regular';
}

.my-friends-list-ul {
  padding: 10px 0px 10px 0px;
  cursor: pointer;
}

.my-friends-list-ul:hover {
  background-color: #f1ecec;
  border-radius: 8px;
}

.my-friends-name {
  font-size: 14px;
  font-family: 'Poppins Regular';
}

.my-friends-close-btn {
  position: absolute;
  right: 0;
}

.my-friends-chat-text-box-main-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 10px 10px 4px 10px;
}

.individual-chats-main-div-cgv4 {
  height: calc(100% - 145px);
  overflow-y: auto;
  /* border: 1px solid #ccc; */
  padding: 10px;
  flex-direction: column-reverse;
  display: flex;
  /* background: #e6f5e6; */
}

.group-chats-main-div-cgv4 {
  height: calc(100% - 0px);
  overflow-y: auto;
  /* border: 1px solid #ccc; */
  padding: 10px;
  flex-direction: column-reverse;
  display: flex;
  /* background: #e6f5e6; */
}

.my-name-container-ind-chat {
  text-align: right;
}

.my-name-ind-chat-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 11px;
  font-weight: 400;
  color: #C60303;
}

.chat-my-msg-container-ind-chat-cgv4 {
  width: 80%;
  float: right;
  background: #E7E5FF;
  padding: 5px 10px 5px 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 40px;
  color: #000000;
}


.rvr-name-ind-chat-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 16px;
  font-weight: 400;
  color: black;
}

.rvr-container-ind-chat {
  display: flex;
  align-items: center;
  position: relative;
}

.my-friend-avatar-ind-chat {
  font-family: 'Poppins Regular';
  background: #084159 !important;
  width: 40px;
  height: 40px;
  margin-right: 6px;
  margin-left: 6px;
  border-radius: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.rvr-avatar-container-ind-chat {
  background: #084159 !important;
  width: 40px;
  height: 40px;
  margin-right: 6px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.rvr-msg-container-div-ind-chat {
  margin-left: 46px;
}

.chat-rvr-name-ind-chat-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 11px;
  font-weight: 400;
  color: #EBFF00;

}

.chat-rvr-msg-container-ind-chat-cgv4 {
  float: left;
  background: #7A6FEC;
  border-radius: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  padding: 5px 10px 5px 25px;
  width: 80%;
}

.rvr-msg-container-ind-chat-cgv4 {
  /* padding: 10px 10px 10px 10px; */
  border-radius: 8px;
  color: #fff;
  word-wrap: anywhere;
}

.chat-rvr-msg-ind-chat-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 16px;
  font-weight: 400;
  color: white;
  word-wrap: anywhere;
}

#ind-chat-input-id {
  padding: 10px 10px 10px 10px;
}

.ind-chat-input-cgv4 {
  width: 85%;
  height: auto;
  border-radius: 30px;
  border: 0.8px solid #4011C6;
  font-size: 13px;
  font-family: 'Poppins Regular';
}

.ind-chat-send-btn {
  width: 15%;
  height: 45px;
}

.border-bottom-style {
  border-bottom: 1px solid #D4D9DF;
  margin-bottom: 5px;
}

.my-friends-no-records-found {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.player-master-cgv4 {
  font-family: 'Poppins Regular';
  color: #9d9797;
  font-size: 12px;
  font-weight: 400;
}

.my-friends-count-badge {
  background: #fff;
  color: #000;
  width: 20px;
  border-radius: 30px;
  margin-left: 5px;
  font-size: 12px;
}

/* courses v4 */
.missions_game_card_cv4 {
  padding: 0px;
  border-radius: 22px;
}

.missions_game_card_img_cv4 {
  padding: 3px;
}

.missions_game_card_content {
  padding: 12px;
}

.missions_game_card_title {
  font-family: 'Pompiere-Regular';
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}

.start-for-free-cv4 {
  font-family: 'Poppins Regular';
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  border-radius: 44px;
  font-weight: 500;
  border: none;
  width: 100%;
  background: linear-gradient(180deg, #4011C6 0%, #3D12B7 100%);
}

.game_card_img_cv4 {
  border-radius: 22px;
}

.missions-text-title-cgv4 {
  font-family: 'Pompiere-Regular';
  font-size: 30px;
  font-weight: 600;
  line-height: 52.87px;
  text-align: left;
  color: #1e0e81;
}

.Join-with-code-text-cgv4 {
  font-family: 'Pompiere-Regular';
  font-size: 25px;
  font-weight: 600;
  text-align: left;
  color: #1e0e81;
  text-transform: uppercase;
}

.close_icon_cgv4 {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 12px;
  cursor: pointer;
}



.Leader-board-btn-cgv4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins Regular';
  font-size: 16px;
  border-radius: 44px;
  font-weight: 500;
  padding-left: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  color: white;
  border: none;
  background: linear-gradient(180deg, #4011C6 0%, #3D12B7 100%);
}

.Join-with-code-btn-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 16px;
  color: #4011C6;
  border-radius: 44px;
  font-weight: 500;
  background-color: white;
  border: 0.8px solid #4011C6;
  padding: 8px;
}

.success-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.success-image-overlay img {
  width: 200px;
  /* Adjust the size as needed */
}

/* CSS */
.image-container-cgv4 {
  position: relative;
  width: 100%;
}

.join_with_code_img_cgv4 {
  display: block;
  width: 100%;
}

.join_with_code_input_cgv4 {
  position: absolute;
  top: 21%;
  left: 51%;
  transform: translate(-50%, -50%);
  width: 87%;
  /* padding: 10px; */
}

.text-align-center {
  text-align: center;
}

.game-card-cgv4 {
  background: #AD2315;
  border-radius: 15px;
  padding: 4px;
}

.background-image-container-cgv4 {
  background-image: url('../../../../assets/img/courses_img_v4/game_card_img.png');
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  color: white;
}

.share-code-text-cgv4 {
  /* min-width: 520px; */
  margin-top: 20px;
  margin-bottom: 30px;

}

.player-text-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 20px;
  font-weight: 600;
  color: #FFE500;
}

.no-players-text-cgv4 {
  font-family: 'Poppins Regular';
  color: white;
  font-size: 15px;
}

.player-name-text-cgv4 {
  font-family: 'Poppins Regular';
  color: white;
  font-size: 14px;
}

.start-game-cgv4 {
  font-family: 'Poppins Regular';
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  border-radius: 44px;
  font-weight: 500;
  border: none;
  background: linear-gradient(180deg, #4011C6 0%, #3D12B7 100%);
  padding-left: 12px;
  line-height: 13px;
}

.Description-btn-cgv4 {
  font-family: 'Poppins Regular';
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4011C6 !important;
  border-radius: 44px;
  font-weight: 500;
  font-size: 13px;
  border: none;
  background: white;
  padding-left: 12px;
  line-height: 13px;
}

.join-with-code-btn-cgv4-1 {
  background-color: transparent !important;
  border-radius: 7px;
  padding: 4px 10px 4px 10px;
  /* margin-right: 10px; */
  border: 1px solid #4011C6;
  color: #4011C6;
  font-weight: 400;
  font-family: 'Poppins Regular';
}


.copy-code-text-cgv4 {
  font-size: 10px;
  color: #D8CBFC;
  margin-left: 15px;
  /* position: absolute;
    top: 246px;
    right: 28px; */
  position: absolute;
  bottom: 113px;
  /* right: 40px; */
}

/* Modal.css */
.modal-overlay-cgv4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content-cgv4 {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.modal-actions-cgv4 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.modal-actions-cgv4 button {
  padding: 10px 20px;
  cursor: pointer;
}

.are_you_sure_text_cgv4 {
  font-family: 'Poppins Regular';
  font-size: 17px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: center;
  color: #000000;
}

.No-btn-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 16px;
  color: #4011C6;
  border-radius: 44px;
  font-weight: 500;
  background-color: white;
  border: 0.8px solid #4011C6;
  padding: 6px 30px 6px 30px;

}

.Yes-btn-cgv4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins Regular';
  font-size: 16px;
  border-radius: 44px;
  font-weight: 500;
  padding: 0px 0px 0px 25px;
  color: white;
  border: none;
  background: linear-gradient(180deg, #4011C6 0%, #3D12B7 100%);
}

.my-friends-invite-title-cvg4 {
  font-family: 'Pompiere-Regular';
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #4011C6;
}


.content-cgv4 {
  height: 48px;
  /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2); */
  border-radius: 60px;
  overflow: hidden;
  border: 0.5px solid #4011C6A8;

}

.subscription-cgv4 {
  position: relative;
  width: 100%;
  height: 100%;
}

.subscription-cgv4 .add-email-cgv4 {
  font-family: 'Poppins Regular';
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 20px;
  color: #5B4A8A !important;
  background: #FAF8FF;
}

.subscription-cgv4 .submit-email-cgv4 {
  font-family: 'Poppins Regular';
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 2px);
  width: 100px;
  border: none;
  border-radius: 60px;
  outline: none;
  margin: 1px;
  padding: 0 20px;
  cursor: pointer;
  background: #4316c3;
  color: #ffffff;
  transition: width 0.35s ease-in-out, background 0.35s ease-in-out;
  font-size: 14px;
}

.subscription-cgv4.done .submit-email-cgv4 {
  width: calc(100% - 2px);
  background: #c0e02e;
}

.subscription-cgv4 .submit-email-cgv4 .before-submit-cgv4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 38px;
  transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .subscription-cgv4.done .submit-email-cgv4 .before-submit-cgv4 {
  visibility: hidden;
  opacity: 0;
} */

.subscription-cgv4 .submit-email-cgv4 {
  transition-delay: 0.35s;
}

/* .subscription-cgv4:not(.done) .submit-email-cgv4 .before-submit-cgv4 {
  visibility: visible;
  opacity: 1;
} */

.accept-btn-cgv4 {
  background: #4011C6;
  font-family: 'Poppins Regular';
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  padding: 5px 10px 5px 10px;
  border: none;
  border-radius: 20px;
}

.reject-btn-cgv4 {
  background: #EAE6F6;
  font-family: 'Poppins Regular';
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding: 5px 10px 5px 10px;
  border: none;
  border-radius: 20px;
}

.close_icon_cgv4_1 {
  position: absolute;
  right: 7px;
  font-size: 12px;
  cursor: pointer;
}


.my-friends-list-main-div-message {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 2;
  width: 100%;
  /* background: black; */
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  /* padding: 10px 10px 10px 10px; */
  overflow: hidden;
  border-top: 1px solid #D4D9DF;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
}

.my-friends-list-inner-div-message-cgv4 {
  height: 75%;
  width: 27%;
  background: #fff;
  position: fixed;
  right: 0;
  padding: 0px 0px 50px 0px;
  border-radius: 30px;
  border: 2px solid blue;
}

@media only screen and (max-width: 768px) {
  .my-friends-list-inner-div-message-cgv4 {
    width: 95%;
    height: 100%;
  }
}

.background-color-blue-cgv4 {
  background: #7A6FEC;
  height: 41px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.icon-blue-border-cgv4 {
  border-radius: 50px;
  border: 0.8px solid #4011C6;
  padding: 10px;
  cursor: pointer;
  margin-right: 3px;
}

.text-clips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: 'Poppins Regular';
}

.text-clip-button {
  background-color: #4011C6;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 1px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.text-clip-button:hover {
  background-color: #341094;
}

.text-clip-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.don-t-share-personal-text {
  color: red;
  font-size: 12px;
  margin-left: 33px;
}

@media (max-width: 768px) {
  .hide-on-mobile-view-cgv4 {
    display: none;
  }

  .start-game-cgv4 {
    padding: 7px;
  }
}

.message-limited-text-cgv4 {
  color: red;
  font-size: 12px;
}

.comimg_soon_active_st_btn {
  background-color: red;
  font-weight: 600;
  font-family: 'Poppins Regular';
  width: 100%;
  padding: 10px;
  color: white;
  position: absolute;
  bottom: 0;
}

.comimg_soon_trialing_st_btn {
  font-family: 'Poppins Regular';
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  border-radius: 44px;
  font-weight: 500;
  border: none;
  width: 100%;
  background: red;
}

.unread_message_div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e0e81;
  color: white;
  border-radius: 30px;
  padding: 15px;
  height: 20px;
  width: 20px;
  margin-right: 7px;
}

.unread_message_count_text {
  font-size: 12px;
  font-family: 'Poppins Regular';
}

.join-with-code-error-msg {
  font-family: 'Poppins Regular';
}

.shar-this-code-text-cgv4 {
  font-size: 14px;
  color: #ff9900;
  font-family: "Poppins Regular";
}

.people-you-know-text-cgv4 {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins Regular";
  color: #1e0e81;
}

.people-you-know-text-cgv4 {
  animation: fadeSlideIn 1s ease-in-out;
}

@keyframes fadeSlideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .shar-this-code-text-cgv4 {
  animation: fadeSlideIn 1s ease-in-out;
} */



/* helper css */

/* helper-btn */

.helper_btn_cgv4 {
  position: absolute;
  left: 25px;
  bottom: 25px;
}

.helper_btn_cgv4 {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #cec9f0;
  color: #9165f5;
}

.helper_btn_cgv4:hover {

  /* Darker blue on hover */
  transform: scale(1.1);
  /* Slight zoom effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Bigger shadow */
}

.helper_btn_cgv4 {
  transition: transform 0.3s ease-in-out;
}

.helper_btn_cgv4:hover {
  transform: rotate(360deg);

}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-10px);
  }

  40% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-5px);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.helper_btn_cgv4 {
  animation: bounce 1s ease-in-out;
}

/* helper-btn end */

.backdrop-cgv4 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1110;
}

.modal-content-for-cgv4 {
  /* font-family: 'Pompiere-Regular' !important; */
  background: white;
  border: 3px solid #4011C6;
  /* padding: 10px; */
  border-radius: 10px;
  /* text-align: center; */
  width: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1;
  font-family: 'Poppins Regular' !important;
}



/* Styling for Circular Buttons */

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  font-family: 'Poppins Regular';
}

/* Styling for Circular Buttons */

/* .circle-button {
  width: 100px;
  height: 100px;
  background: conic-gradient(from 0deg, #007bff, #73ede8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 3px;
  transition: all 0.4s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  line-height: 1.2;
} */

/* Hover Effects */
/* .circle-button:hover {
  background: conic-gradient(from 90deg, #73ede8, #007bff);
  transform: scale(1.2);
  box-shadow: 0 10px 20px rgba(0, 123, 255, 0.5);
} */

/* Active Button Styling */

/* .active-circle-button {
  background: conic-gradient(from 45deg, #0056b3, #00274d);
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 123, 255, 0.6);
} */

/* Styling for Circular Buttons end */
.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin: 20px;
}

.circle-button-for-helper {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circle-button-for-helper:hover,
.circle-button-for-helper.active-circle-button {
  transform: scale(1.1);
}

.circle-for-helper {
  width: 70px;
  height: 70px;
  background: linear-gradient(270deg, #7E61F1 0%, #1E74D1 100%);
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.circle-button-for-helper:hover .circle-for-helper,
.circle-button-for-helper.active-circle-button .circle-for-helper {
  background: linear-gradient(135deg, #73ede8, #007bff);
  box-shadow: 0 6px 12px rgba(0, 123, 255, 0.5);
}

.rect-button {
  background: #FFFFFF;
  color: #4011C6;
  font-size: 14px;
  text-align: center;
  border: 0.8px solid #4011C6;
  /* border: 2px solid #007bff; */
  border-radius: 30px;
  padding: 10px 20px;
  margin-left: -45px;
  /* Creates the overlap effect */
  position: relative;
  z-index: 1;
  /* Keeps it above the circle */
  transition: color 0.3s ease, border-color 0.3s ease;
}

.circle-button-for-helper:hover .rect-button,
.circle-button-for-helper.active-circle-button .rect-button {
  color: #fff;
  background: linear-gradient(270deg, #7E61F1 0%, #1E74D1 100%);
  border-color: #73ede8;
}

/* Styling for the content container */
.content-container {
  border: 1px solid #ccc;
  padding: 16px;
  background-color: #0c0c0c;
  border-radius: 8px;
}


/* close-button-for-helper */

.close_icon_for_helper_cgv4 {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  z-index: 1000;
  /* Ensure it appears on top */
  transition: transform 0.3s ease, rotate 0.3s ease;
  /* Smooth hover transition */
}

.close_icon_for_helper_cgv4:hover img {
  transform: scale(1.1);
  /* Slightly enlarge the icon on hover */
}


/* close-button-for-helper end*/

/* helper-content-cgv4-cat-img-div */

/* .helper-content-cgv4-cat-img-div {
  position: absolute;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: -1;
}

.helper-content-cgv4-cat-img-div .login_cat {
  max-width: 100px;
  height: auto;
 
  transition: transform 0.3s ease-in-out;
} */


/* helper-content-cgv4-cat-img-div END*/






/* General styles for the animated grid */
.helper-content-cgv4 {
  /* position: relative; */
  width: 100%;
  height: 300px;
  /* Adjust as needed */
  /* overflow: hidden; */
  /* background-color: #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.helper-content-container-cgv4 {
  position: relative;
}

.login_cat_img_for_cgv4 {
  max-width: 100px;
  position: absolute;
  z-index: 1;
  right: -100px;
  bottom: -20px;
}

.animated-slide {
  animation: zoomRotate 1s ease-in-out;
}

@keyframes zoomRotate {
  0% {
    transform: scale(0.9) rotate(0deg);
    opacity: 0.8;
  }

  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}



/* Custom styles */
.carousel-slide {
  padding: 10px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.robot-name {
  font-size: 20px;
  font-weight: 900;
  color: #1e0e81;
}

.robot-description {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  color: rgb(6 6 6);
  line-height: 1.5;
  font-weight: 700;
}


/* Styling the main container */
.__floater.__floater__open {
  display: inline-block;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  max-width: 100%;
  opacity: 1;
  position: absolute;
  z-index: 9999 !important;
  padding: 16px 0;
  transform: translate3d(628px, 493px, 0px);
  top: 0;
  left: 0;
  transition: opacity 0.3s ease, transform 0.2s ease;
}

/* Styling the tooltip */
.react-joyride__tooltip {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  color: #333;
  font-size: 16px;
  padding: 20px;
  width: 400px;
}

/* Centering the text in the tooltip */
.react-joyride__tooltip div {
  line-height: 1.5;
  text-align: center;
}

/* Styling the buttons */
.react-joyride__tooltip button[data-action="skip"] {
  background-color: transparent;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
}

.react-joyride__tooltip button[data-action="primary"] {
  background-color: #ff0044;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
}

/* Styling the close button */
.react-joyride__tooltip button[data-action="close"] {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Styling the arrow */
.__floater__arrow span svg polygon {
  fill: #f9f9f9;
}

.tour-btn-div-cgv4 {
  position: absolute;
  top: 7px;
  right: 45px;
}

.login-as-parent-title {
  font-size: 24px !important;
  font-family: "Poppins Regular";
  color: #1e0e81;
}

.login-as-parent-content {
  font-size: 17px;
  font-family: "Poppins Regular";
  color: #1e0e81;
}

.login-as-parent-cancel-btn {
  color: #1e0e81 !important;
  border-radius: 44px;
  font-weight: 500;
  border: none;
  background: lightgray;
  padding: 5px 20px;
  font-size: 16px;
  font-family: "Poppins Regular";
}

.login-as-parent-btn {
  color: white !important;
  border-radius: 44px;
  font-weight: 500;
  border: none;
  background: linear-gradient(180deg, #4011C6 0%, #3D12B7 100%);
  padding: 5px 20px;
  font-size: 16px;
  font-family: "Poppins Regular";
}