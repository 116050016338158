.title_navbar {
  background-color: white;
  padding: 10px;
}
.planning_text {
  font-size: 20px;
  margin-left: 10px;
  font-weight: 600;
  cursor: pointer;
}
.Team_close_icon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  top: 25px;
}
/* popup */
.our_mission_text {
  font-size: 16px;
  color: #778190;
}
.our_mission_text_box {
  height: 230px;
  padding: 10px;

  border-radius: 9px;
  background-color: #f1f3f6;
}
.team_members_name{
   
    margin-left: 10px;
}
.drag_section{
    background-color: aqua;
}
.team_wolf_title{
    font-size: 32px;
    font-weight: 600;
}
.add_task_title{
  font-size: 23px;
  font-weight: 600;
}
.add_task_close_icon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  top: 21px;
}
.task__description{
  font-weight: lighter;
  font-size: 13px;
}
.task__title{
  font-weight: bold;
}
ul#event-list li {
  display: inline;
}
.hor_lu_list{
  display: flex;
  list-style-type: none;
}

.task_delete_icon{
  cursor: pointer;
    display: flex;
    justify-content: end;
    width: 100%;
}

.whiteboard-iframe{
  width: 100%;
  height: 75vh;
}


/** WHITEBOARD TABS **/
.whiteboard-tabs-breakout-room-expert{
  background-color: #d6dbde;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.whiteboard-tab-container-breakout-room-expert{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 1px 0px 1px;

  flex: 1;
}

.whiteboard-tab-button-breakout-room-expert{
  align-items: center;
  color: #3b4357;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  width: 100%;
}

.whiteboard-tab-name-breakout-room-expert{
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-width 0.3s ease;
}

.whiteboard-tab-name-breakout-room-expert:hover{
  color: #5901e6;
}

.whiteboard-tabs{
  background-color: #d6dbde;
  display: flex;
}

.whiteboard-tab-container{
  cursor: pointer;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 7px;
  align-items: center;
  background-color: #5901e6;
}

.whiteboard-in-active{
  background-color: #f1f3f6;
}

.whiteboard-active{
  background-color: #fff;
}

.whiteboard-tab-button{
  align-items: center;
  color: #3b4357;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 15px 15px;
  width: 330px;
}

.whiteboard-tab-name:hover{
  color: #5901e6;
}

.whiteboard-tab-controls{
  display: flex;
  align-items: center;
}

.whiteboard-tab-controls span{
  margin-right: 8px;
  margin-left: 8px;
  cursor: pointer;
}

.whiteboard-add-tab{
  color: #fff;
  width: 50px;
text-align: center;
display: flex;
justify-content: center;
align-content: center;
}

.whiteboard-locked-morph-div{
  width: 100%;
  height: 75vh;
  position: absolute;
}