.payment-main-div {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripe-btn {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.payment-col-left {
  display: flex;
  justify-content: end;
}

.payment-col-right {
  display: flex;
  justify-content: start;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* RESPONSIVE SCREENS */
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {

  #payment-form {
    width: 100%;
    align-self: center;
    border-radius: 7px;
    padding: 20px;
  }

  .payment-form-container {
    display: block;
    width: 100%;
  }

  .checkout-card {
    display: grid;
    justify-content: center;
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  .payment-left-main {
    width: 100%;
    align-self: end;
  }

  .payment-col-left {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .payment-col-right {
    display: flex;
    justify-content: center;
  }

  .payment-course-name {
    color: rgb(111 104 104);
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    margin-left: 0px !important;
    text-transform: capitalize;
  }

  .payment-full-amount {
    color: #000000;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px !important;
    text-transform: capitalize;
  }

  .checkout-sessions-main {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 0px !important;
  }

  .checkout-session-img {
    width: 14px;
  }

  .checkout-session-detail {
    font-size: 12px;
    font-weight: 400;
    padding-left: 4px;
  }

  .checkout-session-amount {
    color: #000 !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .checkout-total-border {
    margin-left: 0px !important;
    color: #c9bfbf;
    border: 0;
    border-top: 1px solid;
  }

  .checkout-session-total {
    color: #000 !important;
    font-size: 12px;
    width: 75%;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

  #payment-form {
    width: 100%;
    align-self: center;
    border-radius: 7px;
    padding: 20px;
  }

  .payment-form-container {
    display: block;
    width: 100%;
  }

  .checkout-card {
    display: grid;
    justify-content: center;
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  .payment-left-main {
    width: 100%;
    align-self: end;
  }

  .payment-col-left {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .payment-col-right {
    display: flex;
    justify-content: center;
  }

  .payment-course-name {
    color: rgb(111 104 104);
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    margin-left: 0px !important;
    text-transform: capitalize;
  }

  .payment-full-amount {
    color: #000000;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px !important;
    text-transform: capitalize;
  }

  .checkout-sessions-main {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 0px !important;
  }

  .checkout-session-img {
    width: 14px;
  }

  .checkout-session-detail {
    font-size: 12px;
    font-weight: 400;
    padding-left: 4px;
  }

  .checkout-session-amount {
    color: #000 !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .checkout-total-border {
    margin-left: 0px !important;
    color: #c9bfbf;
    border: 0;
    border-top: 1px solid;
  }

  .checkout-session-total {
    color: #000 !important;
    font-size: 12px;
    width: 75%;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

  #payment-form {
    width: 100%;
    align-self: center;
    border-radius: 7px;
    padding: 20px;
  }

  .payment-form-container {
    display: block;
    width: 100%;
  }

  .checkout-card {
    display: grid;
    justify-content: center;
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  .payment-left-main {
    width: 100%;
    align-self: end;
  }

  .payment-col-left {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .payment-col-right {
    display: flex;
    justify-content: center;
  }

  .payment-course-name {
    color: rgb(111 104 104);
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    margin-left: 0px !important;
    text-transform: capitalize;
  }

  .payment-full-amount {
    color: #000000;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px !important;
    text-transform: capitalize;
  }

  .checkout-sessions-main {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 0px !important;
  }

  .checkout-session-img {
    width: 14px;
  }

  .checkout-session-detail {
    font-size: 12px;
    font-weight: 400;
    padding-left: 4px;
  }

  .checkout-session-amount {
    color: #000 !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .checkout-total-border {
    margin-left: 0px !important;
    color: #c9bfbf;
    border: 0;
    border-top: 1px solid;
  }

  .checkout-session-total {
    color: #000 !important;
    font-size: 12px;
    width: 75%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {

  #payment-form {
    width: 100%;
    align-self: center;
    border-radius: 7px;
    padding: 20px;
  }

  .payment-form-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .checkout-card {
    display: flex;
    height: 80%;
    overflow: auto;
    width: 70%;
  }

  .payment-left-main {
    width: 100%;
    align-self: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .payment-col-left {
    display: flex;
    justify-content: right;
  }

  .payment-col-right {
    display: flex;
    justify-content: left;
  }

  .payment-course-name {
    color: rgb(111 104 104);
    display: flex;
    justify-content: start;
    font-size: 15px;
    font-weight: 600;
    margin-left: 40px !important;
    text-transform: capitalize;
  }

  .payment-full-amount {
    color: #000000;
    display: flex;
    justify-content: start;
    font-size: 20px;
    font-weight: 600;
    margin-left: 40px !important;
    text-transform: capitalize;
  }

  .checkout-sessions-main {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 40px !important;
  }

  .checkout-session-img {
    width: 14px;
  }

  .checkout-session-detail {
    font-size: 13px;
    font-weight: 400;
    padding-left: 4px;
  }

  .checkout-session-amount {
    color: #000 !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .checkout-total-border {
    margin-left: 40px !important;
    color: #c9bfbf;
    border: 0;
    border-top: 1px solid;
  }

  .checkout-session-total {
    color: #000 !important;
    font-size: 12px;
    width: 75%;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  #payment-form {
    width: 100%;
    align-self: center;
    border-radius: 7px;
    padding: 40px;
  }

  .payment-form-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .checkout-card {
    display: flex;
    height: 80%;
    overflow: auto;
    width: 70%;
  }

  .payment-left-main {
    width: 100%;
    align-self: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  .payment-full-amount {
    color: #000000;
    display: flex;
    justify-content: start;
    font-size: 25px;
    font-weight: 600;
    margin-left: 56px !important;
    text-transform: capitalize;
  }

  .payment-col-left {
    display: flex;
    width: 100%;
    justify-content: left;
  }

  .payment-col-right {
    display: flex;
    width: 100%;
    justify-content: left;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  }

  .payment-course-name {
    color: rgb(111 104 104);
    display: flex;
    justify-content: start;
    font-size: 18px;
    font-weight: 600;
    margin-left: 56px !important;
    text-transform: capitalize;
  }

  .checkout-sessions-main {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 56px !important;
  }

  .checkout-session-img {
    width: 25px;
  }

  .checkout-session-detail {
    font-size: 15px;
    font-weight: 400;
    padding-left: 4px;
  }

  .checkout-session-amount {
    color: #000 !important;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .checkout-total-border {
    margin-left: 56px !important;
    color: #c9bfbf;
    border: 0;
    border-top: 1px solid;
  }

  .checkout-session-total {
    color: #000 !important;
    font-size: 15px;
    width: 75%;
  }
}

/* .payment-main-div{
  left: 50%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
} */
.payment-company-short-logo {
  border-radius: 30px;
  margin-left: 0px;
  width: 25px;
}

/* ProductQuantityUpdater.css */

.quantity-button-checkout {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding-left: 6px;
  padding-right: 3px;
  padding-bottom: 2px;
  padding-top: 2px;
  background-color: #f3f3f3;
  width: 58px;
  margin-left: 9px;
}

.quantity-button-checkout span {
  font-family: 'Inter Tight Medium';
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #000000;
}


.Update-quantity-text {
  font-size: 14px;
  font-weight: 600;
}

.course-name-quantity-text {
  font-size: 12px;
  font-weight: 100;
  color: gray;
}

.update-quantity-btn {
  margin-top: 20px;
  background: #5469d4 !important;
  font-family: Arial, sans-serif;
  color: #ffffff !important;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  border: none;
  padding: 6px;
}

.input-container-quantity {
  padding-left: 90px;
  padding-right: 90px;
}


.Increase-button-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 10px;
  height: 30px;
  width: 30px;
  background-color: #f3f3f3 !important;
}

.quantity-input {
  width: 50px;
  font-size: 1rem;
  border: 1px solid #f3f3f3 !important;
  border-radius: 4px;
  padding: 14px;
  text-align: center;
}

.error-quantity-msg {
  color: red;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}
.closeIcon-quantity {
  cursor: pointer;
}