/* .white-boards-tab {
    padding: 20px;
  
    margin-top: 20px;
    overflow-y: scroll;
    max-height: 88vh;
} */

.breakout-progress-navbar {
    background: rgb(250, 236, 34);
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border: 5px;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: #522bc1;
    height: 25px;
}
.group-title{
    font-weight: 600;
    height: 38px;
    opacity: 0.75;
    background-color: #000;
    width: 170px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    position: absolute;
}
.group-actions-main{
    position: absolute;
    right: 15px;
}

.group-actions{
    margin-top: 6px;
    cursor: pointer;
}

.group-item{
    margin-bottom: 10px;
    height: 480px;
}

.group-item-active{
    margin-bottom: 10px;
    border: 2px solid black;
    height: 480px;
}

.break-out-room-users{
    position: absolute;
    bottom: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.b-users{
    background-color: #656565;
    width: 45px;
    height: 45px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
}