/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  .large-screen {
    display: none;
  }

  .mobile-screen {
    display: block;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .large-screen {
    display: none;
  }

  .mobile-screen {
    display: block;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .large-screen {
    display: none;
  }

  .mobile-screen {
    display: block;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .large-screen {
    display: none;
  }

  .mobile-screen {
    display: block;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .large-screen {
    display: block;
  }

  .mobile-screen {
    display: none;
  }
}

.p-myc-img {
  border-radius: 12px;
}

.add-learner-link {
  color: #006AE0;
  cursor: pointer;
}

.add-new-learner-link {
  color: #006AE0;
  cursor: pointer;
}

.my_kids_save_btn_black {
  color: #000000 !important;
  background-color: #FDB600 !important;
}

.left-column-myc {
  background-color: #F2F6F8;
  /* height: 100vh; */
}

.right-column-myc {
  /* height: 100vh; */
  background: #D4D9DF;
}

.courses-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 24px;
  color: #3B4357;
  font-weight: 700;
  position: absolute;
  left: 0;
}

.parent-profile-card-myc {
  padding: 20px;
  box-shadow: 0px 30px 50px 0px #00000026;
}

.parent-profile-com-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.parent_profile_linear {
  margin-top: 6px;
  background-color: rgb(233 237 231);
}

.Complete-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #12B347;
}

.MuiLinearProgress-bar1 {
  color: #12B347 !important;
}

.things-to-do-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

/* CSS */
.active-ui-myc {
  width: 30px;
  height: 30px;
  background-color: #12B347;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.inactive-ui-myc {
  width: 30px;
  height: 30px;
  background-color: gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.parent-profile-ui-myc {
  list-style-type: none;
}

.parent-profile-li-myc {
  cursor: pointer;
  position: relative;
  bottom: -11px;
}


.border-left-parent-section-myc {

  border-left: 3px solid grey !important;
  height: 15px;
  margin-left: 13px;
  margin-bottom: 0px;
}

.border-left-parent-section-myc-active {
  border-left: 3px solid #12B347 !important;
  height: 15px;
  margin-left: 13px;
  margin-bottom: 0px
}

.parent-profile-ui-title-myc {
  font-family: 'Inter Tight Medium';
  color: #3B4357;
  font-size: 15px;
  cursor: pointer;
}

.parent-profile-ui-des-myc {
  font-family: 'Inter Tight Medium';
  font-size: 12px;
  color: #818694;
  position: absolute;
  left: 41px;
  top: 21px;
}




/* Tabs.css */
.tab-content-parent-myc {
  margin-top: 20px;
  display: none;
}

.tab-content-parent-myc.active {
  margin-top: 20px;
  display: block;
}

.parent-course-btn-myc {
  color: #591FFF !important;
  border: none;
  background-color: white;
  border-radius: 50px;
  font-family: 'Inter Tight Medium';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  padding: 12px 18px;
}

.parent-course-btn-myc.active {
  background: #591FFF !important;
  color: white !important;
  border: 1px solid #3498db;
  border-radius: 50px !important;
  font-family: 'Inter Tight Medium';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  padding: 12px 18px;
}

.course-group-btn-myc {
  background-color: white !important;
  border-radius: 50px;
}

.course-group-btn-myc-mobile {
  background-color: white !important;
  border-radius: 50px;
  margin-left: 13px;
}


.parent-course-card-myc {
  padding: 7px;
  /* box-shadow: 0px 30px 50px 0px #00000026; */
  border-radius: var(--card-border-radius);
}

.card-course-title-myc {
  font-family: 'Inter Tight Medium';
  font-size: 17px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
}

.card-course-text-section-myc {
  padding: 10px;
}

.assign-kid-btn-myc {
  border-radius: 27px;
  background: #FDB600;
  color: #000000;
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  padding: 10px 30px;
}

.buy-course-btn-myc {
  border-radius: 27px;
  background: transparent !important;
  color: #3B4357;
  border: 1px solid #B3B8C7;
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 30px;
}

.course-access-available-btn-myc {
  font-family: 'Inter Tight Medium';
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  color: #818694 !important;
  background: transparent !important;
  border: 1px solid #818694;
  padding: 1px 10px
}

.access-table-section-myc {
  padding-right: 10px;
  padding-left: 10px;
}

td,
th {
  text-align: left;
  padding: 8px;
}

.access-table-th-myc {
  color: #818694;
  font-family: 'Inter Tight Medium';
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.access-table-tb-pod-myc {
  font-family: 'Inter Tight Medium';
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4357;

}

.access-table-tb-myc {
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4357;

}

.access-table-tb-free-myc {
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF5866;
}

.accordion-assigned-learners-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4357;
}

.AccordionSummary-parent {
  min-height: 20px !important;
  height: 20px;
  width: 160px;
}

.no-nearners-available-section-myc {
  border-radius: 8px;
  background: #F2F6F8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.no-nearners-available-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.pods-section-myc {
  border-radius: 8px;
  background: #F2F6F8;
  padding: 10px;
}

.pods-title-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4357;

}

.card-sub-title-myc {
  font-family: 'Inter Tight Medium';
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #818694;
  text-transform: uppercase;
}

.card-dec-free-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF5866;

}

.card-date-text-myc {
  font-family: 'Inter Tight Medium';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4357;
}

.states-inprogress-btn-myc {
  border-radius: 27px;
  background: #55C6D6;
  color: #FFFFFF;
  font-family: 'Inter Tight Medium';
  font-size: 13px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  padding: 3px 11px
}

.parent-profile-section-mobile-myc {
  padding: 20px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.parent-profile-section-mobile-myc-div {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #FFFFFF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.p_my_courses_image_section {
  position: relative;
}

.p_my_courses_btn {
  position: absolute;
  top: 8px;
  left: 8px;
}

.p_my_courses_free_courses_btn {

  font-family: 'Inter Tight Medium';
  padding: 3px 8px;
  border-radius: 8px;
  background: #FF5866;
  color: #FFFFFF;
  font-size: 12px;
  border: none;

}