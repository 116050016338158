/**LARGE VIEW*/
.desktop-logo{
    margin-left: 95px;
    width: 154px;
    cursor: pointer;
}
.desktop-menu{
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #8262FF;
    text-transform: capitalize;
    margin-left: 145px;
    padding-top: 24px;
    padding-bottom: 24px;
}
.desktop-search{
    margin-right: 36px;
}
.desktop-search img{
    cursor: pointer;
}
.desktop-cart{
    margin-right: 36px;
}
.desktop-cart img{
    cursor: pointer;
}
.desktop-sign-in-container{
    cursor: pointer;
    align-items: center;
    background: #854DFF;
    border-radius: 31px;
    display: flex;
    justify-content: center;
    margin-right: 65px;
    width: 149px;
    height: 48px;
}
.desktop-sign-in-container span{
    color: #FDFDFD;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

/**MOBILE VIEW*/
.mobile-logo{
    height: 25px;
    margin-left: 10px;
}

.mobile-sign-in-container{
    align-items: center;
    background: #854DFF;
    border-radius: 31px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    width: 100px;
    height: 30px;
}
.mobile-sign-in-container span{
    color: #FDFDFD;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
}