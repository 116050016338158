.tab-pane {
    padding: 10px;
}
.create-whiteboard {
    border: none;
    background: #522bc1 !important;
    color: #ffff;
    padding: 10px;
    border-radius: 2px;
}
.group-item{
    margin-bottom: 10px;
}
.group-item-active{
    margin-bottom: 10px;
    border: 2px solid black;
}
.whiteboard-grid{
    padding: 10px;
}