.span-or {
    display: block;
    position: absolute;
    left: 50%;
    top: -2px;
    margin-left: -25px;
    background-color: #fff;
    width: 50px;
    text-align: center;
  }
  .hr-or {
    background-color: #cdcdcd;
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  footer.nb-footer {
    background: #222;
    border-top: 4px solid #b78c33;
  }
  footer.nb-footer .about {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 1170px;
    text-align: center;
  }
  footer.nb-footer .about p {
    font-size: 13px;
    color: #999;
    margin-top: 30px;
  }
  footer.nb-footer .about .social-media {
    margin-top: 15px;
  }
  footer.nb-footer .about .social-media ul li a {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    font-size: 16px;
    color: #b78c33;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  footer.nb-footer .about .social-media ul li a:hover {
    background: #b78c33;
    color: #fff;
    border-color: #b78c33;
  }
  footer.nb-footer .footer-info-single {
    margin-top: 30px;
  }
  footer.nb-footer .footer-info-single ul li a {
    display: block;
    color: #aaa;
    padding: 2px 0;
  }
  footer.nb-footer .footer-info-single ul li a:hover {
    color: #b78c33;
  }
  footer.nb-footer .footer-info-single p {
    font-size: 18px;
    line-height: 20px;
    color: #aaa;
  }
  footer.nb-footer .copyright {
    margin-top: 15px;
    background: #111;
    padding: 7px 0;
    color: #999;
  }
  .copyright_text{
      text-align: center;
  }
  .footer_list_a_text{
  text-decoration: none;
  font-size: 18px !important;
  }
  .footer_title{
  color: white;
  font-size: 22px !important;
  }
  .mobile_num_text{
      font-size: 18px;
  }
  .email_text{
      font-size: 18px;
  }