.wall-post-card {
    padding: 0;
    margin-bottom: 35px;
    border-radius: 10px;
    border: 2px solid #4011C6;
    background-color: white !important;
}

.wall-card-text-div {
    padding: 13px;
}

.wall-post-user-name {
    font-family: 'Poppins Regular';
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #191919;
}

.wall-post-search-box {
    height: 40px;
    border-radius: 33px;
    border: 0.8px solid grey;
    padding: 4px;
}

.wall-post-search-input {
    font-family: 'Poppins Regular';
    font-size: 13px;
    width: 100%;
    padding-left: 13px;
}

.wall-post-created-time {
    font-family: 'Poppins Regular';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #707070;
}

.wall-post-des {
    font-family: 'Poppins Regular';
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    text-align: left;
    color: #6C757D;
}

.wall-post-image-div {
    padding: 20px;
    background: rgb(73 16 183 / 14%);
    border-radius: 10px;
}

.wall-post-image {
    border-radius: 10px;
}

.menu-list-for-wall-post {
    font-family: 'Poppins Regular';
}

.post-publish-button {
    background-color: #4011C6 !important;
    color: white;
    font-family: Public Sans, sans-serif;
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px 13px;
    cursor: pointer;
}

.post-deactivate-button {

    background-color: #fa0710 !important;
    color: white;
    font-family: 'Poppins Regular';
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px 13px;
    cursor: pointer;

}