.wall-post-search-box {
    height: 40px;
    border-radius: 33px;
    border: 0.8px solid grey;
    padding: 4px;
}

.wall-post-search-input {
    /* font-family: 'Poppins Regular'; */
    font-size: 13px;
    width: 100%;
    padding-left: 13px;
}