.heading{
    text-decoration: underline!important;
    text-underline-position: from-font;
    font-weight: 400!important;
}

.sub_text{
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.success_c{
    display: flex;
    justify-content: center;
    margin-top: 125px;
    margin-bottom: 125px;
}

.success_img_c{
    width: 100%;
    /* display: flex; */
    justify-content: center;
}

.success_img_div{
    width: 30%;
    text-align: center;
    font-weight: 600;
}

.success_icon{
    padding-bottom: 8px;
}

.landing_img_1{
    padding-top:15px;
}

.input_form{
    justify-content: center; 
}

.input_form_div{
    text-align: center;
    justify-content: center;
    display: flex;
}

.input{
    color: black!important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

.join_free_class_btn{
    padding-top: 19px;
    padding-bottom: 18px;
    padding-right: 20px;
    padding-left: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #ff0b53!important;
}

/* POPUP */
.play-img{
    display: block;
  margin-left: auto;
  margin-right: auto;
}

.close-btn{
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    color: #4500e2;
}

.btn-submit{
    background-color: #ff0b53!important;
    color: white !important;
    width: 75%;
    font-weight: 600;
}

.form-container{
    padding: 20px;
}

.free-live-text{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .c_padding{
        padding-top:130px;
    }
    .input_form_div{
        width: 100%;
    }
    .heading{
        font-size: 24px;
    }
    .sub_text{
        font-size: 12px;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .c_padding{
        padding-top:130px;
    }
    .input_form_div{
        width: 100%;
    }
    .heading{
        font-size: 24px;
    }
    .sub_text{
        font-size: 12px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .c_padding{
        padding-top:130px;
    }
    .input_form_div{
        width: 100%;
    }
    .heading{
        font-size: 24px;
    }
    .sub_text{
        font-size: 12px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .c_padding{
        padding-top:130px;
    }
    .input_form_div{
        width: 500px;
    }
    .heading{
        font-size: 35px;
    }
    .sub_text{
        font-size: 15px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .c_padding{
        padding-top:130px;
    }
    .input_form_div{
        width: 500px;
    }
    .heading{
        font-size: 35px;
    }
    .sub_text{
        font-size: 15px;
    }
}