.welcome-meeku{
    text-align: center;
    padding-top: 20%;
    padding-bottom: 20%;
    font-size: 22px;
    font-weight: 700;
    font-family: sans-serif;

}
.reset-text{
    font-family: 'Inter Tight Medium';
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 17px;
}
.reset-text-1 {
    font-family: 'Inter Tight Medium';
    font-size: 17px;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 17px;
}
.reset-btn{
    background: #F9BA1A !important;
    color: #3B4357;
    border-radius: 38px;
    font-family: 'Inter Tight Medium';
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
}
.error{
    color: red;
}
.reset-password-container{
    background: linear-gradient(287.59deg, #591FFF 0%, #39B8FF 57.58%, #4BA9FF 110.55%);
    height: 100%;
    overflow: hidden;
    padding: 0px 7px 0px 7px;
    width: 100%;
  }