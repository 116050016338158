.close-icon {
  font-size: 15px;
  cursor: pointer;
}

.e-notes-container {
  position: absolute;
  width: 100%;
  top: 253px;
  overflow: auto;
  height: 100%;
}
.e-role-label {
  background: #3b4357;
  width: 100%;
  color: white;
  padding: 8px;
  font-weight: bold;
  font-size: 20px;
}

.e-sticky-text-area {
  top: 46px;
  position: absolute;
  width: 100%;
  background: #fff;
}

.e-sticky-header {
  position: fixed;
  width: 280px;
  z-index: 1;
}
.host-course-item {
  padding: 5px;
  display: flex;
  color: #6c757d;
}
.notes-input {
  width: 100%;
}
.notes-textarea {
  border: none;
  background: #f8f9fa;
  width: 100%;
  /* width: 77vh; */
}
.notes-action {
  height: 40px;
  /* background: red; */
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #adb5bd;
}
.notes-color {
  display: flex;
}
.notes-red {
  height: 20px;
  width: 20px;
  background: #f15c5c;
  border-radius: 50%;
  margin-right: 6px;
}
.notes-blue {
  height: 20px;
  width: 20px;
  background: #0dcaf0;
  border-radius: 50%;
  margin-right: 6px;
}
.notes-yellow {
  height: 20px;
  width: 20px;
  background: #ffc107;
  border-radius: 50%;
  margin-right: 6px;
}
.notes-violet {
  height: 20px;
  width: 20px;
  background: #9b79fb;
  border-radius: 50%;
  margin-right: 6px;
}
.add-note {
  font-weight: 500;
  cursor: pointer;
}
.note-item {
  color: white;
  padding: 20px;
  border-radius: 15px;
  margin-top: 10px;
  word-wrap: break-word;
  position: relative;
}
.removeNotes {
  position: absolute;
  top: 8px;
  right: 8px;
}
.required_alert_text {
  color: red;
  padding-left: 20px;
  font-weight: 500;
  font-size: 13px;
  text-align: end;
  padding-right: 20px;
}

.host-course-list-notes {
  border-radius: 15px;
  /* height: 100vh; */
  overflow: auto;
  position: relative;
  top: 247px;
  background-color: white;
  overflow-y: auto;
  height: calc(100% - 250px);
}

/* .notes-area{
    position: relative;
} */
/* .notes-area {
    height: 40vh;
    overflow-y: scroll;
}
@media only screen and (max-width: 1700px) {
    .notes-area {
        height: 46vh;
        overflow-y: scroll;
    }
  } */
