/* section-8-container footer */
.meeku_logo_footer {
  cursor: pointer;
}

.footer-container-hv4 {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #c9cb8d;
  width: 100%;
  /* height: 100vh; */
}

.footer-section-hv4 {
  padding-top: 90px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.safe-environment-footer-text-hv4 {
  color: white;
  font-size: 16px !important;
  font-family: 'Poppins Regular';
}

.Meeku-Learning-footer-text-hv4 {
  font-size: 12px !important;
  text-align: center;
  color: white !important;
  font-family: 'Poppins Regular';
}

.user-guide-footer-text-hv4 {
  font-size: 12px !important;
  text-align: left;
  color: white !important;
  font-family: 'Poppins Regular';
}

.atag-color {
  cursor: pointer;
  color: white !important;
  text-decoration: underline;
}

/* new */
.content {
  height: 40px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 60px;
  overflow: hidden;
}

.subscription-hv4 {
  position: relative;
  width: 100%;
  height: 100%;
}

.subscription-hv4 .add-email {
  font-family: 'Poppins Regular';
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 20px;
}

.subscription-hv4 .submit-email {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 2px);
  width: 100px;
  border: none;
  border-radius: 60px;
  outline: none;
  margin: 1px;
  padding: 0 20px;
  cursor: pointer;
  background: #4316c3;
  color: #ffffff;
  transition: width 0.35s ease-in-out, background 0.35s ease-in-out;
}

.subscription-hv4.done .submit-email {
  width: calc(100% - 2px);
  background: #c0e02e;
}

.subscription-hv4 .submit-email .before-submit,
.subscription-hv4 .submit-email .after-submit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 38px;
  transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
}

.subscription-hv4.done .submit-email .before-submit,
.subscription-hv4:not(.done) .submit-email .after-submit {
  visibility: hidden;
  opacity: 0;
}

.subscription-hv4 .submit-email .after-submit {
  transition-delay: 0.35s;
}

.subscription-hv4:not(.done) .submit-email .before-submit,
.subscription-hv4.done .submit-email .after-submit {
  visibility: visible;
  opacity: 1;
}

.custom-align-social-media-img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Default for larger screens */
}

@media (max-width: 992px) {
  .custom-align-social-media-img {
    justify-content: center;
    /* Center on smaller screens */
  }
}