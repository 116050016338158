/* nav.meeku-header.navbar.navbar-expand-md.navbar-dark {
    background: #522bc1;
} */

.cursor-pointer{
  cursor: pointer;
}
.meeku-breakout-room-header{
  background: #ffee6c !important;
  height: 40px;
}

.meeku-header{
  background: #522bc1 !important;
  padding: 15px;
}

.meeku-header-icon {
  font-size: 30px;
  color: white;
 
}

.meeku-header-icon1 {
  font-size: 33px;
  color: white;
  border-radius: 50%;
}

.meeku-header-icon1-on{
  border-radius: 50%;
}

.meeku-header-icon1-off {
  background: red;
  border-radius: 50%;
}

.video-off-icon{
  font-size: 25px;
  color: white;
}
/* .navbar-text {
  background: red;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #f8f9fa;
  font-size: 30px;
  padding: 5px;
} */

.main-selection {
  right: 33%;
  position: absolute;
  /* top: 25px; */
 
}

.main-selection-item {
  color: #ffff;
  background: #522bc1;
  border: none;
  padding: 5px;
  cursor: pointer;
 font-size: 20px;
}

.main-selection-item-active {
  color: #ffff;
  background: #522bc1;
  padding: 5px;
  border: none;
  cursor: pointer;
  font-weight: 900;
  border-bottom: 6px solid #ffff;
  margin-bottom: -20px;
  font-size: 20px;
}

.video-off-link {
  /* border-radius: 50%; */
  /* background: red; */
  /* width: 50px;
  height: 50px; */
  /* display: flex; */
  /* background: red; */
  /* width: 50px;
  height: 50px; */
  justify-content: center;
  align-items: center;
 
}
.video-on-link{
  justify-content: center;
  align-items: center;
 
 
}
.navbar-text {
  background: red;
  padding-top: 17px;
  padding-bottom: 0.5rem;
  color: #f8f9fa;
  font-size: 30px;
  padding: 23px;
  position: absolute;
  right: 0;
  bottom: 0;
 cursor: pointer;
}
.nav_title{
  font-size: 25px !important;
  position: relative;
  right: 20px;
}
.break_out_section{
  
  padding: 12.6px 18px 12.6px 21.9px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.356);
  
}
.break_out_section_text{
  color: white !important;
  font-weight: bold;
}
.circle{
  width: 18px;
  height: 18px;
  margin: 7px 10px 11px 0;
  background-color: #f00;
  border-radius: 50px;
}
.rec_section_text{
  margin-right: 15px;
  margin-left: 10px;
}

.logout_box{
  background-color: #f00;
  position: absolute;
    right: 0px;
     padding: 22px; 
     bottom: 0; 
    top: 0;
}
.logout_box_inactive{
  background-color: gray;
  position: absolute;
    right: 0px;
     padding: 22px; 
     bottom: 0; 
    top: 0;
}

.loading-dot{
  font-size: 24px;
    font-weight: 900;
}

/* RAISE HAND */
.raise-hand-left-initial{
  background: #9898ae;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  padding: 7px 5px 6px 5px;
  font-size: 14px;
  font-weight: 500;
}

.raise-hand-name{
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 30px;
}

.lower-hand-container{
  cursor: pointer;
  height: 20px;
  background: #e3cdcd;
  color: black;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.raised-hand-notification-dot{
  position: absolute;
  top: -5px;
  right: -7px;
  width: 15px;
  height: 15px;
  background: yellow;
  border-radius: 50%;
}

.hand-icon-container{
  position: relative;
}

.breakout-session-header-expert-main{
  color: #5901e6;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.end-breakout-session-by-expert-btn{
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-left: 15px!important;
  padding: 2px 10px 2px 10px;
  border-radius: 30px;
  background-color: red;
  color: #fff;
}

.header-e-opened_group_floating_lable{
  position: absolute;
    top: 0px;
    left: 40%;
    z-index: 2000;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: #23237c;
    padding: 6px 25px 6px 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.leave-group-text-btn{
  background: red;
  margin-left: 8px;
  padding: 2px 6px 2px 6px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}